import { FontDemi } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const SubtitleEl = styled.h2`
  display: flex;
  font-family: ${FontDemi};
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  color: ${colors.grey1};
  text-transform: uppercase;
  max-width: 90%;
  margin: 16px 0px;
  overflow-wrap: break-word;

  &.centered {
    margin: auto;
    text-align: center;
    justify-content: center;
  }

  @media (${breakpoints.s}) {
    font-size: 18px;
    letter-spacing: 1.29px;
    line-height: 28px;
    max-width: 70%;
  }

  @media (${breakpoints.l}) {
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 34px;
  }

  @media (${breakpoints.xl}) {
    max-width: 50%;
  }
`;
