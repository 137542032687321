import * as React from 'react';
import Shape from 'src/components/shape/Shape';
import { AreaProps, Area } from '../../components/area/Area';
import { AreasWrapper } from './Areas.styles';

export type AreasProps = {
  areas: Array<AreaProps>,
  className?: string
};

const Areas = (props: AreasProps): JSX.Element => {

  return (
    <AreasWrapper className={`areas ${props.className || ''}`}>
      <Shape alignment={'left'} type={5} y={430} />
      <Shape alignment={'right'} type={3} y={430} />
      {
        props.areas && props.areas.map((area: AreaProps, index: number) => {
          return <Area key={index} {...area} areaImage={area.areaImage} partner={area.partner} index={index} />;
        })
      }
    </AreasWrapper>
  );
};

export default Areas;

