import * as React from 'react';
import { StageWrapper, StageVideo, ButtonWrapper } from './Stage.styles';
import Title from '../../components/title/Title';
import Searchbox from '../../components/searchbox/Searchbox';
import Button, { ButtonProps } from '../../components/button/Button';
import Subtitle from '../../components/Subtitle/Subtitle';
import { getAbsoluteImageURL } from '../../helper';
import _ from 'underscore';

export type StageProps = {
  title: string,
  subtitle?: string,
  text?: string,
  jobsearch?: boolean,
  searchPlaceholder?: string,
  buttons?: Array<ButtonProps>,
  backgroundImages?: Array<{
    url: string
  }>,
  backgroundVideos?: Array<{
    url: string
  }>,
  backgroundColor?: string,
  useStaticSourceBackground?: boolean
};

const Stage = (props: StageProps): JSX.Element => {


  // Randomize all background images and videos in stage
  const randomizedBackgroundImages = _.shuffle(props.backgroundImages || []);
  const randomizedBackgroundVideos = _.shuffle(props.backgroundVideos || []);

  // Make sure to only show video on desktop
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const getBackgroundImage = (): string => {

    // Use images from assets
    // This is a special case for job detail stage - here we have five categories for jobs, each having a specific image which is stored in assets
    if (props.useStaticSourceBackground && props.backgroundImages && props.backgroundImages.length > 0) {
      return props.backgroundImages[0].url;
    }

    // If mobile only show image, avoid video
    if (isMobile && randomizedBackgroundImages.length > 0) {
      return getAbsoluteImageURL(randomizedBackgroundImages[0].url);
    } else {
      // Choose video if existing an desktop
      if (props.backgroundVideos && props.backgroundVideos.length >= 1) {
        return 'none';
      } else {
        //Choose image if no video existing
        if (props.backgroundImages && props.backgroundImages.length >= 1) {
          return getAbsoluteImageURL(randomizedBackgroundImages[0].url);
        } else {
          return 'none'; // No video and image existing, just use color
        }
      }
    }
  }

  return (
    <StageWrapper className={'stage padding'} style={{
      backgroundImage: `url(${getBackgroundImage()})`
    }}>
      {props.subtitle && <Subtitle title={props.subtitle} />}
      <Title title={props.title} />
      {props.text && <Subtitle title={props.text} className={'text'} />}
      {props.jobsearch && <Searchbox placeholder={props.searchPlaceholder} suggestions={true} />}
      <ButtonWrapper>
        {props.buttons && props.buttons.map((button: ButtonProps, index: number) => {
          return <Button key={index} {...button} />;
        })}
      </ButtonWrapper>
      {!isMobile && props.backgroundVideos && props.backgroundVideos.length >= 1 && <>

        <StageVideo autoPlay={true} controls={false} loop={true} muted={true} className={'stage-video'}>
          <source src={getAbsoluteImageURL(randomizedBackgroundVideos[0].url)} />
        </StageVideo>

      </>}
    </StageWrapper >
  );
};

export default Stage;
