import { breakpoints, breakpointValues } from 'src/styles/breakpoints';
import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const ExcellenceWrapper = styled.div`

  background-color: ${colors.grey1};
  padding-bottom: 136px;

  @media(max-width: ${breakpointValues.s}) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .section-title {
    color: white;
    margin: 0px auto;
    justify-content: center;
    text-align: center;
  }
`;

export const ExcellenceItemsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
    justify-items: center;
    max-width: 1350px;
    margin-top: 140px;

    .text, .title {
      color: white;
    }

    .text {
      font-size: 16px;
      line-height: 22px;
    }

    @media(${breakpoints.l}) {
      grid-template-columns: repeat(3, 1fr);
      margin: auto;
      justify-items: unset;
      margin-top: 140px;
    }
`;

export const ExcellenceTitle = styled.p`
    font-family: ${FontHeavy};
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 2.66667px;
    text-transform: uppercase;
    color: white;
    margin-top: 44px;
    font-size: 16px;
    width: 250px;
    margin-bottom: 16px;

    @media(${breakpoints.s}) {
      margin-top: 88px;
      font-size: 32px;
      line-height: 40px;
    }
`;

export const ExcellenceColumn = styled.div`

    width: 366px;
    height: 366px;
    margin-top: -66px;

    .title, .text {
      margin-left: 68px;
      width: 260px;
    }

    .text {
      font-size: 14px;
    }

    .column-svg {
      position: absolute;
      width: 366px;
      height: 366px;
    }

    .cms-image {
      width: 250px;
      margin-left: 58px;
      margin-top: 60px;
    }

    @media(${breakpoints.s}) {
      width: 550px;
      height: 550px;
      margin-top: -80px;

      .title, .text {
        margin-left: 100px;
        width: 270px;
      }

      .text {
        font-size: 16px;
        line-height: 26px;
        width: 350px;
      }

      .cms-image {
        margin-left: 75px;
        margin-top: 76px;
        width: 366px;
      }

      .column-svg {
        width: 550px;
        height: 550px;
      }
    }

    @media(${breakpoints.l}) {

      .title, .text {
        margin-left: 100px;
        width: 270px;
      }

      &:first-child {
        margin-left: 0px;
      }

      margin-left: -180px;
    }
`;
