import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const ShapeWrapper = styled.div`
  display: none;
  position: absolute;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.above {
    z-index: 100;
  }

  @media(${breakpoints.l}) {

    display: block;

    &.shape-2 {
      left: -40px;
      img {
        width: 44%;
      }
    }

    &.shape-3 {
      right: -155px;
      img {
        width: 75%;
      }
    }

    &.shape-5 {
      left: -100px;
      img {
        width: 76%;
      }
    }

    &.shape-6 {
      right: -220px;
      img {
        width: 75%;
      }
    }

    &.shape-12 {
      right: -90px;
      img {
        width: 75%;
      }
    }
  }

  @media(${breakpoints.xl}) {

    img {
      width: 100%;
    }

    .shape-2 img {
      width: 70%;
    }
  }

`;
