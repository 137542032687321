import * as React from 'react';
import { MatchmakingWrapper } from './Matchmaking.styles';
import MatchValue, { MatchValueProps } from 'src/components/match-value/MatchValue';
import Title from 'src/components/title/Title';
import { MatchmakingDataProps } from 'src/queries/matchmaking';
import Button from 'src/components/button/Button';
import { ButtonWrapper } from '../stage/Stage.styles';
import Text from 'src/components/Text/Text';
import { scrollToElement } from 'src/helper';
import SliderButtonMobile from 'src/components/slider-buttons-mobile/SliderButtonsMobile';
import Subtitle from 'src/components/Subtitle/Subtitle';
import { MobileNavigation } from 'src/modules/matchmaking/Matchmaking.styles';
import WindowSizeListener from 'react-window-size-listener';

export type MatchmakingProps = {
  className?: string,
  title: string,
  text: string,
  titleFullMatch: string,
  titleAlmostlMatch: string,
  textFullMatch: string,
  textAlmostMatch: string,
  matchValues: Array<MatchValueProps>,
  buttonApply: string,
  buttonContact: string
};

const Matchmaking = (props: MatchmakingDataProps): JSX.Element => {

  const [selectedValues, setSelectedValues] = React.useState<Array<MatchValueProps>>([]);
  const [hasMatched, setHasMatched] = React.useState<boolean>(false);
  const [matchTitle, setMatchTitle] = React.useState<string | null>(props.title);
  const [matchText, setMatchText] = React.useState<string | null>(props.text);
  const [mobileSliderOffset, setMobileSliderOffset] = React.useState<number>(0);
  const [sliderOverflowX, setSliderOverflowX] = React.useState<number | null>(null);
  const [mobileSlideVisible, setMobileSlideVisible] = React.useState<number>(0);
  const [selectedValuesCount, setSelectedValuesCount] = React.useState<number>(0);

  React.useEffect(() => {

  }, [hasMatched, mobileSlideVisible, selectedValues, selectedValuesCount]);

  const toggleMatchValue = (value: MatchValueProps) => {

    // Add to set
    let _selectedValues = selectedValues;

    if (value.isSelected) {
      _selectedValues.push(value);
    } else {
      _selectedValues = selectedValues.filter((item) => {
        return item.title.indexOf(value.title) === -1;
      });
    }
    setSelectedValues(_selectedValues);
    setSelectedValuesCount(_selectedValues.length);
    checkMatch();
  };

  const checkMatch = () => {

    const MATCH_ITEMS_TO_SELECT = 3;
    const MATCHMAKERS_TO_SELECT = 1;

    // MATCHING LOGIC
    // Count matching tiles
    let matchmakers = selectedValues.filter(item => {
      return item.matchmaker === true;
    });

    const isFullMatch = matchmakers.length === MATCHMAKERS_TO_SELECT;

    // Matchmaking is finished after three selected values
    if (selectedValues.length === MATCH_ITEMS_TO_SELECT) {
      setMatchTitle(isFullMatch ? props.titleFullMatch : props.titleAlmostMatch);
      setMatchText(isFullMatch ? props.textFullMatch : props.textAlmostMatch);
      setHasMatched(true);
      scrollToElement('matchmaking');
    }
  };

  const changeValueSlide = (_currentSlide: number) => {

    // Mobile + Tablet: horizontal slider > calculate overflow to adjust sliding
    let scrollOverflow;
    if (sliderOverflowX === null) {
      scrollOverflow = updateScrollOverflowX();
    } else {
      scrollOverflow = sliderOverflowX;
    }

    let mobileOffset = _currentSlide * 290;
    setMobileSliderOffset(-1 * Math.min(mobileOffset, (scrollOverflow || 0)));

    // Change currently visible slide (mobile version)
    setMobileSlideVisible(_currentSlide);
  };

  const updateScrollOverflowX = (): number | null => {
    let slider = document.querySelector<HTMLDivElement>('.match-value-list');
    if (slider) {
      let scrollOverflow = slider.scrollWidth - slider.clientWidth;
      setSliderOverflowX(scrollOverflow);
    }

    return null;
  }

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <MatchmakingWrapper className={`matchmaking`}>
      {matchTitle && <Title title={matchTitle} centered={true} />}
      {matchText && <Text title={matchText} centered={true} />}
      <WindowSizeListener onResize={updateScrollOverflowX} />
      {!hasMatched && <MobileNavigation>
        <SliderButtonMobile itemsCount={props.matchValues.length} changeSlideHandler={changeValueSlide} currentSlide={mobileSlideVisible} />
        <Subtitle title={`${selectedValuesCount}/3 ausgewählt`} />
      </MobileNavigation>}
      {hasMatched && <ButtonWrapper className={'button-wrapper'}>
        <Button title={props.buttonApply} buttonColor={'tertiary'} icon={'scrolldown'} onClick={() => scrollToElement('jobs')
        } />
        <Button title={props.buttonContact} buttonColor={'tertiary'} icon={'scrolldown'} onClick={() => scrollToElement('contact')
        } />
      </ButtonWrapper>}
      <div className={`grid match-value-list ${hasMatched ? 'animate-matched' : ''}`} style={{ marginLeft: mobileSliderOffset }}>
        {
          !hasMatched && props.matchValues && props.matchValues.map((matchEl: MatchValueProps, index: number) => {
            return <MatchValue key={index} {...matchEl} onClick={toggleMatchValue} disabled={hasMatched} className={`${(isMobile && mobileSlideVisible === index) ? 'selected' : ''}`} />;
          })
        }
        {
          hasMatched && selectedValues.map((matchEl: MatchValueProps, index: number) => {
            return <MatchValue key={index} {...matchEl} onClick={toggleMatchValue} disabled={hasMatched} className={``} />;
          })
        }
      </div>
    </MatchmakingWrapper >
  );
};

export default Matchmaking;

