import * as React from 'react';
import FAQ from '../../components/faq/FAQ';
import { FAQProps } from '../../components/faq/FAQ';
import { FAQsWrapper } from './FAQs.styles';
import Shape from 'src/components/shape/Shape';
import Subtitle from 'src/components/Subtitle/Subtitle';
import Text from 'src/components/Text/Text';

export type FAQsProps = {
  faqs: Array<FAQProps>,
  text: string,
  title: string,
  className?: string
};

const FAQs = (props: FAQsProps): JSX.Element => {

  if (!!props.faqs && props.faqs.length === 0) return <></>;

  return (
    <FAQsWrapper className={`faqs ${props.className || ''}`}>
      <Shape alignment={'right'} type={12} y={100} />
      <Subtitle title={props.title} centered={true} />
      <Text title={props.text} centered={true} />
      {
        props.faqs && props.faqs.map((faq: FAQProps, index: number) => {
          return <FAQ key={index} {...faq} />;
        })
      }
    </FAQsWrapper>
  );
};

export default FAQs;
