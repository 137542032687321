import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import CircleBackgroundXS from '../../assets/icons/circle-xs.svg';
import CircleBackgroundS from '../../assets/icons/circle-s.svg';
import { FontHeavy } from 'src/styles/fonts';

export const HeaderWrapper = styled.header`
  display: flex;
  margin-top: 35px;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 1000;
  transition: opacity 2s ease-in;
  opacity: 1;
  padding: 50px;

  .logo-wrapper-sticky {
    width: 0;
    overflow: hidden;
  }

  &.sticky-hidden, .sticky-hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.sticky {
    position: fixed;
    top: 0;
    oapcity: 1;

    @media (${breakpoints.s}) {
      background-color: white;

      .logo-wrapper-sticky {
        visibility: visible;
      }
    }

    .link {
      color: ${colors.grey1};
    }

    .logo-wrapper {
      display: none;
    }

    .logo-wrapper-sticky {
      @media (${breakpoints.s}) {
        overflow: visible;
      }
    }

    .logo-wrapper-sticky.mobile-menu-open {
      visibility: visible;
    }

    .nav-dropdown-menu-item {
      color: ${colors.grey1};
    }
  }

  & svg {
    cursor: pointer;
  }

  .logo-dark {
    display: none;
  }

  &::before {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    content: "";
    position: relative;
    top: 0px;
    right: 0px;
    left: 0px;
  }

  &.menu-open {

    height: 80%;
    max-height: 577px;

    &::before {
      background-image: url(${CircleBackgroundXS});
      opacity: 1;
      min-height: 750px;

      position: fixed;
      z-index: -1;
    }

    .logo {
      display: none;
    }

    .logo-dark {
      display: block;
    }

    .logo-wrapper-sticky {
      overflow: visible;
    }


    @media (${breakpoints.s}) {

      height: unset;

      &::before {
        background-image: url(${CircleBackgroundS});
        display: none;
      }

      .logo-dark {
        display: none;
      }
    }
  }

  & .logo {
    height: 41.27px;
    width: auto;

    @media (${breakpoints.l}) {
    height: 78px;
    }

    @media (${breakpoints.xl}) {
      height: 107px;
    }
  }
`;

export const MenuWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;

  & .close {
    padding: 9px;
  }
`;

export const DesktopMenu = styled.div`

  display: none;

  @media (${breakpoints.s}) {
    display: block;
  }
`;

export const MobileMenu = styled.div`

  position: fixed;
  display: flex;
  justify-content: space-between;
  width: calc(100vw - 84px);

  @media (${breakpoints.s}) {
    display: none;
  }
`;

export const NavigationWrapperMobile = styled.div`

  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-top: 42px;
  position: fixed;

  & .link {
    font-family: ${FontHeavy};
    font-size: 16px;
    color: ${colors.grey1};
    letter-spacing: 1px;
    line-height: 26px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 22px 0px;
  }

  @media (${breakpoints.s}) {
    display: none;
  }
`;

export const NavigationWrapperDesktop = styled.div`

  display: none;
  align-items: center;

  @media (${breakpoints.s}) {

    display: flex;
    text-align: center;

    & .link {
      font-family: ${FontHeavy};
      font-size: 16px;
      color: white;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0px 22px;
    }

    &.dark .link {
      color: ${colors.grey1};
    }
  }
`;

export const NavDropdownMenuItem = styled.div`
  font-family: Futura PT Heavy,Trebuchet MS;
    font-size: 16px;
    color: white;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0px 22px;
    cursor: pointer;
`;


export const NavDropdown = styled.div`
  &:hover {
    .nav-dropdown-items-wrapper {
      display: flex !important;
      margin-left: -32px;
    }
  }
`;

export const NavDropdownItemsWrapper = styled.div`

  display: flex;
  flex-flow: column;
  position: absolute;
  display: none;
  padding-top: 16px;

  .nav-dropdown-link {
    color: ${colors.grey1};
    padding: 22px 22px;
    background-color: white;

    &:hover {
      background-color: ${colors.primary};
      color: white;
    }
  }
`;

