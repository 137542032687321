import * as React from 'react';
import { TextBlockWrapper, ButtonWrapper } from './TextBlock.styles';
import Subtitle from '../../components/Subtitle/Subtitle';
import Title from '../../components/title/Title';
import Button from '../../components/button/Button';
import Text from '../../components/Text/Text';
import Image from '../../components/image/Image';
import { ButtonProps } from '../../components/button/Button';
import List from 'src/components/list/List';
import { getAbsoluteImageURLReduced } from '../../helper';

export type TextBlockProps = {
  title: string,
  subtitle?: string,
  text: string,
  list: string,
  image?: {
    url: string,
  },
  button?: ButtonProps,
  direction?: string,
  backgroundColor?: string,
  className?: string,
  onClick?: () => void,
  navigationElements?: JSX.Element,
};

const TextBlock = (props: TextBlockProps, children: JSX.Element): JSX.Element => {

  return (
    <TextBlockWrapper className={`grid ${props.direction || ''} textblock background-${props.backgroundColor || ''} ${props.className || ''}`}>
      {props.image && <div className="span-col-6 span-col-12">
        <Image source={getAbsoluteImageURLReduced(props.image.url, 'medium_')} alt={''} />
      </div>
      }
      <div className="span-col-6 span-col-12">
        {props.navigationElements}
        {props.subtitle &&
          <Subtitle title={props.subtitle} />
        }
        <Title title={props.title} />
        {props.text && <Text title={props.text} />}
        {props.list && <List items={props.list.split('\n')} />}
        <ButtonWrapper>
          {props.button &&
            <Button {...props.button} onClick={props.onClick} />
          }
        </ButtonWrapper>
      </div>
    </TextBlockWrapper >
  );
};

export default TextBlock;
