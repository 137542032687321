import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const TitleEl = styled.h1`
  display: flex;
  font-family: ${FontHeavy};
  font-size: 24px;
  letter-spacing: 3px;
  line-height: 34px;
  color: ${colors.grey1};
  text-transform: uppercase;
  max-width: 90%;
  margin: 8px 0px;
  overflow-wrap: break-word;

  @media (${breakpoints.s}) {
    max-width: 70%;
    margin: 16px 0px;
    font-size: 32px;
    line-height: 42px;
  }

  @media (${breakpoints.l}) {
    font-size: 40px;
    letter-spacing: 4px;
    line-height: 50px;
  }

  @media (${breakpoints.xl}) {
    letter-spacing: 5px;
    max-width: 50%;
  }

  &.centered {
    margin: auto;
    text-align: center;
    justify-content: center;
  }
`;
