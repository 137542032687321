import * as React from 'react';
import Text from 'src/components/Text/Text';
import { ProcessWrapper, StepWrapper, StepNumber, StepLeft, StepRight, StepContent, StepNumberWrapper, StepImage, StepsWrapper } from './Process.styles';
import StepLeftSVG from '../../assets/icons/step-left.svg';
import StepRightSVG from '../../assets/icons/step-right.svg';
import { getAbsoluteImageURLReduced } from '../../helper';
import Title from 'src/components/title/Title';

export type ProcessStepProps = {
  text: string,
  image: {
    url: string
  }
};

export type ProcessProps = {
  sectionTitle: string,
  steps: Array<ProcessStepProps>
};

const Process = (props: ProcessProps): JSX.Element => {

  return (
    <ProcessWrapper className={'process'}>
      <Title title={props.sectionTitle} centered={true} />
      <StepsWrapper>
        {
          props.steps && props.steps.map((step: ProcessStepProps, index: number) => {
            return <StepWrapper key={index}>
              {index % 2 !== 0 && <StepLeft src={StepLeftSVG} className={(index + 1) === props.steps.length ? 'last' : ''} />}
              <StepContent className={`${index % 2 !== 0 ? 'lower-text' : ''}`}>
                {step.image && <StepImage src={getAbsoluteImageURLReduced(step.image.url)} className={'step-image'} />}
                <StepNumberWrapper >
                  <StepNumber>{index + 1}.</StepNumber>
                  <Text title={step.text} />
                </StepNumberWrapper>
              </StepContent>
              {index % 2 === 0 && <StepRight src={StepRightSVG} className={(index + 1) === props.steps.length ? 'last' : ''} />}
            </StepWrapper>;
          })
        }
      </StepsWrapper>
    </ProcessWrapper>
  );
};

export default Process;
