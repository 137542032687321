import { FontDemi } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const ValuesWrapper = styled.div`

  margin-bottom: 136px;

    & .grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;
      margin: auto;
      max-width: 325px;

      @media (${breakpoints.s}) {
        max-width: 417px;
      }

      @media (${breakpoints.l}) {
        max-width: 1066px;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .title {
      margin-bottom: 52px;
    }
`;

export const Value = styled.div`

  position: relative;

  & .subheading {
    max-width: unset;
    word-break: keep-all;
    z-index: 1;
    position: relative;
    font-family: ${FontDemi};

    @media(${breakpoints.s}) {
      font-size: 32px;
      margin: 4px;
    }
  }

  & .text {
    max-width: 269px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1px;
    z-index: 1;
    position: relative;
  }

  & .circle {
    position: absolute;
    z-index: 0;
  }

  @media (${breakpoints.xs}) {

    &.value-1 {
      margin-bottom: 40px;

      .circle {
        margin-left: 80px;
        margin-top: -25px;
      }
    }

    &.value-2 {
      left: 45px;
      margin-bottom: 73px;

      .circle {
        margin-left: 82px;
      }
    }

    &.value-3 {
      left: 45px;
      margin-bottom: 45px;

      .circle {
        margin-left: -43px;
        margin-top: -25px;
      }
    }

    &.value-4 {
      margin-bottom: 54px;

      .circle {
        margin-left: 124px;
      }
    }

    &.value-5 {
      left: 45px;

      .circle {
        margin-left: 50px;
        margin-top: 5px;
      }
    }
  }

  @media (${breakpoints.s}) {

    &.value-1 {
      margin-bottom: 40px;
    }

    &.value-2 {
      left: 279px;
      margin-bottom: 73px;
    }

    &.value-3 {
      left: 0px;
      margin-bottom: 45px;

      .circle {
        margin-left: -57px;
      }
    }

    &.value-4 {
      left: 232px;
      margin-bottom: 54px;
    }

    &.value-5 {
      left: 116px;

      .circle {
        margin-left: -133px;
      }
    }
  }

  @media (${breakpoints.l}) {

    margin-bottom: 0px;

    &.value-1 {
      .circle {
        margin-left: 163px;
        margin-top: -5px;
      }
    }

    &.value-2 {
      top: 84px;
      left: 0px;

      .circle {
        margin-left: 93px;
        margin-top: 10px;
      }
    }

    &.value-3 {
      top: 8px;
      left: 0px;

      .circle {
        margin-top: 18px;
      }
    }

    &.value-4 {
      left: 109px;

      .circle {
        margin-left: 132px;
        margin-top: 38px;
      }
    }

    &.value-5 {
      left: 409px;

      .circle {
        margin-left: -123px;
        margin-top: 37px;
      }
    }
  }
`;
