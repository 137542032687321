import { useQuery } from '@apollo/client';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Text from 'src/components/Text/Text';
import Title from 'src/components/title/Title';
import { ConnexysFormDataProps, CONNEXYSFORM_QUERY } from 'src/queries/connexysform';
import Stage from '../stage/Stage';
import { FormApplicationWrapper, ForwardingPrivacyText, FormConnexysWrapper } from './FormConnexys.styles';

export type ConnexysPageProps = {
  formType: string
};

// Connexys Form Documentation: https://help.connexys.com/hc/en-us/articles/208948249-Javascript-API-for-questionnaires
export type ConnexysPageParamsProps = {
  formId?: string,
  ApplicationId?: string,
  accessKey?: string,
  candidateId: string,
  accountName?: string,
  jobName?: string,
};

declare global {
  interface Window {
    cxsForm: {
      init(obj: unknown): void,
      setFieldValue(arg1: string, arg2: string): void,
      onAfterSubmit(success: boolean, jsonString: string): void,
      getFieldData(fieldKey: string): string
    }
  }
}

// http://localhost:3000/privacyStatementForm/a001i0000043AcmAAE/a0A1i00000C1hPD/csCv14YYSfhsM
// http://localhost:3000/forwardingForm/a001i0000043BXL/a0e1i0000075sqcAAA/a0A1i00000C1hPDEAZ/csCv14YYSfhsM/Marco+Winkel/Kaarisma-Berater

const FormConnexys = (props: ConnexysPageProps): JSX.Element => {

  const { formId, candidateId, ApplicationId, accessKey, accountName, jobName } = useParams<ConnexysPageParamsProps>();
  const [forwardFormSubmitted, setForwardFormSubmitted] = React.useState(false);

  const excludedIP = [
    '217.239.231.18', /* Berlin */
    '90.187.239.117', /* Berlin (Backup) */
    '87.138.239.168' /* Mannheim */
  ];

  /**
   * Get current device type from browser information.
   * @returns device type (mobile, tablet, desktop)
   */
    const getDeviceType = () => {

    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
  }

  /**
   * Checks if the IP is on the list of excluded IPs.
   * @returns Boolean if the IP belongs to KAARISMA
   */
  const isCurrentIPFromKaarisma = () => {
    let isCurrentIPExcluded = excludedIP.findIndex((item: string) => {
      return item == getIPAddress();
    });
    return isCurrentIPExcluded !== -1;
  };

  /**
   * Request IP address from current site.
   * Nginx is configured to provide real IP address.
   */
  const getIPAddress = (): string => {

    if (document !== null) {
      const currentIP = document.querySelector<HTMLMetaElement>('meta[name="ip"]')?.content;

      if (currentIP !== undefined) {
        return currentIP;
      } else {
        return '';
      }
    }
    return '';
  }

  /**
   * Convert current date time into appropriate format: dd.mm.yyyy hh:mm
   */
  const getLastUpdated = () => {
    const d = new Date();
    return [
      d.getFullYear(),
      d.getMonth() + 1,
      d.getDate()].join('-') + ' '
      + [
        d.getHours(),
        d.getMinutes(),
        d.getSeconds()
      ].join(':');
  }

  React.useEffect(() => {

    const _cxsForm = window.cxsForm;

    // Execute the script function
    if (_cxsForm) {

      _cxsForm.init({
        target: '#cxsFormHolder',
        //server: 'https://pcsb-resourcemanagerplatinum-168c-16991e9dffa.cs105.force.com/', // TESTING
        server: 'https://kaarisma-cxs.my.salesforce-sites.com/',
        formId: formId,
        accessKey: accessKey,
        candidateId: candidateId,
        jobAppId: ApplicationId, // used for forwarding and privacy form
        jobId: ApplicationId, // used for job-specific application form
        lang: 'de',
        placeholders: true,
        errorHeader: true,
        textareaRows: 10,
        spinnerImg: 'https://kaarisma-cxs.my.salesforce-sites.com/resource/cxsrec__ajaxloader',
        onInit: () => {

          _cxsForm.setFieldValue('cxsrec__cxscandidate__c.ent_last_update_type__c', props.formType);
          _cxsForm.setFieldValue('cxsrec__cxscandidate__c.ent_last_update_date__c', getLastUpdated());

          // Get device type and IP
          let deviceType = getDeviceType();
          let IP = getIPAddress();

          // Distinguish all form types, as they have different field names
          switch (props.formType) {
            case 'Forwarding': {
              _cxsForm.setFieldValue('cxsrec__cxsjob_application__c.ent_mobile_vs_desktop__c', deviceType);
              _cxsForm.setFieldValue('cxsrec__cxsjob_application__c.ent_ip_address__c', IP);
              break;

            }
            case 'PrivacyStatement': {
              _cxsForm.setFieldValue('cxsrec__cxscandidate__c.ent_mobile_vs_desktop__c', deviceType);
              _cxsForm.setFieldValue('cxsrec__cxscandidate__c.ent_ip_address__c', IP);
              break;
            }
            case 'Application': {
              _cxsForm.setFieldValue('cxsrec__cxscandidate__c.ent_mobile_vs_desktop__c', deviceType);
              _cxsForm.setFieldValue('cxsrec__cxscandidate__c.ent_ip_address__c', IP);
              break;
            }
            default: break;
          }

        },
        onAfterSubmit: (success: boolean, jsonString: string) => {
          setForwardFormSubmitted(true);
        }
      });
    }

  }, []);

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<ConnexysFormDataProps>(CONNEXYSFORM_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  const replaceForwardingPrivacyText = (): string => {

    if (accountName && jobName) {

      // Bug: if job name contains (m/w/d) then react router thinks the / starts new param
      // Solution: entero passes "/" encoded as "%2F", which can be replaced
      let _jobName = jobName.replaceAll('%2F', '/');

      return data.connexysForm.forwardingPrivacyText.replace('{%accountName%}', accountName).replace('{%jobName%}', _jobName).replace('{%privacylink%}', '<a class="email inline-link" href="/datasafety">hier</a>').replaceAll('+', ' ');
    } else {
      return data?.connexysForm.forwardingPrivacyText;
    }
  }

  /**
   * Get stage title based on form type.
   * @returns title text
   */
  const getStageTitle = ():string => {
    switch (props.formType) {
      case 'Forwarding': {
        return data.connexysForm.titleForwarding;
      }
      case 'PrivacyStatement': {
        return data.connexysForm.titlePrivacyStatement;
      }
      case 'Application': {
        return data.connexysForm.titleApplication;
      }
      default: return '';
    }
  }

  // Disable form for employees
  if (isCurrentIPFromKaarisma()) {
    return <FormConnexysWrapper>
      {data && data.connexysForm.stage &&
        <Stage {...data.connexysForm.stage} title={getStageTitle()} />
      }
      <Title title='Diese Funktion ist für Mitarbeitende nicht verfügbar.' centered={true} className={'padding'}/>
    </FormConnexysWrapper>
  }

  return (
    <FormConnexysWrapper>
      {data && data.connexysForm.stage &&
        <Stage {...data.connexysForm.stage} title={getStageTitle()} />
      }
      <div className={'stage-overlap'}>
        {/* PRIVACY FORM */}
        {props.formType.indexOf('PrivacyStatement') !== -1 && data.connexysForm.textPrivacyStatement && <Text centered={true} title={data.connexysForm.textPrivacyStatement} />}
        {/* FORWARDING FORM */}
        {props.formType.indexOf('Forwarding') !== -1 && data.connexysForm.textForwarding && <Text centered={true} title={data.connexysForm.textForwarding} />}
        {props.formType.indexOf('Forwarding') !== -1 &&
          <ForwardingPrivacyText>
            {forwardFormSubmitted === false &&
              <Text centered={true} title={replaceForwardingPrivacyText()} />
            }
          </ForwardingPrivacyText>
        }
        <FormApplicationWrapper>
          <div id="cxsFormHolder" className={`form-${props.formType}`}></div>
        </FormApplicationWrapper>
      </div>
    </FormConnexysWrapper >
  );
};

export default FormConnexys;
