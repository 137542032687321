// define a minimal configuration
export const CookieConfig = {
  elementID: 'klaro-cookie-content',
  storageMethod: 'cookie',
  acceptAll: true,
  translations: {
    de: {
      privacyPolicyUrl: '/datasafety',
      consentNotice: {
        description: 'Dieser Text wird in der Einwilligungsbox erscheinen.',
      },
      decline: 'Ich lehne ab',
      ok: 'Das ist ok',
      acceptAll: 'Alle akzeptieren',
      consentModal: {
        description:
          'Hier können Sie einsehen und anpassen, welche Information wir über Sie ' +
          'sammeln. Einträge die als "Beispiel" gekennzeichnet sind dienen lediglich ' +
          'zu Demonstrationszwecken und werden nicht wirklich verwendet.',
      },
    }
  },
  services: [
    {
      name: 'google-analytics',
      purposes: ['performance'],
      // Klaro delete the Google Analytics cookies if the user declines its use
      cookies: [
        [/^_ga(_.*)?/, '/', 'kaarisma.de'],
        [/^_ga(_.*)?/, '/', 'karriereberater24.com'],
        [/^_gid(_.*)?/, '/', 'kaarisma.de'],
        [/^_gid(_.*)?/, '/', 'karriereberater24.com'],
      ],
    }
  ],
  callback: function (consent, service) {
  },
};

export function createCookieConfig(title, text, accept, decline, onCookieConsentChange) {
  let cookieConfig = CookieConfig;

  cookieConfig.translations.de.consentModal.description = text;
  cookieConfig.translations.de.consentNotice.description = text;
  cookieConfig.translations.de.decline = decline;
  cookieConfig.translations.de.ok = accept;

  cookieConfig.callback = onCookieConsentChange;

  return cookieConfig;
}
