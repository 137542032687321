import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const FactsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title, .text {
        align-self: center;
    }
`;

export const GridWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 48px;

    @media(${breakpoints.s}) {
        grid-template-columns: repeat(2, 1fr);
        max-width: 768px;
        margin: auto;
    }
`;

