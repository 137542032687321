import * as React from 'react';
import {
  SubheadingEl
} from './Subheading.styles';

export type TitleProps = {
  title: string,
  className?: string
};

const Subheading = (props: TitleProps): JSX.Element => {

  return (
    <SubheadingEl className={`${props.className || ''} subheading`}>{props.title}</SubheadingEl>
  );
};

export default Subheading;
