import * as React from 'react';
import { useQuery } from '@apollo/client';
import { JobProps, JOBS_QUERY } from '../../queries/jobs';
import { JoblistWrapper, FilterWrapper } from './Joblist.styles';
import Job from '../job/Job';
import Button from '../../components/button/Button';
import MoreSVG from '../../assets/icons/more.svg';
import Searchbox from 'src/components/searchbox/Searchbox';
import Select from 'src/components/select/Select';
import { useParams } from 'react-router-dom';
import { addFilters, jobSearch } from 'src/helper/search';
import Title from 'src/components/title/Title';

export type JoblistProps = {
  jobs?: Array<JobProps>,
  searchEnabled?: boolean,
};

type JoblistURLParams = {
  searchterm?: string
};

const Joblist = (props: JoblistProps): JSX.Element => {

  const TILES = 8;
  const [tilesShown, setTilesShown] = React.useState<number>(TILES);
  const [jobs, setJobs] = React.useState<Array<JobProps>>();
  const [FilterRegions, setFilterRegions] = React.useState([{ title: '', value: '' }]);
  const [FilterAreas, setFilterAreas] = React.useState([{ title: '', value: '' }]);
  const [filterValueRegions, setFilterValueRegions] = React.useState<string | undefined>();
  const [filterValueAreas, setFilterValueAreas] = React.useState<string | undefined>();
  const [currentSearchTerm, setCurrentSearchTerm] = React.useState<string>('');

  const { searchterm } = useParams<JoblistURLParams>();

  /* GraphQL*/
  const { loading, data, error } = useQuery<JoblistProps>(JOBS_QUERY);

  React.useEffect(() => {

    // Initially set jobs loaded from database
    if (jobs === undefined && data !== undefined) {
      setJobs(data?.jobs);

      // Add area filters
      const areas = [...new Set(data?.jobs?.map(item => item.Taetigkeitsfeld))];
      setFilterAreas(addFilters(areas));

      // Add region filters
      const regions = [...new Set(data?.jobs?.map(item => item.Bundesland))];
      setFilterRegions(addFilters(regions));

      // Set search term from URL
      if (searchterm && data.jobs) {
        setCurrentSearchTerm(searchterm);
      }
    }

    // Change search results in case they change (prevent infite rendering)
    if (data !== undefined && jobs !== undefined) {
      let newJobSearch = jobSearch(data.jobs || [], currentSearchTerm, filterValueRegions, filterValueAreas);
      let isResultEqual = JSON.stringify(newJobSearch) === JSON.stringify(jobs);

      if (!isResultEqual) {
        setJobs(newJobSearch);
      }
    }

  }, [jobs, data, data?.jobs, searchterm, filterValueAreas, filterValueRegions, currentSearchTerm]);

  if (loading) return <p>Lade Jobs...</p>;
  if (error) return <p>{error.message}</p>;
  if (!data) return <p></p>;

  const searchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

    // Get current search term and perform search
    const currentValue = e.currentTarget.value.toLowerCase();
    setCurrentSearchTerm(currentValue);
  };

  /**
   * Get current search term and perform search
   * @param e event handler of select element
   */
  const selectChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {

    const currentValue = e.currentTarget?.getAttribute('data-value') || '';

    if (e.currentTarget.id === 'filter-areas') {
      setFilterValueAreas(currentValue);
    } else if (e.currentTarget.id === 'filter-regions') {
      setFilterValueRegions(currentValue)
    }
  };

  /**
   * Load more search results.
   */
  const loadMore = () => {
    const currentScrollY = window.scrollY;
    const wrapperEl = document.querySelector('.joblist');

    if (wrapperEl) {
      wrapperEl.classList.add('hidden');
      setTilesShown(tilesShown + TILES);
      setTimeout(() => {
        window.scrollTo({ top: currentScrollY });
        wrapperEl.classList.remove('hidden');
        return;
      }, 300);
    }
  };

  return (
    <JoblistWrapper>
      {props.searchEnabled && <>
        <Searchbox theme={'dark'} onSearchChange={searchChange} defaultSearch={`${searchterm ? searchterm.toString() : ''}`} placeholder={'Nach Jobtitel, Ref-Nummer, Region, Tätigkeitsfeld oder Kompetenz suchen'} />
        <FilterWrapper>
          <Select options={FilterAreas} onSelectChange={selectChange} id={'filter-areas'} placeholder={'Tätigkeitsfeld'} />
          <Select options={FilterRegions} onSelectChange={selectChange} id={'filter-regions'} placeholder={'Region'} />
        </FilterWrapper>
        <Title title={`Suchergebnisse (${jobs ? jobs?.length : ''}):`} />
      </>}
      <div className={'grid joblist'}>
        {jobs && jobs.map((item: JobProps, index: number) => {
          if (index < tilesShown) {
            return <a href={'/job/' + item.Referenznummer} target={'_blank'} key={index}><Job {...item} Wochenstunden={item.Wochenstunden?.toString() + 'h'} /></a>;
          }
        })
        }
      </div>
      {
        jobs && jobs.length > tilesShown &&
        <Button title={'Mehr laden'} onClick={loadMore} className={'load-more'} icon={MoreSVG} buttonColor={'tertiary'} />
      }
    </JoblistWrapper>

  );
};

export default Joblist;
