import { FontHeavy, FontNormal } from 'src/styles/fonts';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const FAQWrapper = styled.div`

  background-color: white;
  padding: 19px;
  margin-bottom: 24px;
  transition: background-color 0.5s;
  border: 2px ${colors.grey4} solid;

  & .icon {
    transform: rotate(0deg);
    transition: transform 0.25s ease-in-out;
    color: red;
  }

  &.open {
    background-color: ${colors.grey4};

    & .icon {
      transform: rotate(45deg);
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
`;

export const FAQTitle = styled.span`
  font-family: ${FontHeavy};
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const FAQText = styled.span`
  font-family: ${FontNormal};
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  max-height: 0px;
  overflow: hidden;
  display: flex;
  transition: max-height 0.5s ease-in;

  &.open {
    max-height: 500px;
  }
`;
