import * as React from 'react';
import {
  ButtonEl,
  Circle,
  ButtonTitle
} from './Button.styles';
import { ReactSVG } from 'react-svg';
import LinkLightSVG from './../../assets/icons/link.svg';
import ScrolldownSVG from './../../assets/icons/scrolldown.svg';
import PlaySVG from './../../assets/icons/play.svg';
import { colors } from '../../styles/colors';
import { useHistory } from 'react-router-dom';
import Link from '../link/Link';

export type ButtonProps = {
  title: string,
  className?: string,
  fontColor?: string,
  buttonColor?: string,
  type?: string,
  disabled?: boolean,
  onClick?: () => void,
  route?: string,
  icon?: string,
  mailtoSubject?: string
};

const Button = (props: ButtonProps): JSX.Element => {

  const [isDisabled, /*setIsDisabled*/] = React.useState(props.disabled);

  const history = useHistory();

  const onClick = () => {

    // Navigate to page if this is  navigation button
    if (props.route && props.type?.indexOf('route') !== -1) {

      // Anker
      if (props.route.indexOf('#') !== -1) {
        let element = document.getElementById(props.route.replace('#', '')); // Remove # from route to getbyID
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
      // React Route
      else if (props.route.indexOf('http') === -1) {
        history.push(props.route);

        // Scroll to top of page
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      // external URL
      else {
        window.location.href = props.route;
      }
    }

    if (props.onClick) {
      props.onClick();
    }
  };

  const getIcon = () => {

    switch (props.icon) {
      case 'scrolldown': return ScrolldownSVG;
      case 'video': return PlaySVG;
      default: return LinkLightSVG;
    }
  };

  return (
    <ButtonEl className={`${props.className || ''} button ${isDisabled ? 'disabled' : ''}`} onClick={onClick}>
      {/* Mailto: Uses route string to attach recipient and subject */}
      {props.type === 'mailto' &&
        <Link fontColor={props.fontColor === 'light' ? 'white' : colors.grey1} title={props.title} url={('mailto:' + props.route) || ''} mailtoSubject={props.mailtoSubject} />
      }
      {/* External: Opens new tab with URL */}
      {props.type === 'external' &&
        <Link fontColor={props.fontColor === 'light' ? 'white' : colors.grey1} title={props.title} url={(props.route) || ''} />
      }
      {/* Default: button with no interaction */}
      {
        props.type !== 'mailto' && props.type !== 'external' &&
        <ButtonTitle disabled={isDisabled} type={'button'} style={{ color: props.fontColor === 'light' ? 'white' : colors.grey1 }} value={props.title} />

      }
      <Circle className={`circle background-${props.buttonColor || 'primary'}`}>
        <ReactSVG src={getIcon()} />
      </Circle>
    </ButtonEl>
  );
};

export default Button;
