import * as React from 'react';
import { SliderWrapper, SliderItem, SliderButton } from './Slider.styles';
import TextBlock, { TextBlockProps } from '../textBlock/TextBlock';
import { ReactSVG } from 'react-svg';
import PreviousSVG from './../../assets/icons/previous.svg';
import NextSVG from './../../assets/icons/next.svg';
import WindowSizeListener from 'react-window-size-listener'
import SliderButtonsMobile from 'src/components/slider-buttons-mobile/SliderButtonsMobile';

export type SliderProps = {
  items: Array<TextBlockProps>,
  backgroundColor?: string,
  className?: string,
  autoSlide?: boolean
};

const Slider = (props: SliderProps): JSX.Element => {

  const [currentSlide, setCurrentSlide] = React.useState(0);

  const nextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const previousSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const changeSlide = (_currentSlide: number) => {
    setCurrentSlide(_currentSlide);
  };

  const adjustSliderHeight = () => {
    let sliderItem = document.querySelector<HTMLDivElement>('.slider-item .grid');
    let sliderContainer = document.querySelector<HTMLDivElement>('.slider');
    if (sliderItem !== null && sliderContainer !== null) {
      const sliderHeight = sliderItem.scrollHeight;
      sliderContainer.style.height = sliderHeight + 'px';
    }
  };

  React.useEffect(() => {

    if (props.autoSlide) {
      const _timer: NodeJS.Timeout = setInterval(() => {

        // Loop through slide, reset at the end of it
        if (currentSlide === props.items.length - 1) {
          changeSlide(0);
        } else {
          nextSlide();
        }
      }, 10000);

      return () => clearInterval(_timer);
    }
  }, [currentSlide]);

  return (
    <SliderWrapper className={`slider background-${props.backgroundColor || ''} ${props.className || ''}`}>
      {currentSlide > 0 && <SliderButton onClick={previousSlide} className={'left'}>
        <ReactSVG src={PreviousSVG} />
      </SliderButton>}
      <WindowSizeListener onResize={adjustSliderHeight} />
      {props.items.map((item: TextBlockProps, index: number) => {
        return <SliderItem className={`slider-item ${index === currentSlide ? 'slide-visible' : 'slide-hidden'}`} key={index}>
          <TextBlock {...item} subtitle={`${item.subtitle} ${index + 1}/${props.items.length}`} navigationElements={<SliderButtonsMobile changeSlideHandler={changeSlide} itemsCount={props.items.length} currentSlide={currentSlide} />} />
        </SliderItem>
      })}
      {currentSlide < (props.items.length - 1) && <SliderButton onClick={nextSlide} className={'right'}>
        <ReactSVG src={NextSVG} />
      </SliderButton>}
    </SliderWrapper >
  );
};

export default Slider;
