import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { animations } from '../../styles/animations';
import { breakpoints } from 'src/styles/breakpoints';
import { FontHeavy } from 'src/styles/fonts';

export const SearchboxWrapper = styled.div`
  display: flex;
  flex-flow: column;

  .autocomplete {
    margin-top: -66px;
    text-align: left;

    option {
      max-width: 283px;

      @media(${breakpoints.s}) {
        max-width: unset;
      }
    }

    div {
      border: none;
    }
  }

  &.dark {
    input {
      border: solid 2px ${colors.grey1};
      color: ${colors.grey1};
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  height: 130px;

  & .circle-click {
    z-index: 3;
    margin-left: -110px;
    cursor: pointer;
    background-color: transparent;
  }

  &:hover {
    .circle {
      min-width: 120px;
      min-height: 120px;
      margin-left: -120px;

      & > div {
        margin-left: 10px;
      }
    }
  }

  & label {
    width: 0;
    opacity: 0;
  }
`;

export const SearchboxEl = styled.input`
  background-color: transparent;
  outline: none;
  flex-grow: 2;
  font-family: ${FontHeavy};
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 26px;
  font-size: 16px;
  border: solid 2px white;
  padding: 17px;
  padding-right: 110px;
  z-index: 2;
  text-overflow: ellipsis;
  color: white;
  min-width: 133px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: white;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: white;
  }

  &::-ms-input-placeholder {
    color: white;
  }

  &.dark {
    &::placeholder {
      color: ${colors.placeholder};
    }

    &:-ms-input-placeholder {
      color: ${colors.placeholder};
    }

    &::-ms-input-placeholder {
      color: ${colors.placeholder};
    }
  }
`;

export const Circle = styled.div`
  border-radius: 100px;
  background-color: ${colors.primary};
  min-width: 110px;
  min-height: 110px;
  margin-left: -110px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: min-width ${animations.buttons}, min-height ${animations.buttons}, margin-left ${animations.buttons};
  z-index: 1;
  position: relative;
  right: -12px;

  @media(${breakpoints.s}) {
    right: -26px;
  }

  & > div {
    transition: margin-left ${animations.buttons};
  }
`;
