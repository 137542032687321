import { gql } from '@apollo/client';
import { AreaProps } from 'src/components/area/Area';
import { StageProps } from 'src/modules/stage/Stage';
import { TextBlockProps } from 'src/modules/textBlock/TextBlock';
import { TEXTBLOCK_FRAGMENT } from './components';
import { STAGE_FRAGMENT } from './stage';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type ContactDataProps = {
  contact: {
    stage: StageProps,
    contacts: Array<TeamMemberDataProps>,
    contactTitle: string,
    title: string,
    subtitle: string,
    career: TextBlockProps,
    areas: Array<AreaProps>
  }
};

export const CONTACT_QUERY = gql`
  query {
    contact {
      ${STAGE_FRAGMENT}
      title
      subtitle
      areas {
        partner {
          ${TEAM_FRAGMENT}
        }
        title
        text
        areaImage {
          url
        }
      }
      career {
        ${TEXTBLOCK_FRAGMENT}
      }
      contactTitle
      contacts {
        ${TEAM_FRAGMENT}
      }
    }
  }
`;
