import { gql } from '@apollo/client';

export type CookieBannerDataProps = {
  cookiebanner: {
    title: string,
    text: string,
    accept: string,
    decline: string
  }
};

export const COOKIEBANNER_QUERY = gql`
query {
  cookiebanner {
    text
    title
    accept
    decline
  }
}
`;
