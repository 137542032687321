import * as React from 'react';
import { ImageEl, RatioContainer } from './Image.styles';

export type ImageProps = {
  source: string,
  alt: string,
  className?: string
};

const Image = (props: ImageProps): JSX.Element => {

  return (
    <RatioContainer className={`${props.className || ''} image`}>
      <ImageEl src={props.source} alt={props.alt} />
    </RatioContainer>
  );
};

export default Image;
