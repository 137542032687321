export const breakpointValues = {
  xs: '0px',
  s: '768px',
  l: '1440px',
  xl: '1920px'
};

export const breakpoints = {
  xs: `min-width: ${breakpointValues.xs}`,
  s: `min-width: ${breakpointValues.s}`,
  l: `min-width: ${breakpointValues.l}`,
  xl: `min-width: ${breakpointValues.xl}`
};
