import * as React from 'react';
import { useQuery } from '@apollo/client';
import Stage from '../modules/stage/Stage';
import Datapromise from '../modules/datapromise/Datapromise';
import Subtitle from 'src/components/Subtitle/Subtitle';
import Title from 'src/components/title/Title';
import TextBlock from 'src/modules/textBlock/TextBlock';
import { ContactDataProps, CONTACT_QUERY } from 'src/queries/contact';
import Areas from 'src/modules/areas/Areas';
import Shape from 'src/components/shape/Shape';
import Footer from 'src/modules/footer/Footer';
import { resetPageMetadata } from 'src/helper';
import Offices from 'src/modules/offices/Offices';
import ContactOffice from 'src/modules/contact-office/ContactOffice';
import Teamlist from 'src/modules/teamlist/Teamlist';

export type ContactPageProps = {

};

const ContactPage = (props: ContactPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<ContactDataProps>(CONTACT_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  const replaceStageTitle = (): string => {
    // Contact stage title length fix - if word too long, it's not centered anymore
    if (data && data.contact && data.contact.stage && data.contact.stage.title) {

      // If mobile, add -
      if (window.innerWidth < 400) {
        return data.contact.stage.title.replace('Erfolgsversprechen', 'Erfolgs-versprechen');
      }
      return data.contact.stage.title;
    }
    else {
      return '';
    }
  }

  return (
    <div>
      {data.contact.stage &&
        <Stage {...data.contact.stage} title={replaceStageTitle()} />
      }
      <div className={'stage-overlap'}>
        <Subtitle title={data.contact.subtitle} centered={true} />
        <Title title={data.contact.title} centered={true} />
      </div>
      <Areas areas={data.contact.areas} className={'overlap-margin'} />
      <Datapromise className={'margin'} />
      <Offices />
      <Shape alignment={'right'} type={3} y={500} />
      <TextBlock {...data.contact.career} className={'padding overlap-margin'} />
      {data.contact.contacts && data.contact.contacts.length === 0 &&
        <ContactOffice title={data.contact.contactTitle} />}
      {data.contact.contacts && data.contact.contacts.length >= 1 &&
        <Teamlist teams={data.contact.contacts} subtitle={data.contact.contactTitle} />}
      <Footer />
    </div >
  );
};

export default ContactPage;
