import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const ContactOfficeWrapper = styled.div`

  max-width: 800px;
  margin: auto;

  .contact-title {
    margin-bottom: 48px;
  }
`;

export const ContactOfficeInnerWrapper = styled.div`

  margin-bottom: 72px;

  .avatar {
    width: 128px;
    margin-bottom: 24px;
  }

  @media (${breakpoints.s}) {
    .avatar {
      width: 155px;
    }
  }

  & p {
    margin: 0px;
  }

  .social-icons-wrapper {

    @media (${breakpoints.s}) {
      margin-top: 0px;
    }

    .social-icon {
      margin-right: 12px;
      width: 48px;

      @media (${breakpoints.l}) {
        width: 66px;
      }
    }
  }
`;
export const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (${breakpoints.s}) {
    flex-direction: row;
  }

  .contact-group {
    margin-bottom: 32px;
  }

  & .link-wrapper {
    display: flex;
    white-space: nowrap;
  }

  .text {
    white-space: nowrap;
  }
`;

export const FrontdeskAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;

  @media (${breakpoints.s}) {
    flex-direction: row;
  }

  .title {
    display: flex;
    margin: 0px;
    margin-left: 16px;
  }
`;

export const FrontdeskTeamMember = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`;
