import * as React from 'react';
import { useQuery } from '@apollo/client';
import Stage from '../modules/stage/Stage';
import FAQs from 'src/modules/faqs/FAQs';
import { CareerDataProps, CAREER_QUERY } from 'src/queries/career';
import Subtitle from 'src/components/Subtitle/Subtitle';
import Title from 'src/components/title/Title';
import Shape from 'src/components/shape/Shape';
import JoblistCareer from 'src/modules/joblist-career/JoblistCareer';
import Footer from 'src/modules/footer/Footer';
import { resetPageMetadata } from 'src/helper';
import Values from 'src/modules/values/Values';
import TextBlock from 'src/modules/textBlock/TextBlock';
import Slider from 'src/modules/slider/Slider';
import Matchmaking from 'src/modules/matchmaking/Matchmaking';
import Offices from 'src/modules/offices/Offices';
import Button from 'src/components/button/Button';
import Teamlist from 'src/modules/teamlist/Teamlist';
import ContactOffice from 'src/modules/contact-office/ContactOffice';

export type CareerPageProps = {

};

const CareerPage = (props: CareerPageProps): JSX.Element => {

  const [toggleExperienceStatus, setToggleExperienceStatus] = React.useState(false);

  React.useEffect(() => {
    resetPageMetadata();
  }, [toggleExperienceStatus]);

  const onToggleExperienceStatus = (status: boolean) => {
    setToggleExperienceStatus(status);
  };

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<CareerDataProps>(CAREER_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      {data.career.stage &&
        <Stage {...data.career.stage} />
      }
      <div className={'stage-overlap'}>
        <Values values={data.career.values} title={data.career.benefitsTitle} />
        <Shape alignment={'left'} type={5} y={-390} />
        <Shape alignment={'right'} type={3} y={-390} />
      </div>
      <Button {...data.career.jobsAnker} className={'centered margin-bottom'} />
      <Slider items={data.career.slider} autoSlide={true} />
      <div className={'overlap-margin'} id={'matchmaking'}>
        {data.career.matchmaking && <Matchmaking {...data.career.matchmaking} />}
      </div>
      <div className={'overlap-margin'} id={'jobs'}>
        <Subtitle title={data.career.offersSubtitle} centered={true} />
        <Title title={data.career.offersTitle} centered={true} />
        <JoblistCareer experienceToggleStatus={toggleExperienceStatus} onToggleClick={onToggleExperienceStatus} />
      </div>
      <div className={'overlap-margin'}>
        <TextBlock {...data.career.testimonial} className={'margin-bottom'} />
        <Offices />
        <FAQs faqs={data.faqcareers} text={data.career.faqText} title={data.career.faqTitle} className={'margin-bottom'} />
        {data.career.contacts && data.career.contacts.length === 0 &&
          <ContactOffice title={data.career.contactTitle} />}
        {data.career.contacts && data.career.contacts.length >= 1 &&
          <Teamlist teams={data.career.contacts} subtitle={data.career.contactTitle} />}
      </div>
      <Footer />
    </div >
  );
};

export default CareerPage;
