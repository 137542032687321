import * as React from 'react';
import { ContactOfficeInnerWrapper, ContactOfficeWrapper, ContactWrapper, FrontdeskTeamMember, FrontdeskAvatarWrapper } from '../contact-office/ContactOffice.styles';
import Title from '../../components/title/Title';
import Text from '../../components/Text/Text';
import Image from '../../components/image/Image';
import XingSVG from './../../assets/icons/xing-white.svg';
import LinkedinSVG from './../../assets/icons/linkedin-white.svg';
import Shape from 'src/components/shape/Shape';
import { TeamMemberDataProps } from 'src/queries/team';
import { getAbsoluteImageURLReduced } from '../../helper';
import Obfuscate from 'react-obfuscate';
import Link from '../../components/link/Link';
import { useQuery } from '@apollo/client';
import { ImageSocialWrapper } from '../teammember/TeamMember.styles';
import { SocialMediaIcon } from '../footer/Footer.styles';
import Subtitle from 'src/components/Subtitle/Subtitle';
import { ContactNewsroomDataProps, CONTACT_NEWSROOM_QUERY } from 'src/queries/contact-newsroom';

// Reuse styles from ContactOffice

export type ContactNewsroomProps = {
  className?: string,
  title?: string
}

export const ContactNewsroom = (props: ContactNewsroomProps): JSX.Element => {

  /**
   * Formats all names (last name, middle name, first name).
   */
  const getNameFields = (name: string) => {
    if (name) {
      const names = name.split(' ');

      // CASE: only first + last name - each in one row
      if (names.length === 2) {
        return <div className={'names'}>
          <Title title={names[0]} className={`team-member-name`} />
          <Title title={names[1]} className={`team-member-name`} />
        </div>;
      }
      // CASE: also middle name - put middle name into first row
      else if (names.length === 3) {
        return <div className={'names'}>
          <Title title={`${names[0]} ${names[1]}`} className={`team-member-name`} />
          <Title title={names[2]} className={`team-member-name`} />
        </div>;
      }
    } else {
      return '';
    }
  }

  const isFirefox = () => {
    return navigator.userAgent.search("Firefox") !== -1
  }

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<ContactNewsroomDataProps>(CONTACT_NEWSROOM_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <ContactOfficeWrapper className={props.className || ''} id={'contact'}>
      <Shape alignment={'left'} type={5} y={210} />
      <Subtitle title={props.title || data.contactNewsroom.contactTitle} centered={true} />
      <ContactOfficeInnerWrapper>
        <FrontdeskAvatarWrapper>
          {data.contactNewsroom.frontdeskTeam.map((teamMember: TeamMemberDataProps, index: number) => {
            return <FrontdeskTeamMember key={index}>
              {teamMember.avatar && <Image source={getAbsoluteImageURLReduced(teamMember.avatar.url || data.contactNewsroom.avatar.url)} alt={'Profilbild Ansprechpartner ' + teamMember.name} className={'avatar'} />}
              {teamMember.name && getNameFields(teamMember.name)}
            </FrontdeskTeamMember>
          })}
        </FrontdeskAvatarWrapper>
        <ContactWrapper>
          <div className={'contact-group'}>
            <div className={'link-wrapper'}>
              <Text title={'Tel.:'} />
              {isFirefox() === false &&
                <Obfuscate tel={data.contactNewsroom.phone} className={'phone'}></Obfuscate>
              }
              {isFirefox() === true &&
                <Link title={data.contactNewsroom.phone} url={'tel:' + data.contactNewsroom.phone} className={'phone'} />
              }
            </div>
            <div className={'link-wrapper'}>
              <Text title={'Fax.:'} />
              {isFirefox() === false &&
                <Obfuscate tel={data.contactNewsroom.fax} className={'phone'}></Obfuscate>
              }
              {isFirefox() === true &&
                <Link title={data.contactNewsroom.fax} url={'tel:' + data.contactNewsroom.fax} className={'phone'} />
              }
            </div>
          </div>
          <div className={'contact-group'}>
            <div className={'link-wrapper'}>
              <Text title={'E-Mail:'} />
              {isFirefox() === false &&
                <Obfuscate email={data.contactNewsroom.email} className={'email'}></Obfuscate>
              }
              {isFirefox() === true &&
                <Link title={data.contactNewsroom.email} url={'mailto:' + data.contactNewsroom.email} className={'email'} />
              }
            </div>
            <div className={'link-wrapper'}>
              <Text title={'Web:'} />
              <Link title={data.contactNewsroom.web} url={data.contactNewsroom.web} className={'email'} />
            </div>
          </div>
          <ImageSocialWrapper className={'social-icons-wrapper'}>
            {data.contactNewsroom.xing && <SocialMediaIcon href={data.contactNewsroom.xing} target={'_blank'}><img className={'social-icon'} src={XingSVG} alt={'Xing'} /></SocialMediaIcon>}
            {data.contactNewsroom.linkedin && <SocialMediaIcon href={data.contactNewsroom.linkedin} target={'_blank'}><img className={'social-icon'} src={LinkedinSVG} alt={'LinkedIn'} /></SocialMediaIcon>}
          </ImageSocialWrapper>
        </ContactWrapper>
      </ContactOfficeInnerWrapper >
    </ContactOfficeWrapper >
  );
};

export default ContactNewsroom;
