import * as React from 'react';
import { FooterWrapper, TextWrapper, SocialMediaMobile, SocialMediaDesktop, SocialMediaIcon, NavigationWrapper, FooterHeader, FooterText, CopyrightText, CenterWrapper, CookieChangeText } from './Footer.styles';
import FacebookIcon from './../../assets/icons/facebook.svg';
import InstagramIcon from './../../assets/icons/instagram.svg';
import XingIcon from './../../assets/icons/xing.svg';
import LinkedinIcon from './../../assets/icons/linkedin.svg';
import { Link } from 'react-router-dom';
import { FooterDataProps, FOOTER_QUERY } from '../../queries/footer';
import { useQuery } from '@apollo/client';
import Shape from 'src/components/shape/Shape';
import { NavigationItemsProps } from '../header/Header';

export type FooterProps = {

};

const Footer = (props: FooterProps): JSX.Element => {

  const scrollTop = () => {
    // Scroll to top of page
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  /* Shows cookie modal to customize inital decision */
  const showCookieConsent = () => {
    if (window.klaro) {
      window.klaro.show();
    }
  }

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<FooterDataProps>(FOOTER_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <FooterWrapper className={'footer padding'}>
      <Shape alignment={'right'} type={11} y={-188} />
      <TextWrapper>
        <FooterHeader>{data.contactOffice.companyName}</FooterHeader>
        <FooterText>{data.contactOffice.street}<br />{data.contactOffice.address}</FooterText>
      </TextWrapper>
      <CenterWrapper>
        <SocialMediaMobile>
          {data.contactOffice.xing && <SocialMediaIcon href={data.contactOffice.xing} target={'_blank'}><img src={XingIcon} alt={'Xing'} /></SocialMediaIcon>}
          {data.contactOffice.linkedin && <SocialMediaIcon href={data.contactOffice.linkedin} target={'_blank'}><img src={LinkedinIcon} alt={'LinkedIn'} /></SocialMediaIcon>}
          {data.contactOffice.facebook && <SocialMediaIcon href={data.contactOffice.facebook} target={'_blank'}><img src={FacebookIcon} alt={'Facebook'} /></SocialMediaIcon>}
          {data.contactOffice.instagram && <SocialMediaIcon href={data.contactOffice.instagram} target={'_blank'}><img src={InstagramIcon} alt={'Instagram'} /></SocialMediaIcon>}
        </SocialMediaMobile>
        <NavigationWrapper>
          {
            data.footer.navigationItems && data.footer.navigationItems.map((item: NavigationItemsProps, index: number) => {
              return <Link to={item.route} className={'link'} key={index} onClick={scrollTop}>{item.title}</Link>;
            })
          }
        </NavigationWrapper>
        <SocialMediaDesktop>
          {data.contactOffice.xing && <SocialMediaIcon href={data.contactOffice.xing} target={'_blank'}><img src={XingIcon} alt={'Xing'} /></SocialMediaIcon>}
          {data.contactOffice.linkedin && <SocialMediaIcon href={data.contactOffice.linkedin} target={'_blank'}><img src={LinkedinIcon} alt={'LinkedIn'} /></SocialMediaIcon>}
          {data.contactOffice.facebook && <SocialMediaIcon href={data.contactOffice.facebook} target={'_blank'}><img src={FacebookIcon} alt={'Facebook'} /></SocialMediaIcon>}
          {data.contactOffice.instagram && <SocialMediaIcon href={data.contactOffice.instagram} target={'_blank'}><img src={InstagramIcon} alt={'Instagram'} /></SocialMediaIcon>}
        </SocialMediaDesktop>
        <CopyrightText>{data.footer.copyright}</CopyrightText>
        <CookieChangeText onClick={showCookieConsent}>Cookieeinstellungen anpassen</CookieChangeText>
      </CenterWrapper>
    </FooterWrapper >
  );
};

export default Footer;
