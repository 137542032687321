import * as React from 'react';
import {
  TextEl
} from './Text.styles';

export type TextProps = {
  title: string,
  className?: string,
  centered?: boolean
};

const Text = (props: TextProps): JSX.Element => {

  return (
    <TextEl className={`text ${props.className || ''} ${props.centered ? 'centered' : ''}`} dangerouslySetInnerHTML={{ __html: props.title }} />
  );
};

export default Text;
