import { gql } from '@apollo/client';

export type JobProps = {
  id: number,
  Bezeichnung: string,
  Referenznummer: string,
  Bundesland?: string,
  Beschreibung?: string,
  Kontakt?: string,
  Land?: string,
  Vorraussetzungen: string,
  Branche?: string,
  Gehalt?: string,
  Unternehmensinfo?: string,
  Wochenstunden?: number | string,
  Onlinebewerbung?: boolean,
  BewerbungsId?: string,
  Einleitung?: string,
  Taetigkeitsfeld?: string
};

export type JobsDataProps = {
  jobs: Array<JobProps>
};

export const JOB_FRAGMENT = `
  id
  Bezeichnung
  Referenznummer
  Bundesland
  Beschreibung
  Kontakt
  Land
  Vorraussetzungen
  Branche
  Gehalt
  Unternehmensinfo
  Onlinebewerbung
  BewerbungsId
  Wochenstunden
  Einleitung
  Taetigkeitsfeld
`;

export const JOBS_QUERY = gql`
    query {
        jobs {
          ${JOB_FRAGMENT}
        }
    }
`;
