import { breakpoints } from 'src/styles/breakpoints';
import { FontDemi } from 'src/styles/fonts';
import styled from 'styled-components';

export const AreaWrapper = styled.div`

  margin-bottom: 64px;
  flex-flow: column;
  text-align: center;
  display: flex;

  @media(${breakpoints.s}) {
    padding: 12px;
  }

  &.area-0, &.area-1 {
    .area-text {
      @media(${breakpoints.s}) {
        min-height: 200px;
      }
      @media(min-width: 960px) {
        min-height: 190px;
      }
      @media(min-width: 1100px) {
        min-height: 160px;
      }
      @media(min-width: 1400px) {
        min-height: 150px;
      }
      @media(${breakpoints.l}) {
        min-height: 230px;
      }
    }
  }

  &.area-2, &.area-3 {
    .area-text {
      @media(${breakpoints.s}) {
        min-height: 260px;
      }
      @media(min-width: 960px) {
        min-height: 210px;
      }
      @media(min-width: 1100px) {
        min-height: 190px;
      }
      @media(min-width: 1400px) {
        min-height: 170px;
      }
      @media(${breakpoints.l}) {
        min-height: 240px;
      }
    }
  }

  &.area-4 {
    .area-text {
      padding-bottom: 24px;
    }
  }

  .area-image-wrapper {
    padding-bottom: 56.25%;
    position: relative;
  }
`;

export const AreaImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
`;

export const AreaContent = styled.div`

`;

export const TextWrapper = styled.div`
  h1 {
    text-align: left;
    max-width: unset;
  }
`;

export const PartnerWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-flow: column;
  margin-top: 24px;

  img {
    margin-bottom: 24px;
  }

  @media(${breakpoints.s}) {
    flex-flow: row;
    margin-top: 0px;

    img {
      margin-bottom: 0px;
    }
  }
`;

export const Avatar = styled.img`
  width: 85px;
  height: 85px;
  border-radius: 100%;
  margin-right: 24px;
`;

export const PositionWrapper = styled.div`

  @media(${breakpoints.s}) {
    height: 90px;
  }

  @media(${breakpoints.l}) {
    height: 120px;
  }
`;

export const ContactDetailsWrapper = styled.div`

  .phone-wrapper {
    margin-top: 8px;
  }

  .link-wrapper {
    display: flex;
    white-space: nowrap;
  }
`;

export const ContactContentWrapper = styled.div`

  width: 100%;

  .name {
    margin-bottom: 8px;
    font-family: ${FontDemi};
  }
`;

export const LocationDetailsWrapper = styled.div`
  margin-top: 8px;
`;
