import { gql } from '@apollo/client';
import { StageProps } from 'src/modules/stage/Stage';
import { STAGE_FRAGMENT } from './stage';

export type DatasafetyDataProps = {
  datasafety: {
    stage: StageProps,
    content: string
  },
};

export const DATASAFETY_QUERY = gql`
query {
  datasafety {
    ${STAGE_FRAGMENT}
    content
  }
}
`;
