import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const JobWrapper = styled.div`

  border-bottom: 1px solid #000000;
  overflow: hidden;
  display: flex;
  max-width: calc(100vw - 40px);

  .job-info {
    flex-grow: 2;
  }

  & .jobTag {
    display: flex;
    align-items: center;
    margin-right: 18px;
    color: ${colors.grey1};

    @media (${breakpoints.s}) {
      margin-right: 32px;
    }

    .bullet {
      background-color: ${colors.primary};
      border-radius: 100%;
      min-width: 10px;
      height: 10px;
      margin-right: 10px;
    }

    .title {
      font-family: ${FontHeavy};
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 20px;
      letter-spacing: 1px;
      width: min-content;
      cursor: pointer;

      @media (${breakpoints.s}) {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 1.28571px;
        width: max-content;
      }
    }
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (${breakpoints.s}) {
    margin-bottom: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .subheading {
    max-width: calc(100% - 100px);
    letter-spacing: 2px;
    line-height: 24px;
    font-size: 18px;
    word-break: break-word;

    @media (${breakpoints.s}) {
      font-size: 24px;
    }
  }
`;
