import * as React from 'react';
import { TeamListOuterWrapper, TeamlistWrapper } from './Teamlist.styles';
import { TeamlistDataProps, TeamMemberDataProps, TEAM_QUERY } from '../../queries/team';
import { useQuery } from '@apollo/client';
import TeamMember from '../teammember/TeamMember';
import Button from '../../components/button/Button';
import _ from 'underscore';
import MoreSVG from '../../assets/icons/more.svg';
import Title from 'src/components/title/Title';
import Subtitle from 'src/components/Subtitle/Subtitle';

export type TeamlistProps = {
  title?: string,
  subtitle?: string,
  teams?: Array<TeamMemberDataProps>,
  isShuffled?: boolean,
  className?: string
};

const Teamlist = (props: TeamlistProps): JSX.Element => {

  const TILES = (window.innerWidth > 1440) ? 9 : 8;
  const [tilesShown, setTilesShown] = React.useState<number>(TILES);
  const [teamMembers, setTeamMembers] = React.useState<Array<TeamMemberDataProps>>([]);

  /* GraphQL */
  const { loading, data, error } = useQuery<TeamlistDataProps>(TEAM_QUERY);

  React.useEffect(() => {

    if (data && data.teams) {
      // Use props to fill team array if available, else load all team members from database
      let _team = props.teams === undefined ? data.teams : props.teams;

      // Optional: Shuffle array
      _team = props.isShuffled ? _.shuffle(_team) : _team;

      setTeamMembers(_team);
    }
  }, [data]);

  /**
   * Load more search results.
   */
  const loadMore = () => {
    const currentScrollY = window.scrollY;
    const wrapperEl = document.querySelector('.teamlist');

    if (wrapperEl) {
      wrapperEl.classList.add('hidden');
      setTilesShown(tilesShown + TILES);
      setTimeout(() => {
        window.scrollTo({ top: currentScrollY });
        wrapperEl.classList.remove('hidden');
        return;
      }, 300);
    }
  };

  if (loading) return <p>Still loading..</p>;
  if (error) return <p>error</p>;
  if (!data) return <></>;

  return (
    <TeamListOuterWrapper className={`overlap-margin ${props.className || ''}`}>
      {props.title && <Title title={props.title} centered={true} />}
      {props.subtitle && <Subtitle title={props.subtitle} centered={true} className={'contact-subtitle'} />}
      <TeamlistWrapper className={`grid teamlist has-${teamMembers?.length}-contact`}>
        {teamMembers &&
          teamMembers.map((item: TeamMemberDataProps, index: number) => {
            if (index < tilesShown) {
              return <TeamMember key={index} {...item} />;
            }
          })
        }
      </TeamlistWrapper >
      {teamMembers && teamMembers.length > tilesShown && <Button title={'Mehr laden'} onClick={loadMore} className={'load-more'} buttonColor={'tertiary'} icon={MoreSVG} />}
    </TeamListOuterWrapper>
  );
};

export default Teamlist;
