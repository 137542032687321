import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { FontDemi } from 'src/styles/fonts';

export const ToggleWrapper = styled.div`
  display: flex;
  margin: 32px auto;

  @media(${breakpoints.s}) {
    margin: 48px auto;
  }
`;

export const ToggleEl = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 0px 4px;
  cursor: pointer;
  transition: background-color 0.4s;
  height: 35px;
  width: 55px;

  @media(${breakpoints.s}) {
    height: 40px;
    width: 70px;
  }

  &.off {
    background-color: ${colors.primary};
    .circle {

      @media(${breakpoints.s}) {
        margin-left: 0px;
      }
    }
  }

  &.on {
    background-color: ${colors.secondary};
    .circle {

      margin-left: 26px;
      @media(${breakpoints.s}) {
       margin-left: 38px;
      }
    }
  }
`;

export const Circle = styled.div`
  border-radius: 100px;
  width: 29px;
  height: 29px;
  z-index: 1;
  background-color: white;
  transition: 0.3s margin-left;

  @media(${breakpoints.s}) {
    width: 32px;
    height: 32px;
  }
`;

export const ToggleText = styled.span`
  text-transform: uppercase;
  font-family: ${FontDemi};
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
  color: ${colors.grey1};
  padding: 0px 12px;
  align-items: center;
  display: flex;

  @media(${breakpoints.s}) {
    font-size: 18px;
    padding: 0px 24px;
  }

  &:first-of-type {
    padding-left: 0px;
  }

  &:last-of-type {
    padding-right: 0px;
  }
`;
