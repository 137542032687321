import { useQuery } from '@apollo/client';
import * as React from 'react';
import Stage from 'src/modules/stage/Stage';
import { DatasafetyDataProps, DATASAFETY_QUERY } from 'src/queries/datasafety';
import Footer from 'src/modules/footer/Footer';
import { resetPageMetadata } from 'src/helper';

export type DatasafetyPageProps = {

};

const DatasafetyPage = (props: DatasafetyPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<DatasafetyDataProps>(DATASAFETY_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      {data.datasafety.stage &&
        <Stage {...data.datasafety.stage} />
      }
      <div className={'stage-overlap legal-page'}>
        <div dangerouslySetInnerHTML={{ __html: data.datasafety.content }}></div>
      </div>
      <Footer />
    </div>
  );
};

export default DatasafetyPage;
