import * as React from 'react';
import { ValuesWrapper, Value } from './Values.styles';
import Text from '../../components/Text/Text';
import Subheading from '../../components/subheading/Subheading';
import { ReactSVG } from 'react-svg';
import Circle1VG from './../../assets/shapes/shape-8.svg';
import Circle2VG from './../../assets/shapes/shape-9.svg';
import Circle3VG from './../../assets/shapes/shape-10.svg';
import Title from 'src/components/title/Title';

export type ValueProps = {
  title: string,
  text: string
};

export type ValuesProps = {
  values: Array<ValueProps>,
  className?: string,
  title?: string
};

const Values = (props: ValuesProps): JSX.Element => {

  const getCircleSVG = (index: number) => {
    if (index === 0 || index === 3) {
      return Circle1VG;
    } else if (index === 1 || index === 4) {
      return Circle2VG;
    } else {
      return Circle3VG;
    }
  };

  return (
    <ValuesWrapper>
      {props.title && <Title title={props.title} centered={true} />}
      <div className={`grid values ${props.className || ''}`}>
        {props.values &&
          props.values.map((item: ValueProps, index: number) => {
            return <Value key={index} className={'value-' + (index + 1).toString()}>
              <ReactSVG src={getCircleSVG(index)} className={'circle'} />
              <Subheading title={item.title} />
              <Text title={item.text} />
            </Value>;
          })
        }
      </div>
    </ValuesWrapper>

  );
};

export default Values;
