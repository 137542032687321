import * as React from 'react';
import Title from 'src/components/title/Title';
import Text from '../../components/Text/Text';
import { ExcellenceWrapper, ExcellenceItemsWrapper, ExcellenceTitle, ExcellenceColumn } from './Excellence.styles';
import Column1SVG from '../../assets/icons/excellence-1.svg';
import Column2SVG from '../../assets/icons/excellence-2.svg';
import Column3SVG from '../../assets/icons/excellence-3.svg';
import { getAbsoluteImageURL } from '../../helper';

export type ExcellenceProps = {
  title: string;
  titleTextBlock1: string;
  contentTextBlock1: string;
  titleTextBlock2: string;
  contentTextBlock2: string;
  imageTextBlock3: {
    url: string;
  };
};

const Excellence = (props: ExcellenceProps): JSX.Element => {

  return (
    <ExcellenceWrapper className={'padding'}>
      <Title title={props.title} className={'section-title'} />
      <ExcellenceItemsWrapper>
        <ExcellenceColumn>
          <img src={Column1SVG} className={'column-svg'} alt={'Kreisform'} />
          <ExcellenceTitle className={'title'}>{props.titleTextBlock1}</ExcellenceTitle>
          <Text title={props.contentTextBlock1} />
        </ExcellenceColumn>
        <ExcellenceColumn>
          <img src={Column2SVG} className={'column-svg'} alt={'Kreisform'} />
          <ExcellenceTitle className={'title'}>{props.titleTextBlock2}</ExcellenceTitle>
          <Text title={props.contentTextBlock2} />
        </ExcellenceColumn>
        <ExcellenceColumn>
          <img src={Column3SVG} className={'column-svg'} alt={'Kreisform'} />
          <img src={getAbsoluteImageURL(props.imageTextBlock3.url)} className={'cms-image'} alt={'Match Talente und Arbeitgeber'} />
        </ExcellenceColumn>
      </ExcellenceItemsWrapper>
    </ExcellenceWrapper>
  );
};

export default Excellence;
