import { breakpoints } from 'src/styles/breakpoints';
import { colors } from 'src/styles/colors';
import styled from 'styled-components';

export const NewsElementWrapper = styled.a`

  margin-bottom: 64px;
  flex-flow: column;
  text-align: center;
  display: flex;
  text-decoration: none;


  @media(${breakpoints.s}) {
    padding: 12px;

    &:nth-child(odd) {
      padding-left: 0px;
    }

    &:nth-child(even) {
      padding-right: 0px;
    }
  }
`;

export const CoverImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;

  @media(${breakpoints.l}) {
    height: 260px;


  @media(${breakpoints.xl}) {
    height: 340px;
  }
`;

export const NewsElementContent = styled.div`

`;

export const TextWrapper = styled.div`
  text-align: left;

  .title {
    max-width: unset;
  }

  .content-preview {
    margin-bottom: 16px;
  }

  .date {
    color: ${colors.grey3};
    margin-bottom: 8px;
  }
`;

export const ContentPreview = styled.div``;
