import { gql } from '@apollo/client';
import { FactProps } from 'src/components/fact/Fact';
import { StageProps } from 'src/modules/stage/Stage';
import { TextBlockImageProps } from 'src/modules/textblock-image/TextBlockImage';
import { TextBlockProps } from 'src/modules/textBlock/TextBlock';
import { ValueProps } from 'src/modules/values/Values';
import { TEXTBLOCK_FRAGMENT, TEXTBLOCK_IMAGE_FRAGMENT } from './components';
import { STAGE_FRAGMENT } from './stage';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type AboutDataProps = {
  about: {
    stage: StageProps,
    values: Array<ValueProps>,
    vision: TextBlockImageProps,
    service: TextBlockProps,
    facts: Array<FactProps>,
    contacts: Array<TeamMemberDataProps>,
    contactTitle: string,
    factsTitle: string,
    factsText: string,
  }
};

export const ABOUT_QUERY = gql`
query {
  about {
    ${STAGE_FRAGMENT}
    values {
      title
      text
    }
    factsTitle
    factsText
    facts {
      title
      text
    }
    vision {
      ${TEXTBLOCK_IMAGE_FRAGMENT}
    }
    contactTitle
    contacts {
      ${TEAM_FRAGMENT}
    }
    service {
      ${TEXTBLOCK_FRAGMENT}
    }
  }
}
`;
