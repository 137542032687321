import { BUTTON_FRAGMENT } from './components';

export const STAGE_FRAGMENT = `
  stage {
    title
    jobsearch
    searchPlaceholder
    buttons {
      ${BUTTON_FRAGMENT}
    }
    backgroundImages {
      url
    }
    backgroundColor
    backgroundVideos {
      url
    }
  }
`;
