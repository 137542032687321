import Axios from 'axios';

export function getAbsoluteURL(base: string): string {

  return (process.env.REACT_APP_BASE_URI || '') + '/' + base;
}

export function getAbsoluteImageURL(base: string): string {

  return (process.env.REACT_APP_BASE_URI || '') + base;
}

export function getAbsoluteImageURLReduced(base: string | undefined, imagePrefix: string = 'small_'): string {

  if (base) {
    base = base.replace('/uploads/', '/uploads/' + imagePrefix);
  } else {
    base = '';
  }

  return (process.env.REACT_APP_BASE_URI || '') + base;
}

export async function getAPIToken(): Promise<string> {

  // API token already existing?
  let token = localStorage.getItem('api_token');

  if (token) {
    return token;
  } else {
    const { data } = await Axios.post<{ jwt: string }>(getAbsoluteURL('') + 'auth/local', {
      identifier: 'content',
      password: '>9oW4.L^j6>2yAF9',
    });

    localStorage.setItem('api_token', data.jwt);

    return data.jwt;
  }
}

export function formatDate(date: string): string {

  if (date) {
    let _date = new Date(date);
    return ('0' + _date.getDate().toString()).slice(-2) + '.' +
      ('0' + (_date.getMonth() + 1).toString()).slice(-2) + '.' +
      _date.getFullYear();
  }

  return '';
}

/**
 * Resets the current page head metadata to their defaults.
 * This may occur when using React router to navigate from a news article or job to a standard site.
 */
export function resetPageMetadata(
  OG_TITLE = 'KAARISMA Recruitment GmbH - Personalberatung',
  OG_DESCRIPTION = 'KAARISMA Recruitment ist die erste Adresse für spezialisierte Personalvermittlung in Deutschland.',
  OG_IMAGE = '/url_preview.jpg') {

  // Set page title and OG tags
  document.title = OG_TITLE;

  let meta_title = document.head.querySelector<HTMLMetaElement>('meta[property="og:title"]');
  if (meta_title) {
    meta_title.content = OG_TITLE;
  }

  let meta_description = document.head.querySelector<HTMLMetaElement>('meta[property="og:description"]');
  if (meta_description) {
    meta_description.content = OG_DESCRIPTION;
  }

  let meta_image = document.head.querySelector<HTMLMetaElement>('meta[property="og:image"]');
  if (meta_image) {
    meta_image.content = OG_IMAGE;
  }
}

export function scrollToElement(id: string) {
  let elementToScrollTo = document.getElementById(id);

  if (elementToScrollTo) {
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = elementToScrollTo.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;
    window.scrollTo(0, offset - 150);
  }
};
