import styled from 'styled-components';

export const ImageEl = styled.img`

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

export const RatioContainer = styled.div`
  position: relative;

  &::after {
    display: block;
    content: '';
    /* 1:1 aspect ratio */
    padding-bottom: 100%;
  }
`;
