import * as React from 'react';
import { ForkWrapper } from './Fork.styles';
import TextBlock from '../textBlock/TextBlock';
import { TextBlockProps } from '../textBlock/TextBlock';

export type ForkProps = {
  left: TextBlockProps,
  right: TextBlockProps,
  onButtonLeftClick?: () => void,
  onButtonRightClick?: () => void,
  className?: string
};

const Fork = (props: ForkProps): JSX.Element => {

  const onButtonLeftClick = () => {
    if (props.onButtonLeftClick) {
      props.onButtonLeftClick();
    }
  }

  const onButtonRightClick = () => {
    if (props.onButtonRightClick) {
      props.onButtonRightClick();
    }
  }

  return (
    <ForkWrapper className={`outer-grid ${props.className || ''}`}>
      <TextBlock {...props.left} className={'padding'} onClick={onButtonLeftClick} />
      <TextBlock {...props.right} className={'padding'} onClick={onButtonRightClick} />
    </ForkWrapper>
  );
};

export default Fork;
