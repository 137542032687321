import * as React from 'react';
import {
  SubtitleEl
} from './Subtitle.styles';

export type SubtitleProps = {
  title: string,
  className?: string,
  centered?: boolean
};

const Subtitle = (props: SubtitleProps): JSX.Element => {

  return (
    <SubtitleEl className={`${props.className || ''} subtitle ${props.centered ? 'centered' : ''}`}>{props.title}</SubtitleEl>
  );
};

export default Subtitle;
