import { gql } from '@apollo/client';
import { AreaProps } from 'src/components/area/Area';

export type TeamMemberDataProps = {
  name: string,
  email?: string,
  phone?: string,
  avatar?: {
    url: string
  },
  xing?: string,
  linkedin?: string,
  fax?: string,
  position?: string,
  leadership?: string,
  areaFunction?: string,
  areaIndustry?: string,
  web?: string,
  className?: string,
  office?: AreaProps
};

export type TeamlistDataProps = {
  teams: Array<TeamMemberDataProps>
};

export const TEAM_FRAGMENT: string = `
  name
  position
  areaFunction
  areaIndustry
  leadership
  phone
  email
  fax
  linkedin
  xing
  web
  office {
    title
  }
  avatar {
    url
  }
`;

export const TEAM_QUERY = gql`
  query {
    teams {
      ${TEAM_FRAGMENT}
    }
  }
`;
