import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const MatchmakingWrapper = styled.div`

  margin-bottom: 136px;

  .grid {
    display: grid;
    grid-gap: 16px;
    justify-items: center;
    margin-top: 80px;
    grid-template-columns: repeat(8, 1fr);
    transition: margin-left 1s;
  }

  @media(${breakpoints.l}) {
    .grid {
      grid-template-columns: repeat(4, 1fr);
      margin-left: 0px !important;
      height: 820px;
    }
  }

  .grid.animate-matched {

    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 240px;
    margin-left: 0px !important;
    margin-top: 24px;
    height: 350px;

    .match-value {
      margin-bottom: -176px;

      &:hover {
        margin-left: 20px;
      }
    }

    @media(${breakpoints.l}) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 24px;

      .match-value {
        &:hover {
          margin-left: 0px;
        }
      }
    }
  }

  .button-wrapper {
    margin-top: 32px;
    flex-flow: column;
    align-items: center;

    .button {
      margin-right: 0px;
      margin-top: -24px;
    }

    @media(${breakpoints.s}) {
      flex-flow: row;

      .button {
        margin-right: 24px;
        margin-top: -20px;
      }
    }
  }
`;

export const MobileNavigation = styled.div`

  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: space-between;

  @media(${breakpoints.l}) {
    display: none;
  }
`;


