import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const AutocompleteWrapper = styled.div`
  height: 0px;
`;

export const SelectOptions = styled.div`
  border: solid 2px ${colors.grey1};
  border-top: none;
  display: flex;
  flex-direction: column;
`;

export const OptionEl = styled.option`
  cursor: pointer;
  font-size: 16px;
  font-family: ${FontHeavy};
  text-transform: uppercase;
  color: ${colors.grey1};
  background-color: white;
  letter-spacing: 1px;
  line-height: 26px;
  padding: 25px;
  z-index: 100;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;
