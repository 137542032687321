import { gql } from '@apollo/client';
import { NewsElementProps } from 'src/components/newsElement/NewsElement';
import { ExcellenceProps } from 'src/modules/excellence/Excellence';
import { StageProps } from 'src/modules/stage/Stage';
import { TextBlockProps } from 'src/modules/textBlock/TextBlock';
import { TEXTBLOCK_FRAGMENT } from './components';
import { NEWS_ELEMENT_FRAGMENT } from './news';
import { STAGE_FRAGMENT } from './stage';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type LandingDataProps = {
  startseite: {
    stage: StageProps,
    contacts: Array<TeamMemberDataProps>,
    contactTitle: string,
    forkLeft: TextBlockProps,
    forkRight: TextBlockProps,
    career: TextBlockProps,
    excellence: ExcellenceProps,
    newsTitle: string
  },
  presses: Array<NewsElementProps>
};

export const LANDING_QUERY = gql`
query {
  startseite {
    ${STAGE_FRAGMENT}
    forkLeft {
      ${TEXTBLOCK_FRAGMENT}
    }
    forkRight {
      ${TEXTBLOCK_FRAGMENT}
    }
    excellence {
      title
      titleTextBlock1
      contentTextBlock1
      titleTextBlock2
      contentTextBlock2
      imageTextBlock3 {
        url
      }
    }
    career {
      ${TEXTBLOCK_FRAGMENT}
    }
    contactTitle
    contacts {
      ${TEAM_FRAGMENT}
    }
    newsTitle
  }
  presses (sort: "date:desc") {
    ${NEWS_ELEMENT_FRAGMENT}
  }
}
`;
