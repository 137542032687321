import { gql } from '@apollo/client';
import { StageProps } from 'src/modules/stage/Stage';
import { STAGE_FRAGMENT } from './stage';

export type ImpressumDataProps = {
  impressum: {
    stage: StageProps,
    content: string
  },
};

export const IMPRESSUM_QUERY = gql`
query {
  impressum {
    ${STAGE_FRAGMENT}
    content
  }
}
`;
