import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const MatchValueWrapper = styled.div`
  border-radius: 100%;
  width: 278px;
  height: 278px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  transition: margin-top 0.5s, margin-left 0.5s;

  .hover-outer-wrapper {
    display: grid;
    align-content: center;
  }

  .hover-state {
    border-radius: 100%;
    width: 278px;
    height: 278px;
    padding: 15px 30px 15px 30px;
    box-sizing: border-box;
    overflow: hidden;
    display: none;

    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .normal-state {
    .title {
      font-size: 24px;
    }
  }

  &.selected {
    .normal-state {
        display: none;
    }

    .hover-state {
      display: flex;
    }
  }

  &:hover {
    z-index: 100;
    margin-top: -10px;
    cursor: pointer;
  }

    &:hover {
      .normal-state {
        display: none;
      }

      .hover-state {
        display: flex;
      }
    }

  @media(${breakpoints.l}) {
    width: 305px;
    height: 305px;

    &:nth-child(even) {
      margin-top: 100px;

      &:hover {
        margin-top: 90px;
      }
    }
  }

  h1, h2, p {
    color: white;
    margin-bottom: 8px;
    max-width: unset;
    line-height: 24px;
    pointer-events: none;
  }

  p {
    font-size: 16px;
    text-align: center;
    line-height: 20px;
  }

  h2 {
    text-align: center;
    word-break: normal;
    justify-content: center;
  }
`;

export const SelectButton = styled.div`
  display: flex;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 28px;
  min-width: 200px;
  margin-top: 8px;

  &.disabled {
    pointer-events: none;
  }

  span {
    font-family: ${FontHeavy};
    font-size: 16px;
    text-decoration: underline;
    margin-left: 8px;
    text-transform: uppercase;

    @media(${breakpoints.s}) {
      font-size: 18px;
    }
  }
`;
