import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const TextBlockImageWrapper = styled.div`

  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 136px;

  .image-left, .image-right {
    display: none;
    position: absolute;
  }

  .title, .text, .subtitle {
    max-width: unset;
    justify-content: center;
    text-align: center;

    @media(${breakpoints.s}) {
      padding: 0px 25%;
    }

    @media(${breakpoints.l}) {
      padding: 0px 30%;
    }
  }

  .button {
    justify-content: center;
  }

  @media(${breakpoints.l}) {
    .image-left, .image-right {
      display: block;
      width: 201px;
    }

    .image-left {
      left: 159px;
    }

    .image-right {
      right: -75px;
      margin-top: 200px;
    }
  }

  @media(${breakpoints.xl}) {
    .image-left, .image-right {
      width: 268px;
    }

    .image-left {
      left: 83px;
    }

    .image-right {
      right: -65px;
    }
  }

`;
