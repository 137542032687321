import { gql } from '@apollo/client';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type ContactNewsroomDataProps = {
  contactNewsroom: {
    contactTitle: string,
    phone: string,
    fax?: string,
    email: string,
    web: string,
    companyName: string,
    street: string,
    address: string,
    avatar: {
      url: string
    },
    name: string,
    xing: string,
    facebook: string,
    instagram: string,
    linkedin: string,
    frontdeskTeam: Array<TeamMemberDataProps>
  }
};

export const CONTACT_NEWSROOM_QUERY = gql`
  query {
    contactNewsroom {
      contactTitle
      phone
      fax
      email
      web
      companyName
      street
      address
      avatar {
        url
      }
      name
      xing
      facebook
      instagram
      linkedin
      frontdeskTeam {
        ${TEAM_FRAGMENT}
      }
    }
  }
`;
