import { gql } from '@apollo/client';
import { NavigationItemsProps } from 'src/modules/header/Header';

export type FooterDataProps = {
  footer: {
    navigationItems: Array<NavigationItemsProps>,
    copyright: string
  },
  contactOffice: {
    companyName: string,
    street: string,
    address: string,
    xing?: string,
    linkedin?: string,
    instagram?: string,
    facebook?: string,
  }
};

export const FOOTER_QUERY = gql`
    query {
        footer {
            navigationItems {
                title,
                route
            },
            copyright
        }
        contactOffice {
          companyName
          street
          address
          xing
          linkedin
          facebook
          instagram
        }
    }
`;
