import * as React from 'react';
import { SelectEl, SelectWrapper, OptionEl, SelectHeader, SelectOptions } from './Select.styles';
import { ReactSVG } from 'react-svg';
import ArrowSVG from './../../assets/icons/arrow.svg';

export type SelectOptionProps = {
  value: string,
  title: string,
};

export type SelectProps = {
  options: Array<SelectOptionProps>,
  defaultValue?: string,
  className?: string,
  placeholder?: string,
  onSelectChange?(e?: React.MouseEvent<HTMLDivElement, MouseEvent>): void,
  id?: string
};

const Select = (props: SelectProps): JSX.Element => {

  const [value, setValue] = React.useState(props.options.find((i) => { return i.value === props.defaultValue; }) || { title: '', value: '' });
  const [isOpen, setIsOpen] = React.useState(false);

  const onOpenClick = (e?: React.MouseEvent<HTMLDivElement>): void => {
    setIsOpen(!isOpen);
  };

  const onSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLOptionElement>): void => {

    // Display current value and close menu
    setValue({
      value: e.currentTarget?.getAttribute('data-value') || '',
      title: e.currentTarget?.getAttribute('data-title') || ''
    });

    onOpenClick();
  };


  const selectHandlerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {

    onSelect(e);
    if (props.onSelectChange) {
      props.onSelectChange(e);
    }
  };

  return (
    <SelectWrapper className={`${props.className || ''} select`} onClick={onOpenClick}>
      <SelectHeader className={`${isOpen ? 'select-open' : ''}`}>
        <SelectEl placeholder={props.placeholder} value={value.title} readOnly={true} />
        <ReactSVG src={ArrowSVG} className={'arrow'} />
      </SelectHeader>
      {isOpen &&
        <SelectOptions>
          <OptionEl data-value={'default'} data-title={'(Alle)'} id={props.id || ''} onClick={selectHandlerClick}>{'(Alle)'}</OptionEl>
          {props.options && props.options.map((option, index) => {
            return <OptionEl key={index} data-value={option.value} data-title={option.title} id={props.id || ''} onClick={selectHandlerClick} >{option.title}</OptionEl>;
          })}
        </SelectOptions>
      }
    </SelectWrapper>
  );
};

export default Select;
