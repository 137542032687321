import React from 'react';
import './styles/App.scss';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import LandingPage from './pages/landing';
import Header from './modules/header/Header';
import JobdetailPage from './pages/jobdetail';
import AboutPage from './pages/about';
import CandidatesPage from './pages/candidates';
import ClientsPage from './pages/clients';
import ContactPage from './pages/contact';
import CareerPage from './pages/career';
import { getAbsoluteURL, getAPIToken } from './helper';
import GoogleAnalytics from './modules/google-analytics/GoogleAnalytics';
import ImpressumPage from './pages/impressum';
import DatasafetyPage from './pages/datasafety';
import CareerJobdetailPage from './pages/career-jobdetail';
import NewsPage from './pages/news';
import NewsroomPage from './pages/newsroom';
import ConnexysConsentPage from './pages/connexys';


const IS_DEVELOPMENT = window.location.hostname.indexOf('localhost') !== -1;
const httpLink = new HttpLink({ uri: IS_DEVELOPMENT ? 'http://localhost/graphql' : getAbsoluteURL('graphql') });

const authMiddleware = () =>
  new ApolloLink((operation, forward) => {

    getAPIToken()
      .then(token => {
        // add the authorization to the headers
        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      })
      .catch((error) => 'error');

    return forward(operation);

  });

const client = new ApolloClient({
  link: authMiddleware().concat(httpLink),
  cache: new InMemoryCache()
});

const isJobPostingIFrame = () => {
  // Check if this is a Xing job posting
  // Background: Xing caches the Job Posting URL on their own servers. So React Routing doesn't work anymore.
  let isXingURL = document.location.href.indexOf('/xing') !== -1 || document.location.href.indexOf('/outsourced_postings') !== -1;
  let hasCorrectJobID = getJobPostingID() !== null;

  return isXingURL && hasCorrectJobID;
}

/**
 * Parse job id (Referenznummer) from query
 */
const getJobPostingID = (): string | null => {

  // Only load this info when used inside xing iframe
  if (document.location.href.indexOf('xing.com')) {

    // xing caching has original url with job id in header/ baseURI
    let params = document.head.baseURI;
    if (params) {
      let id = params.split('id=');

      // If params are correct, we can extract id from something like: https://kaarisma.de/xing?id=001636
      if (id && id.length >= 2) {
        return id[1];
      }
    }
  }
  return null;
}

/**
 * Make stage accessible via fixed password. Check local storage for previous access.
 * @returns
 */
const stagingPasswordPrompt = () => {
  if (window.location.hostname.indexOf('karriereberater24.com') !== -1) {
    let isStagingLoggedIn = window.localStorage.getItem('staging');

    // Ask for password for the first time
    // Save access in local storage to available in all tabs
    if (isStagingLoggedIn === null) {
      if (prompt('Enter staging password:') === '4kaarisma') {
        window.localStorage.setItem('staging', 'true');
        return true;
      } else return false;
    } else return true;
  }
  // Localhost/ Production: no password
  else return true;
}

function App(): JSX.Element {

  return (
    <ApolloProvider client={client}>
      {stagingPasswordPrompt() !== false && <Router>
        <div className='App'>
          {
            isJobPostingIFrame() && <JobdetailPage hasNavigation={false} id={getJobPostingID() || ''} />
          }
          {
            isJobPostingIFrame() === false && <Switch>
              {/*<Route path='/job/portal/:id'>
                  <JobdetailPage hasNavigation={false} />
            </Route>*/}
              <Route path='/'>
                <Header />
                <GoogleAnalytics />
                <Switch>
                  <Route path='/about'>
                    <AboutPage />
                  </Route>
                  <Route path='/candidates/:searchterm'>
                    <CandidatesPage />
                  </Route>
                  <Route path='/candidates'>
                    <CandidatesPage />
                  </Route>
                  <Route path='/clients'>
                    <ClientsPage />
                  </Route>
                  <Route path='/contact'>
                    <ContactPage />
                  </Route>
                  <Route path='/career/:id'>
                    <CareerJobdetailPage hasNavigation={true} />
                  </Route>
                  <Route path='/career'>
                    <CareerPage />
                  </Route>
                  <Route path='/news/:id'>
                    <NewsPage />
                  </Route>
                  <Route path='/news'>
                    <NewsroomPage />
                  </Route>
                  <Route path='/job/:id'>
                    <JobdetailPage hasNavigation={true} />
                  </Route>
                  <Route path='/impressum'>
                    <ImpressumPage />
                  </Route>
                  <Route path='/datasafety'>
                    <DatasafetyPage />
                  </Route>
                  <Route path='/forwardingForm/:formId/:ApplicationId/:candidateId/:accessKey/:accountName/:jobName'>
                    <ConnexysConsentPage formType={'Forwarding'} />
                  </Route>
                  <Route path='/privacyStatementForm/:formId/:candidateId/:accessKey'>
                    <ConnexysConsentPage formType={'PrivacyStatement'} />
                  </Route>
                  <Route path='/application/:ApplicationId'>
                    <ConnexysConsentPage formType={'Application'} />
                  </Route>
                  <Route path='/'>
                    <LandingPage />
                  </Route>
                </Switch>
              </Route>
            </Switch>
          }
        </div>
      </Router>
      }
    </ApolloProvider >
  );
}

export default App;
