import * as React from 'react';
import Stage from '../modules/stage/Stage';
import { useQuery } from '@apollo/client';
import { JobdetailDataProps, JOBDETAIL_QUERY } from '../queries/jobdetail';
import Jobdetails from '../modules/jobdetails/Jobdetails';
import { useHistory, useParams } from 'react-router-dom';
import { JobProps } from '../queries/jobs';
import Shape from 'src/components/shape/Shape';
import { formatString, makeListFromString } from 'src/helper/jobs'

import AreaITImage from '../assets/images/IT.jpg';
import AreaEngineeringImage from '../assets/images/Engineering.jpg';
import AreaVertriebImage from '../assets/images/Vertrieb.jpg';
import AreaBauwesenImage from '../assets/images/Bauwesen.jpg';
import AreaFinanzenImage from '../assets/images/Finanzen.jpg';
import Footer from 'src/modules/footer/Footer';
import { resetPageMetadata } from 'src/helper';
import Teamlist from 'src/modules/teamlist/Teamlist';


export type JobdetailPageProps = {
  hasNavigation?: boolean,
  id?: string
};

type JobdetailPageParams = {
  id?: string
};

const JobdetailPage = (props: JobdetailPageProps): JSX.Element => {

  const { id } = useParams<JobdetailPageParams>();
  const [currentJob, setCurrentJob] = React.useState<JobProps>();
  const { loading, data, error } = useQuery<JobdetailDataProps>(JOBDETAIL_QUERY, { variables: { jobID: props.id || id } });

  const history = useHistory();

  React.useEffect(() => {

    // Get job with current ref number from all available jobs
    if (data) {
      let job;
      if (props.id) {
        job = data.jobs.find((item: JobProps) => { return item.Referenznummer === props.id; });
      } else if (id) {
        job = data.jobs.find((item: JobProps) => { return item.Referenznummer === id; });
      }

      if (job) {
        setCurrentJob(job);
        resetPageMetadata(job?.Bezeichnung, (job?.Einleitung || '') + job?.Bezeichnung);
      } else {
        // Navigate to candidate page if ref number not existing
        history.push('/candidates');
      }
    }
  }, [data]);

  /* Graph QL data query*/
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;


  type CompanyInfoProps = {
    companyInfoText: string,
    companyInfoOfferText: string,
    benefitsList: Array<string>
  };

  /**
   * Formats the company info field in its two separare entities returned as CompanyInfoProps.
   */
  const formatCompanyinfoString = (): CompanyInfoProps => {

    const text = currentJob?.Unternehmensinfo;

    const emptyResult = {
      companyInfoText: '',
      companyInfoOfferText: '',
      benefitsList: new Array<string>()
    };

    // Check if there is a text at all
    if (!text) {
      return emptyResult;
    }

    // Check if the text has following format
    // <p>%companyText%</p>
    // <p>%companyTextOffer%</p>
    // <ul>%benfitsList%</ul>
    const contentParts = text.split('<ul>');
    let companyInfoText: string = '', companyInfoOfferText: string = '';
    let benefitList;
    if (contentParts && contentParts.length === 2) {

      // Split first two parahgraphs
      let companyInfoParts = contentParts[0].split(/<p(.*?)>|<\/p>/g);
      companyInfoParts = companyInfoParts.filter((item) => { return item !== undefined && item.length > 0 })

      // First part are p-Elements
      if (companyInfoParts && companyInfoParts.length === 2) {
        companyInfoText = formatString(companyInfoParts[0]);
        companyInfoOfferText = formatString(companyInfoParts[1]);
      }

      // Second part is ul-Element
      benefitList = formatString(contentParts[1]);
    } else {
      return emptyResult;
    }

    return {
      companyInfoText: companyInfoText,
      companyInfoOfferText: companyInfoOfferText,
      benefitsList: makeListFromString(benefitList)
    };
  };

  const loadJobareaStageImage = (): Array<{ url: string }> => {
    if (currentJob && currentJob.Taetigkeitsfeld) {

      // Format area name without spaces and umlaute
      let area = currentJob.Taetigkeitsfeld;
      area = area.replaceAll(' ', '-');
      area = area.replaceAll('ä', 'ae');
      area = area.replaceAll('ö', 'oe');
      area = area.replaceAll('ü', 'ue');

      switch (area) {
        case 'IT': return [{ url: AreaITImage }];
        case 'Engineering': return [{ url: AreaEngineeringImage }];
        case 'Vertrieb': return [{ url: AreaVertriebImage }];
        case 'Bauwesen': return [{ url: AreaBauwesenImage }];
        case 'Finanzen': return [{ url: AreaFinanzenImage }];
      }
    }

    return [data.jobdetail.backgroundImage];
  };

  return (
    <div>
      {data.jobdetail && currentJob && <>
        <Stage title={currentJob.Bezeichnung} subtitle={currentJob.Einleitung} text={`REF. NR. ${currentJob.Referenznummer}`} backgroundImages={loadJobareaStageImage()} useStaticSourceBackground={true} />

        <Shape alignment={'right'} type={12} y={300} aboveAll={true} />
        <div className={'stage-overlap'}>
          <Jobdetails {...data.jobdetail}
            taskTitle={data.jobdetail.taskTitle}
            profileTitle={data.jobdetail.profileTitle}
            profileList={makeListFromString(currentJob.Vorraussetzungen)}
            taskList={makeListFromString(currentJob.Beschreibung || '')}
            hasNavigation={props.hasNavigation}
            companyText={formatCompanyinfoString().companyInfoText}
            companyTextOffer={formatCompanyinfoString().companyInfoOfferText}
            benefitsList={formatCompanyinfoString().benefitsList}
            applicationID={currentJob.BewerbungsId || ''}
            jobname={currentJob.Bezeichnung} />
        </div >
        {data.jobdetail.contactsExternal && <Teamlist teams={data.jobdetail.contactsExternal} />}
      </>}
      {props.hasNavigation && <Footer />}
    </div>
  );
};

export default JobdetailPage;
