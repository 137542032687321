import * as React from 'react';
import Teamlist from '../modules/teamlist/Teamlist';
import { useQuery } from '@apollo/client';
import { AboutDataProps, ABOUT_QUERY } from '../queries/about';
import Values from '../modules/values/Values';
import Stage from '../modules/stage/Stage';
import TextBlockImage from '../modules/textblock-image/TextBlockImage';
import Facts from '../modules/facts/Facts';
import TextBlock from '../modules/textBlock/TextBlock';
import Datapromise from '../modules/datapromise/Datapromise';
import Shape from 'src/components/shape/Shape';
import Footer from 'src/modules/footer/Footer';
import { resetPageMetadata } from 'src/helper';
import Offices from 'src/modules/offices/Offices';
import ContactOffice from 'src/modules/contact-office/ContactOffice';

export type AboutPageProps = {

};

const AboutPage = (props: AboutPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<AboutDataProps>(ABOUT_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      {data.about.stage &&
        <Stage {...data.about.stage} />
      }
      <div className={'stage-overlap'}>
        <Facts facts={data.about.facts} factsText={data.about.factsText} factsTitle={data.about.factsTitle} />
      </div>
      <div className={'margin'}>
        <Shape alignment={'left'} type={2} y={-400} aboveAll={true} />
        <Shape alignment={'right'} type={6} y={-400} aboveAll={true} />
        <TextBlockImage {...data.about.vision} />
        <Values values={data.about.values} className={'margin-bottom'} />
        <Shape alignment={'right'} type={12} y={-200} />
        <TextBlock {...data.about.service} />
        <Datapromise hideAboutButton={true} />
        <Offices />
        <Teamlist title={'Unser Team'} isShuffled={true} />
        {data.about.contacts && data.about.contacts.length === 0 &&
          <ContactOffice title={data.about.contactTitle} />}
        {data.about.contacts && data.about.contacts.length >= 1 &&
          <Teamlist teams={data.about.contacts} subtitle={data.about.contactTitle} />}
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
