import { colors } from "src/styles/colors";
import styled from "styled-components";

export const SliderButtonMobile = styled.div`
  border-radius: 100%;
  background-color: ${colors.primary};
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  margin-right: 8px;

  &.disabled {
    background-color: ${colors.grey2};
    pointer-events: none;
    opacity: 0.7;
  }

  svg {
    transition: 0.4s width, 0.4s height;
    width: 16px;
    height: 16px;
  }
`;

export const SliderButtonsMobileWrapper = styled.div`
  display: flex;
`;
