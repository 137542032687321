import { gql } from '@apollo/client';
import { TextBlockImageProps } from 'src/modules/textblock-image/TextBlockImage';
import { TEXTBLOCK_IMAGE_FRAGMENT } from './components';

export type DatapromiseDataProps = {
  datapromise: TextBlockImageProps
};

export const DATAPROMISE_QUERY = gql`
query {
  datapromise {
    ${TEXTBLOCK_IMAGE_FRAGMENT}
  }
}
`;
