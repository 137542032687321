import * as React from 'react';
import { useQuery } from '@apollo/client';
import { DatapromiseDataProps, DATAPROMISE_QUERY } from '../../queries/datapromise';
import TextBlockImage from '../textblock-image/TextBlockImage';
import { DatapromiseWrapper } from './Datapromise.styles';

export type DatapromiseProps = {
  className?: string,
  hideAboutButton?: boolean
};

const Datapromise = (props: DatapromiseProps): JSX.Element => {

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<DatapromiseDataProps>(DATAPROMISE_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <DatapromiseWrapper className={props.className || ''}>
      <TextBlockImage {...data.datapromise} button={props.hideAboutButton ? undefined : data.datapromise.button} />
    </DatapromiseWrapper>
  );
};

export default Datapromise;
