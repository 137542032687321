import { colors } from 'src/styles/colors';
import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const ProcessWrapper = styled.div`

  padding: 16px;

    @media(${breakpoints.s}) {
      padding: 48px;
      margin: auto;
    }

    margin-bottom: 136px;

    .title {
      margin-bottom: 64px;
      max-width: 735px;
    }
`;

export const StepsWrapper = styled.div`
    max-width: 735px;
    margin: auto;

    @media(${breakpoints.l}) {
      padding-left: 125px;
    }
`;

export const StepWrapper = styled.div`

  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  .text {
    max-width: 50vw;
  }

  @media(${breakpoints.s}) {
    .text {
      width: 33.33333333vw;
      max-width: 309px;
    }

    .lower-text {
      align-items: flex-start;

      div {
        margin-top: 100px;
      }
    }
  }

  @media(${breakpoints.l}) {
    .text {
      width: 25vw;
    }
  }

  @media(${breakpoints.xl}) {
    .text {
      width: 16.66666667vw;
    }
  }


`;

export const StepContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  @media(${breakpoints.s}) {
    align-items: center;
    margin-bottom: 0px;
  }
`;

export const StepImage = styled.img`
  width: 155px;
  height: 155px;
  border-radius: 100%;
  margin-right: 16px;

  @media(${breakpoints.s}) {
    .step-image {
      width: 208px;
      height: 208px;
      margin-right: 24px;
    }
  }

  @media(${breakpoints.l}) {
    .step-image {
      width: 274px;
      height: 274px;
      margin-right: 74px;
    }
  }
`;

export const StepNumberWrapper = styled.div`

  .text {
    font-size: 16px;
  }
`;

export const StepNumber = styled.p`
  font-size: 40px;
  line-height: 51px;
  letter-spacing: 2px;
  color: ${colors.grey1};
  margin-bottom: 8px;
  font-family: ${FontHeavy};
  margin-top: 0px;
`;

export const StepRight = styled.img`
    margin-left: -190px;
    display: none;

    @media(${breakpoints.s}) {
      display: flex;
    }

    &.last {
      opacity: 0;
    }
`;

export const StepLeft = styled.img`
  margin-top: -10px;
  margin-right: -10px;
  display: none;

  @media(${breakpoints.s}) {
    display: flex;
    margin-left: 70px;
  }

  &.last {
    opacity: 0;
  }
`;
