import './cookies.scss'
import { createCookieConfig } from './CookieConfig';
import { useQuery } from '@apollo/client';
import * as React from 'react';
import { CookieBannerDataProps, COOKIEBANNER_QUERY } from 'src/queries/cookiebanner';

export type CookieBannerProps = {
  onCookieConsentChange?(consent: any, service: any): void,
};

declare global {
  interface Window {
    klaro: {
      show(): void,
      getManager(): {
        getConsent(s: string): boolean
      }
    },
    klaroConfig: {},
    cookieModal: HTMLDivElement | null
  }
}

const CookieBanner = (props: CookieBannerProps): JSX.Element => {

  React.useEffect(() => {

    // Initialize Klaro cookie consent

    if (data) {

      var Klaro = require('klaro');

      if (!window.klaro) {
        // we assign the Klaro module to the window, so that we can access it in JS
        window.klaro = Klaro;

        let config = createCookieConfig(data?.cookiebanner.title, data?.cookiebanner.text, data?.cookiebanner.accept, data?.cookiebanner.decline, props.onCookieConsentChange);
        window.klaroConfig = config;

        // we set up Klaro with the config
        Klaro.setup(config);
      }
    }
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<CookieBannerDataProps>(COOKIEBANNER_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <></>
  );
};

export default CookieBanner;


