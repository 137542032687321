import * as React from 'react';
import { HeaderWrapper, NavigationWrapperMobile, MenuWrapper, MobileMenu, NavigationWrapperDesktop, NavDropdown, NavDropdownMenuItem, NavDropdownItemsWrapper, DesktopMenu } from './Header.styles';
import { ReactSVG } from 'react-svg';
import LogoWhiteSVG from './../../assets/icons/logo-white.svg';
import LogoDarkSVG from './../../assets/icons/logo-dark.svg';
import MenuMobileSVG from './../../assets/icons/menu-mobile.svg';
import CloseSVG from './../../assets/icons/close.svg';
import { Link } from 'react-router-dom';
import { HEADER_QUERY } from '../../queries/header';
import { useQuery } from '@apollo/client';

export type NavigationItemsProps = {
  title: string,
  route: string,
  group: string
};

export type HeaderProps = {
  theme?: string
};

export type HeaderDataProps = {
  header: {
    navigationItems: Array<NavigationItemsProps>
  }
};

const Header = (props: HeaderProps): JSX.Element => {

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isSticky, setIsSticky] = React.useState(false);

  /**
 * Make header sticky if certain threshold is met.
 */
  const headerFixedScroll = () => {

    const header = document.querySelector('header');
    const stickyHidden = 300;
    const stickyVisible = 500;

    if (header) {
      if (window.pageYOffset < stickyHidden) {
        header.classList.remove('sticky-hidden');
        setIsSticky(false);
      }
      else if (window.pageYOffset > stickyHidden && window.pageYOffset < stickyVisible) {
        header.classList.add('sticky-hidden');
        header.classList.remove('sticky');
        setIsSticky(false);
      }
      else if (window.pageYOffset > stickyVisible) {
        header.classList.remove('sticky-hidden');
        header.classList.add('sticky');
        setIsSticky(true);
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', headerFixedScroll);
  });

  /**
   * Toogle visibility of mobile menu.
   */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSticky(!isSticky);
  };

  /**
   * Toogle visibility of mobile menu.
   */
  const toggleMenuWithScrollTop = () => {
    setIsMenuOpen(false);
    setIsSticky(false);

    // Scroll to window top when closing menu and routing
    scrollTop();
  };

  const scrollTop = () => {
    // Scroll to top of page
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<HeaderDataProps>(HEADER_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <HeaderWrapper className={`header padding ${isMenuOpen ? 'menu-open' : ''} ${isSticky ? 'sticky' : ''}`}>
      <MenuWrapper>
        <DesktopMenu>
          <Link to={'/'} className={'logo-wrapper'}>
            <img src={LogoWhiteSVG} className={`logo ${isMenuOpen ? 'mobile-menu-open' : ''}`} alt={'KAARISMA Logo'} onClick={toggleMenuWithScrollTop} />
          </Link>
          <Link to={'/'} className={`logo-wrapper-sticky ${isMenuOpen ? 'mobile-menu-open' : ''} ${!isSticky ? 'sticky-hidden' : ''}`}>
            <img src={LogoDarkSVG} className={'logo-sticky'} alt={'KAARISMA Logo'} onClick={toggleMenuWithScrollTop} />
          </Link>
        </DesktopMenu>
        <MobileMenu>

          {!isMenuOpen && <Link to={'/'} className={'logo-wrapper'}>
            <img src={LogoWhiteSVG} className={`logo`} alt={'KAARISMA Logo'} onClick={toggleMenuWithScrollTop} />
          </Link>}
          <Link to={'/'}>
            <img src={LogoDarkSVG} className={`logo logo-dark ${isMenuOpen ? 'mobile-menu-open' : ''}`} alt={'dark KAARISMA logo'} onClick={toggleMenuWithScrollTop} />
          </Link>
          {!isMenuOpen &&
            <ReactSVG src={MenuMobileSVG} onClick={toggleMenu} />
          }
          {isMenuOpen &&
            <ReactSVG src={CloseSVG} onClick={toggleMenu} className={'close'} />
          }
        </MobileMenu>
        <NavigationWrapperDesktop className={`${props.theme || ''}`}>
          {
            /* First add all group-less items */
            data.header.navigationItems && data.header.navigationItems.map((item: NavigationItemsProps, index: number) => {
              if (item.group === 'none') {
                return <Link key={index} to={item.route} className={'link'} onClick={scrollTop}>{item.title}</Link>;
              } else return '';
            })
          }
          {
            /* Second add all about-group items */
            <NavDropdown>
              <NavDropdownMenuItem className={'nav-dropdown-menu-item'}>Über Kaarisma</NavDropdownMenuItem>
              <NavDropdownItemsWrapper className={'nav-dropdown-items-wrapper'}>
                {
                  data.header.navigationItems && data.header.navigationItems.map((item: NavigationItemsProps, index: number) => {
                    if (item.group === 'about') {
                      return <Link key={index} to={item.route} className={'link nav-dropdown-link'} onClick={scrollTop}>{item.title}</Link>;
                    } else return '';
                  })
                }
              </NavDropdownItemsWrapper>
            </NavDropdown>
          }
        </NavigationWrapperDesktop>
      </MenuWrapper>

      {isMenuOpen && <NavigationWrapperMobile>
        {
          data.header.navigationItems && data.header.navigationItems.map((item: NavigationItemsProps, index: number) => {
            return <Link key={index} to={item.route} className={'link'} onClick={toggleMenuWithScrollTop}>{item.title}</Link>;
          })
        }
      </NavigationWrapperMobile>}
    </HeaderWrapper >
  );
};

export default Header;
