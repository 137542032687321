import * as React from 'react';
import { FAQTitle, FAQWrapper, FAQText, TitleWrapper } from './FAQ.styles';
import { ReactSVG } from 'react-svg';
import OpenSVG from './../../assets/icons/close-blue.svg';

export type FAQProps = {
  question: string,
  answer: string
};

const FAQ = (props: FAQProps): JSX.Element => {

  const [isOpen, setIsOpen] = React.useState(false);

  const onOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FAQWrapper className={`faq ${isOpen ? 'open' : ''}`}>
      <TitleWrapper onClick={onOpen}>
        <FAQTitle>{props.question}</FAQTitle>
        <ReactSVG src={OpenSVG} className={'icon'} />
      </TitleWrapper>
      <FAQText className={`faq ${isOpen ? 'open' : ''}`} dangerouslySetInnerHTML={{ __html: props.answer }} />
    </FAQWrapper>
  );
};

export default FAQ;
