import { FontHeavy, FontNormal } from 'src/styles/fonts';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const FactWrapper = styled.div`
  padding: 19px;
  margin-bottom: 72px;
  flex-flow: column;
  text-align: center;
  display: flex;
`;

export const FactTitle = styled.span`
  font-family: ${FontHeavy};
  font-style: normal;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 3px;
  color: ${colors.primary};
`;

export const FactText = styled.span`
  font-family: ${FontNormal};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 1px;
  border-bottom: 1px ${colors.primary} solid;
  align-self: center;
  max-width: 282px;
`;
