import styled from 'styled-components';

export const JoblistWrapper = styled.div`
    & .grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;
      opacity: 1;
      transition: opacity 0.2s;

      &.hidden {
        opacity: 0;
      }
    }

    & .load-more {
      justify-content: center;
      margin-top: 24px;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
`;
