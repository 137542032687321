import * as React from 'react';
import Text from '../Text/Text';
import Title from '../title/Title';
import { CoverImage, TextWrapper, NewsElementContent, NewsElementWrapper } from './NewsElement.styles';
import { formatDate, getAbsoluteImageURLReduced } from '../../helper';
import { TeamMemberDataProps } from 'src/queries/team';
import Link from '../link/Link';

export type NewsElementProps = {
  id: string,
  title: string,
  content: string,
  date: string,
  coverImage: {
    url: string
  },
  contacts: Array<TeamMemberDataProps>,
  index: number
};

export const NewsElement = (props: NewsElementProps): JSX.Element => {

  const removeMarkup = (text: string) => {
    // Remove formatting: **, #, <ul>, images

    if (text) {

      text = text.replaceAll(/<u(.*?)>|<\/u>/g, '');
      text = text.replace(/([*])/g, '');
      text = text.replace(/([#])/g, '');
    }

    if (text.length > 200) {
      return text.substring(0, 200) + "...";
    } else {
      return text;
    }

  }

  return (
    <NewsElementWrapper className={`news-element news-${props.index}`} href={`/news/${props.id}`}>
      {props.coverImage && <CoverImage src={getAbsoluteImageURLReduced(props.coverImage.url)} />}
      <NewsElementContent>
        <TextWrapper>
          <Title title={props.title} />
          <Text title={formatDate(props.date)} className={'date'} />
          {props.content && <Text title={removeMarkup(props.content)} className={'content-preview'} />}
          <Link title={'Jetzt mehr lesen...'} url={`/news/${props.id}`} />
        </TextWrapper>
      </NewsElementContent>
    </NewsElementWrapper >
  );
};

export default NewsElement;
