import { gql } from '@apollo/client';
import { ButtonProps } from 'src/components/button/Button';
import { BUTTON_FRAGMENT } from './components';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type JobKaarismaProps = {
  id: number,
  Bezeichnung: string,
  Referenznummer: string,
  Beschreibung: string,
  Vorraussetzungen: string,
  BewerbungsId: string,
  Office: string,
  Wochenstunden: string,
  Erfahrung: string
};

export type JobKaarismaDataProps = {
  jobKaarismas: Array<JobKaarismaProps>,
  jobdetail: {
    contactsExternal: Array<TeamMemberDataProps>,
    contactsInternal: Array<TeamMemberDataProps>,
    contactTitle: string,
    profileTitleInternal: string,
    taskTitleInternal: string,
    aboutCareerList: string,
    aboutTitle: string,
    aboutCareerText: string,
    shareButton: ButtonProps,
    backgroundImage: {
      url: string
    },
    ranking: number,
    internalApplyButton: ButtonProps
  }
};

export const JOB_KAARISMA_FRAGMENT = `
  id
  Bezeichnung
  Referenznummer
  Beschreibung
  Vorraussetzungen
  Ranking
  Erfahrung
  Wochenstunden
  Office
`;

export const JOBS_KAARISMA_QUERY = gql`
    query {
        jobKaarismas (sort: "Ranking:asc") {
          ${JOB_KAARISMA_FRAGMENT}
        }
        jobdetail {
          contactsExternal {
            ${TEAM_FRAGMENT}
          }
          contactsInternal {
            ${TEAM_FRAGMENT}
          }
          shareButton {
            ${BUTTON_FRAGMENT}
          }
          internalApplyButton {
            ${BUTTON_FRAGMENT}
          }
          backgroundImage {
            url
          }
          aboutCareerList
          aboutTitle
          aboutCareerText
          taskTitleInternal
          profileTitleInternal
        }
    }
`;
