import { colors } from 'src/styles/colors';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const TextBlockWrapper = styled.div`

  color: ${colors.grey1};
  margin-bottom: 136px;

  &.grid {
    display: grid;
    margin: 80px 0px;

    &.right {
      direction: rtl;

      & .span-col-6 {
        direction: ltr;
      }

      & .image {
      }
    }

    @media (${breakpoints.xs}) {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 16px;
    }

    @media (${breakpoints.s}) {
      grid-gap: 24px;

      .image {
        width: 66%;
        max-width: 440px;
      }
    }

    @media (${breakpoints.l}) {
        grid-template-columns: repeat(12, 1fr);

        .image {
          width: 84%;
          max-width: 526px;

          &.right {
            float: right;
          }
        }
    }
  }

  & .span-col-6 {

    @media (${breakpoints.xs}) {
      grid-column: span 6 / auto;
    }
  }

  &.background-transparent {
    .title, .subtitle, .text {
      color: ${colors.grey1};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;

  @media (${breakpoints.xl}) {
    flex-flow: row;
  }
`;
