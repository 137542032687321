import { breakpoints } from 'src/styles/breakpoints';
import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const SelectWrapper = styled.div`
  margin-bottom: 16px;
  height: 63px;

  @media(${breakpoints.s}) {
    margin-bottom: 32px;
  }
`;

export const SelectHeader = styled.div`
  border: solid 2px ${colors.grey1};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;

  & .arrow {
    transform: rotate(0deg);
    transition: transform 0.5s;
  }

  &.select-open .arrow {
    transform: rotate(180deg);
  }
`;

export const SelectEl = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  flex-grow: 2;
  pointer-events: none;
  font-family: ${FontHeavy};
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 26px;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.placeholder};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${colors.placeholder};
  }

  &::-ms-input-placeholder {
    color: ${colors.placeholder};
  }
`;

export const SelectOptions = styled.div`
  border: solid 2px ${colors.grey1};
  border-top: none;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: white;
  max-height: 322px;

  @media(${breakpoints.s}) {
    max-height: 422px;
  }
`;

export const OptionEl = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-family: ${FontHeavy};
  text-transform: uppercase;
  color: ${colors.grey1};
  background-color: white;
  letter-spacing: 1px;
  line-height: 26px;
  padding: 8px;
  z-index: 100;

  @media(${breakpoints.s}) {
    padding: 25px;
  }

  &:hover {
    background-color: ${colors.tertiary};
  }
`;
