import * as React from 'react';
import {
  ListEl, ListItem, ListWrapper
} from './List.styles';
import Subheading from '../subheading/Subheading';
import Text from '../Text/Text';
import BulletSVG from '../../assets/icons/list-item.svg';

export type ListProps = {
  items: Array<string>,
  title?: string,
  text?: string
};

const List = (props: ListProps): JSX.Element => {

  return (
    <ListWrapper className={'list'}>
      {props.title && <Subheading title={props.title} />}
      {props.text && <Text title={props.text} className={'list-text'} />}
      <ListEl>
        {
          props.items && props.items.map((item, index) => {
            return <ListItem key={index}>
              <img src={BulletSVG} alt={'bullet-point'} />
              <span>{item}</span>
            </ListItem>;
          })
        }
      </ListEl>
    </ListWrapper>
  );
};

export default List;
