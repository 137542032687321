import { gql } from '@apollo/client';
import { ButtonProps } from 'src/components/button/Button';
import { BUTTON_FRAGMENT } from './components';
import { JobProps, JOB_FRAGMENT } from './jobs';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type JobdetailDataProps = {
  jobdetail: {
    contactsExternal: Array<TeamMemberDataProps>,
    contactsInternal: Array<TeamMemberDataProps>,
    contactTitle: string,
    profileTitle: string,
    taskTitle: string,
    profileTitleInternal: string,
    taskTitleInternal: string,
    aboutList: string,
    aboutTitle: string,
    aboutText: string,
    applyButton: ButtonProps,
    shareButton: ButtonProps,
    backgroundImage: {
      url: string
    }
  },
  jobs: Array<JobProps>
};

export const JOBDETAIL_QUERY = gql`
query {
    jobdetail {
      contactsExternal {
        ${TEAM_FRAGMENT}
      }
      contactsInternal {
        ${TEAM_FRAGMENT}
      }
      applyButton {
        ${BUTTON_FRAGMENT}
      }
      shareButton {
        ${BUTTON_FRAGMENT}
      }
      backgroundImage {
        url
      }
      profileTitle
      taskTitle
      profileTitleInternal
      taskTitleInternal
      aboutList
      aboutTitle
      aboutText
    }
  jobs {
    ${JOB_FRAGMENT}
  }
}
`;
