import * as React from 'react';
import { SelectOptionProps } from '../select/Select';
import { AutocompleteWrapper, OptionEl, SelectOptions } from './Autocomplete.styles';

export type AutocompleteProps = {
  options: Array<SelectOptionProps>,
  className?: string,
  show?: boolean,
  onSelectClick?(e?: React.MouseEvent<HTMLOptionElement, MouseEvent>): void
};

const Autocomplete = (props: AutocompleteProps): JSX.Element => {

  const clickHandler = (e?: React.MouseEvent<HTMLOptionElement, MouseEvent>): void => {
    if (props.onSelectClick) {
      props.onSelectClick(e);
    }
  };

  return (
    <AutocompleteWrapper className={`${props.className || ''} autocomplete`} >
      {props.show && props.options.length > 0 &&
        <SelectOptions>
          {props.options && props.options.map((option, index) => {
            return <OptionEl key={index} value={option.value} title={option.title} onClick={clickHandler} >{option.title}</OptionEl>;
          })}
        </SelectOptions>
      }
    </AutocompleteWrapper>
  );
};

export default Autocomplete;
