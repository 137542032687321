import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const NewsWrapper = styled.div`

  .grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }

  & .load-more {
    justify-content: center;
    margin-top: 24px;
  }

  @media(${breakpoints.s}) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  `;


