import styled from 'styled-components';

export const ShareWrapper = styled.div`
  text-align: center;
  margin-top: -110px;
  margin-bottom: 43px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  button {
    border-radius: 100%;
    overflow: hidden;
    width: 64px;
    height: 64px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0px;
    }
  }

  &.open {
    opacity: 1;
  }
`;


