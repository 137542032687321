import { FontNormal } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const TextEl = styled.p`
  display: flex;
  font-family: ${FontNormal};
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  color: ${colors.grey1};
  text-align: left;
  margin: 0px;

  &.centered {
    margin: auto;
    text-align: center;
    justify-content: center;
  }

  @media (${breakpoints.s}) {
    font-size: 18px;
    line-height: 28px;
    max-width: 90%;

  @media (${breakpoints.l}) {
    font-size: 24px;
    line-height: 34px;
  }
`;
