import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const LinkEl = styled.a`
  display: flex;
  font-family: Futura PT Book;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  color: ${colors.primary};
  text-align: left;
  margin: 0px;

  &:hover {
    color: ${colors.primary} !important;
  }

  @media (${breakpoints.s}) {
    font-size: 18px;
    line-height: 28px;
    max-width: 90%;

  @media (${breakpoints.l}) {
    font-size: 24px;
    line-height: 34px;
  }
`;
