import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const StageWrapper = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-top: 210px;
  padding-bottom: 25px;
  background-size: cover;
  background-position-x: 50%;
  text-align: center;
  position: relative;
  padding-top: 25%;

  @media (${breakpoints.s}) {
    padding-bottom: 210px;
   }

  @media (${breakpoints.l}) {
   padding-bottom: 350px;
  }

  @media (${breakpoints.xl}) {
  }

  .subtitle, .title {
    color: white;
    z-index: 1;
  }

  .text {
    padding-bottom: 150px;

    @media (${breakpoints.s}) {
      padding-bottom: 96px;
    }
  }

  .searchbox {
    max-width: 870px;
    margin-bottom: 32px;

    @media(${breakpoints.s}) {
      width: 80%;
    }
  }

  @media (${breakpoints.l}) {

    & .title {
      max-width: 83%;
    }

    & .searchbox {
      max-width: 66%;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .button {
    margin-right: 85px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const StageVideo = styled.video`
  top: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const VideoGradient = styled.div`
  background-color: ${colors.primary};
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;
