import * as React from 'react';
import { ReactSVG } from 'react-svg';
import Subheading from '../subheading/Subheading';
import Text from '../Text/Text';
import { MatchValueWrapper, SelectButton } from './MatchValue.styles';
import MatchUnselectedSVG from './../../assets/icons/match-unselected.svg';
import MatchSelectedSVG from './../../assets/icons/match-selected.svg';
import { getAbsoluteImageURLReduced } from 'src/helper';
import Title from '../title/Title';

export type MatchValueProps = {
  title: string,
  text: string,
  backgroundColor: string,
  image?: {
    url: string
  },
  className?: string,
  onClick?: (value: MatchValueProps) => void,
  disabled?: boolean,
  isSelected: boolean,
  matchmaker: boolean
};

const MatchValue = (props: MatchValueProps): JSX.Element => {

  const [isSelected, setIsSelected] = React.useState(props.isSelected || false);

  const toggleSelected = () => {

    // disabled matching after maximal attempts
    if (!props.disabled) {

      let _isSelected = !isSelected;
      setIsSelected(_isSelected);

      // Pass match values to parent
      if (props.onClick) {
        props.onClick({
          ...props,
          isSelected: _isSelected
        });
      }
    }
  };

  return (
    <MatchValueWrapper className={`match-value ${props.className || ''} ${isSelected ? 'selected' : ''} background-${props.backgroundColor}`} onClick={toggleSelected} style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url(${getAbsoluteImageURLReduced(props.image?.url || '', 'medium_')})`
    }}>
      <div className={'normal-state'}>
        <Title title={props.title} centered={true} />
      </div>
      <div className={`hover-state background-${props.backgroundColor}`}>
        <div className={'hover-outer-wrapper'}>
          <Subheading title={props.title} />
          <Text title={props.text} />
          <SelectButton className={`${props.disabled ? 'disabled' : ''}`}>
            <ReactSVG src={isSelected ? MatchSelectedSVG : MatchUnselectedSVG} />
            <span>{isSelected ? 'Ausgewählt' : 'Auswählen'}</span>
          </SelectButton>
        </div>
      </div>
    </MatchValueWrapper >
  );
};

export default MatchValue;
