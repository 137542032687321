import { TEAM_FRAGMENT } from "./team";

export const BUTTON_FRAGMENT = `
  title
  className
  fontColor
  buttonColor
  type
  route
  icon
`;

export const TEXTBLOCK_FRAGMENT = `
  title
  subtitle
  text
  list
  backgroundColor
  button {
    ${BUTTON_FRAGMENT}
  }
  direction
  image {
    url
  }
`;

export const TEXTBLOCK_IMAGE_FRAGMENT = `
  title
  subtitle
  text
  button {
    ${BUTTON_FRAGMENT}
  }
  imageLeft {
    url
  }
  imageRight {
    url
  }
`;

export const FAQ_FRAGMENT = `
  question
  answer
`;

export const MATCH_VALUE_FRAGMENT = `
  title
  text
  image {
    url
  }
  backgroundColor
  matchmaker
`;

export const OFFICES_FRAGMENT = `
  partner {
    ${TEAM_FRAGMENT}
  }
  title
  text
  location
  street
  city
  link
  areaImage: image {
    url
  }
  mapsButton {
    ${BUTTON_FRAGMENT}
  }
`

