import { gql } from '@apollo/client';
import { ButtonProps } from 'src/components/button/Button';
import { FAQProps } from 'src/components/faq/FAQ';
import { QuoteProps } from 'src/modules/quote/Quote';
import { StageProps } from 'src/modules/stage/Stage';
import { TextBlockProps } from 'src/modules/textBlock/TextBlock';
import { ValueProps } from 'src/modules/values/Values';
import { BUTTON_FRAGMENT, FAQ_FRAGMENT, TEXTBLOCK_FRAGMENT } from './components';
import { MatchmakingDataProps, MATCHMAKING_FRAGMENT } from './matchmaking';
import { STAGE_FRAGMENT } from './stage';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type CareerDataProps = {
  career: {
    stage: StageProps,
    values: Array<ValueProps>,
    forkLeft: TextBlockProps,
    forkRight: TextBlockProps,
    contacts: Array<TeamMemberDataProps>,
    offersTitle: string,
    offersSubtitle: string,
    quote: QuoteProps,
    faqText: string,
    faqTitle: string,
    testimonial: TextBlockProps,
    slider: Array<TextBlockProps>,
    benefitsTitle?: string,
    matchmaking: MatchmakingDataProps,
    contactTitle: string,
    jobsAnker: ButtonProps
  },
  faqcareers: Array<FAQProps>
};

export const CAREER_QUERY = gql`
query {
  career {
    ${STAGE_FRAGMENT}
    offersTitle
    offersSubtitle
    faqText
    faqTitle
    contacts {
      ${TEAM_FRAGMENT}
    }
    contactTitle
    benefitsTitle
    values {
      title
      text
    }
    jobsAnker {
      ${BUTTON_FRAGMENT}
    }
    testimonial {
      ${TEXTBLOCK_FRAGMENT}
    }
    slider {
      ${TEXTBLOCK_FRAGMENT}
    }
    matchmaking {
      ${MATCHMAKING_FRAGMENT}
    }
  }
  faqcareers {
    ${FAQ_FRAGMENT}
  }
}
`;
