import * as React from 'react';
import { JobdetailsWrapper, ButtonWrapper, DetailsGridWrapper } from './Jobdetails.styles';
import List from '../../components/list/List';
import Button, { ButtonProps } from '../../components/button/Button';
import ShareSVG from '../../assets/icons/share.svg';
import { TeamMemberDataProps } from 'src/queries/team';
import Text from 'src/components/Text/Text';
import Share from '../share/Share';
import Subheading from 'src/components/subheading/Subheading';

export type JobdetailsProps = {
  taskList: Array<string>,
  profileList: Array<string>,
  taskTitle: string,
  profileTitle: string,
  applyButton: ButtonProps,
  shareButton: ButtonProps,
  contact?: TeamMemberDataProps,
  hasNavigation?: boolean,
  companyText?: string,
  companyTextOffer?: string,
  benefitsList?: Array<string>,
  applicationID: string,
  jobname: string,
  applyMailtoSubject?: string
};

const Jobdetails = (props: JobdetailsProps): JSX.Element => {

  const [showSharebar, setShowSharebar] = React.useState(false);

  /**
   * Toggles sharing options for social media.
   */
  const toggleSharebar = () => {
    setShowSharebar(!showSharebar);
  }

  /**
   * Checks if the job has a route to open (opens mail window for application, interal jobs) or redirects to application form(external jobs) instead.
   * @returns Boolean if it's an internal job with route
   */
  const hasApplicationRoute = () => {

    // Info: button type mailto uses route info containing recipient and subject, see button docs
    return props.applyButton && props.applyButton.route !== undefined && props.applyButton.route.length > 0;
  }

  return (
    <JobdetailsWrapper className={'grid'}>
      {props.companyText && <Text title={props.companyText} className={'company-text'} />}
      {props.companyTextOffer && <Subheading title={props.companyTextOffer} className={'company-text-title'} />}
      {props.benefitsList && <List items={props.benefitsList} />}
      <DetailsGridWrapper>
        {props.taskList && <List title={props.taskTitle} items={props.taskList} />}
        {props.profileList && <List title={props.profileTitle} items={props.profileList} />}
      </DetailsGridWrapper>
      {props.hasNavigation && (props.applyButton || props.shareButton) && <ButtonWrapper>
        {props.applyButton && <Button {...props.applyButton} route={hasApplicationRoute() ? props.applyButton.route : `/application/${props.applicationID}`} type={hasApplicationRoute() ? 'mailto' : 'route'} mailtoSubject={props.applyMailtoSubject} />}
        {props.shareButton && <Button {...props.shareButton} icon={ShareSVG} onClick={toggleSharebar} />}
      </ButtonWrapper>}
      {props.shareButton && <Share url={document.location.href} open={showSharebar} jobname={props.jobname} />}
    </JobdetailsWrapper>
  );
};

export default Jobdetails;
