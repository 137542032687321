import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const TeamMemberWrapper = styled.div`

.contact-title {
  margin-bottom: 48px;
}
`;

export const TeamMemberInnerWrapper = styled.div`

  margin-bottom: 136px;

  &.grid-wrapper {
    display: grid;

    @media (${breakpoints.xs}) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;

      .avatar {
        width: 155px;
      }
    }

    @media (${breakpoints.s}) {
      grid-gap: 24px;

      .avatar {
        width: 220px;
      }
    }
  }

  & p {
    margin: 0px;
  }

  .team-member-name {
    max-width: unset;
    margin: 0px;
    text-transform: none;
  }
`;

export const TeamDetailsWrapper = styled.div`

  &.grid-details {
    display: grid;
    margin-top: 32px;

    @media (${breakpoints.xs}) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;

      .avatar {
        width: 155px;
      }
    }

    @media (${breakpoints.s}) {
      grid-gap: 24px;

      .avatar {
        width: 220px;
      }
    }

    @media (${breakpoints.l}) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 60px;
    }
  }

  & .link-wrapper {
    display: flex;
    white-space: nowrap;
  }

  .office {
    margin-bottom: 24px;
  }

  .text {
    white-space: nowrap;
  }
`;

export const ImageSocialWrapper = styled.div`

`;

export const SocialNetworksWrapper = styled.div`
  display: flex;
  margin-top: -72px;
  margin-left: 127px;
  min-height: 72px;

  & .social-network-link {
    width: 72px;
    margin-right: 8px;
  }
`;

export const PositionWrapper = styled.div`
  height: 80px;
`;
