import * as React from 'react';
import { useQuery } from '@apollo/client';
import Stage from '../modules/stage/Stage';
import { NewsroomDataProps, NEWSROOM_QUERY } from 'src/queries/news';
import Shape from 'src/components/shape/Shape';
import Footer from 'src/modules/footer/Footer';
import News from 'src/modules/news/News';
import Subtitle from 'src/components/Subtitle/Subtitle';
import { resetPageMetadata } from 'src/helper';
import Teamlist from 'src/modules/teamlist/Teamlist';
import ContactNewsroom from 'src/modules/contact-newsroom/ContactNewsroom';

export type NewsroomPageProps = {

};

const NewsroomPage = (props: NewsroomPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<NewsroomDataProps>(NEWSROOM_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      <Stage {...data.newsroom.stage} />
      <Shape alignment={'left'} type={5} y={230} aboveAll={true} />
      <Shape alignment={'right'} type={3} y={230} aboveAll={true} />

      <div className={'stage-overlap'}>
        {data.presses && data.presses.length > 0 &&
          <News news={data.presses} />
        }
        {(!data.presses || data.presses.length === 0) &&
          <Subtitle title={data.newsroom.noNewsMessage || 'Es sind noch keine Einträge vorhanden.'} centered={true} />
        }
      </div>
      <Shape alignment={'right'} type={3} y={500} />
      <div className={'overlap-margin'}>
        {data.newsroom.contacts && data.newsroom.contacts.length === 0 &&
          <ContactNewsroom  />}
        {data.newsroom.contacts && data.newsroom.contacts.length >= 1 &&
          <Teamlist teams={data.newsroom.contacts} subtitle={data.newsroom.contactTitle} />}
      </div>
      <Footer />
    </div >
  );
};

export default NewsroomPage;
