import * as React from 'react';
import { TeamMemberWrapper, TeamDetailsWrapper, SocialNetworksWrapper, ImageSocialWrapper, TeamMemberInnerWrapper, PositionWrapper } from './TeamMember.styles';
import Title from '../../components/title/Title';
import Text from '../../components/Text/Text';
import Image from '../../components/image/Image';
import XingSVG from './../../assets/icons/xing-white.svg';
import LinkedinSVG from './../../assets/icons/linkedin-white.svg';
import Shape from 'src/components/shape/Shape';
import { TeamMemberDataProps } from 'src/queries/team';
import { getAbsoluteImageURLReduced } from '../../helper';
import Obfuscate from 'react-obfuscate';
import Link from '../../components/link/Link';
import { useQuery } from '@apollo/client';
import { ContactOfficeDataProps, CONTACT_OFFICE_QUERY } from 'src/queries/contact-office';

export const TeamMember = (props: TeamMemberDataProps): JSX.Element => {

  /**
   * Formats all names (last name, middle name, first name).
   */
  const getNameFields = () => {
    if (props.name) {
      const names = props.name.split(' ');

      // CASE: only first + last name - each in one row
      if (names.length === 2) {
        return <>
          <Title title={names[0]} className={`team-member-name`} />
          <Title title={names[1]} className={`team-member-name`} />
        </>;
      }
      // CASE: also middle name - put middle name into first row
      else if (names.length === 3) {
        return <>
          <Title title={`${names[0]} ${names[1]}`} className={`team-member-name`} />
          <Title title={names[2]} className={`team-member-name`} />
        </>;
      }
    } else {
      return '';
    }
  }

  const isFirefox = () => {
    return navigator.userAgent.search("Firefox") !== -1
  }

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<ContactOfficeDataProps>(CONTACT_OFFICE_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <TeamMemberWrapper className={props.className || ''} id={'contact'}>
      <Shape alignment={'left'} type={5} y={210} />
      <TeamMemberInnerWrapper className={`grid-wrapper team-member`}>
        <div className="span-col-6 span-col-12">
          <ImageSocialWrapper className={'image-social-wrapper'}>
            {props.avatar && <Image source={getAbsoluteImageURLReduced(props.avatar.url)} alt={'Profilbild Ansprechpartner ' + props.name} className={'avatar'} />}
            {!props.avatar && <Image source={getAbsoluteImageURLReduced(data.contactOffice.avatar.url)} alt={'Profilbild Ansprechpartner ' + props.name} className={'avatar'} />}
            <SocialNetworksWrapper>
              {
                props.xing && <a href={props.xing} className={'social-network-link'}>
                  <Image source={XingSVG} alt={'Xing'} />
                </a>
              }
              {
                props.xing && <a href={props.linkedin} className={'social-network-link'}>
                  <Image source={LinkedinSVG} alt={'LinkedIn'} />
                </a>
              }
            </SocialNetworksWrapper>
          </ImageSocialWrapper>
        </div>
        <div className="span-col-6 span-col-12">
          {props.name && getNameFields()}
          {!props.name && <Title title={data.contactOffice.name} className={`team-member-name`} />}
          {props.position && <PositionWrapper>
            {props.position && <Text title={props.position} />}
            {(props.areaFunction || props.areaIndustry) && <Text title={`
            ${props.areaFunction ? props.areaFunction : ''}
            ${(props.areaFunction && props.areaIndustry) ? ' - ' : ''}
            ${props.areaIndustry ? props.areaIndustry : ''}
          `} />}
            {props.leadership && <Text title={props.leadership} />}
          </PositionWrapper>}
          <TeamDetailsWrapper className={'grid-details team-member'}>
            <div className="span-col-6 span-col-12">
              {props.office && <Text title={props.office.title} className={'office'} />}

              <div className={'link-wrapper'}>
                <Text title={'Tel.:'} />
                {isFirefox() === false &&
                  <Obfuscate tel={props.phone || data.contactOffice.phone} className={'phone'}></Obfuscate>
                }
                {isFirefox() === true &&
                  <Link title={props.phone || data.contactOffice.phone} url={'tel:' + props.phone} className={'phone'} />
                }
              </div>
              <div className={'link-wrapper'}>
                <Text title={'E-Mail:'} />
                {isFirefox() === false &&
                  <Obfuscate email={props.email || data.contactOffice.email} className={'email'}></Obfuscate>
                }
                {isFirefox() === true &&
                  <Link title={props.email || data.contactOffice.email} url={'mailto:' + (props.email || data.contactOffice.email)} className={'email'} />
                }
              </div>
            </div>
          </TeamDetailsWrapper>
        </div>
      </TeamMemberInnerWrapper >
    </TeamMemberWrapper>
  );
};

export default TeamMember;
