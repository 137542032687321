import * as React from 'react';
import { useQuery } from '@apollo/client';
import Stage from '../modules/stage/Stage';
import Datapromise from '../modules/datapromise/Datapromise';
import Subtitle from 'src/components/Subtitle/Subtitle';
import FAQs from 'src/modules/faqs/FAQs';
import Title from 'src/components/title/Title';
import TextBlock, { TextBlockProps } from 'src/modules/textBlock/TextBlock';
import { ClientsDataProps, CLIENTS_QUERY } from 'src/queries/clients';
import Shape from 'src/components/shape/Shape';
import Footer from 'src/modules/footer/Footer';
import { resetPageMetadata } from 'src/helper';
import ContactOffice from 'src/modules/contact-office/ContactOffice';
import Teamlist from 'src/modules/teamlist/Teamlist';

export type ClientsPageProps = {

};

const ClientsPage = (props: ClientsPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<ClientsDataProps>(CLIENTS_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      {data.client.stage &&
        <Stage {...data.client.stage} />
      }
      <div className={'stage-overlap'}>
        <Subtitle title={data.client.subtitle} centered={true} />
        <Title title={data.client.title} centered={true} />
      </div>
      <Shape alignment={'left'} type={2} y={-100} />
      <Shape alignment={'right'} type={6} y={-100} />

      <div className={'margin'}>
        {
          data.client.textblocks && data.client.textblocks.map((block: TextBlockProps, index: number) => {
            return <TextBlock {...block} key={index} />;
          })
        }
      </div>
      <Datapromise className={'margin'} />
      <div className={'overlap-margin'}>
        <FAQs faqs={data.faqclients} text={data.client.faqText} title={data.client.faqTitle} />
        {data.client.contacts && data.client.contacts.length === 0 &&
          <ContactOffice title={data.client.contactTitle} />}
        {data.client.contacts && data.client.contacts.length >= 1 &&
          <Teamlist teams={data.client.contacts} subtitle={data.client.contactTitle} />}
      </div>
      <Footer />
    </div >
  );
};

export default ClientsPage;
