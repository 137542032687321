import { breakpoints } from 'src/styles/breakpoints';
import styled from 'styled-components';

export const DatapromiseWrapper = styled.div`
  @media(${breakpoints.l}) {
    margin: 0px;
    margin-bottom: 136px;
  }
`;

