import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const FooterWrapper = styled.footer`
  display: flex;
  background-color: ${colors.grey1};
  flex-direction: column;
  align-items: center;
  font-family: ${FontHeavy};
  font-size: 16px;

  .shape {
    z-index: -1;
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
`;

export const TextWrapper = styled.div`
  color: ${colors.grey3};
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const FooterHeader = styled.span`
  font-size: 18px;
  padding-bottom: 10px;
  letter-spacing: 1px;
`;

export const FooterText = styled.span`
  padding-bottom: 47px;
  line-height: 26px;
  max-width: 180px;
  margin: auto;

  @media (${breakpoints.xl}) {
    font-size: 18px;
  }
`;

export const CopyrightText = styled.span`
  color: ${colors.grey2};
  margin-top: 19px;
  letter-spacing: 1px;
`;

export const SocialMediaMobile = styled.div`

  margin-bottom: 50px;
  display: flex;
  justify-content: center;

  @media (${breakpoints.l}) {
    display: none;
  }

  & a:last-child img {
    margin-right: 0px;
  }
`;

export const SocialMediaDesktop = styled.div`

  display: none;
  margin-top: 16px;
  justify-content: center;

  @media (${breakpoints.l}) {
    display: flex;
  }

  & a:last-child img {
    margin-right: 0px;
  }
`;

export const SocialMediaIcon = styled.a`

  & img {
    margin-right: 25px;

    @media (${breakpoints.xs}) {
      width: 32px;
    }

    @media (${breakpoints.s}) {
      width: 66px;
      margin-right: 54px;
    }

    @media (${breakpoints.l}) {
      width: 32px;
      margin-right: 16px;
    }
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (${breakpoints.l}) {
    flex-direction: row;
    justify-content: center;
  }

  & .link {

    font-family: ${FontHeavy};
    color: ${colors.primary};
    letter-spacing: 1px;
    line-height: 26px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 13px 0px;

    @media (${breakpoints.l}) {
      padding: 0px 13px;
    }
}
`;

export const CookieChangeText = styled.span`
  color: ${colors.grey2};
  margin-top: 19px;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
`;

