import * as React from 'react';
import { colors } from 'src/styles/colors';
import {
  LinkEl
} from './Link.styles';

export type TextProps = {
  title?: string,
  url: string,
  className?: string,
  fontColor?: string,
  mailtoSubject?: string
};

const Link = (props: TextProps): JSX.Element => {

  if (!props.title) return <></>;

  return (
    <LinkEl href={props.url + (props.mailtoSubject ? `?subject=${props.mailtoSubject}` : '')} className={`link ${props.className || ''}`} target={'_blank'} style={{ color: props.fontColor || colors.grey1 }}>{props.title}</LinkEl>
  );
};

export default Link;
