import * as React from 'react';
import { SearchboxEl, SearchboxWrapper, Circle, SearchWrapper } from './Searchbox.styles';
import { ReactSVG } from 'react-svg';
import SearchSVG from './../../assets/icons/search.svg';
import Autocomplete from '../autocomplete/Autocomplete';
import { jobSearch, jobSuggestionSearch } from 'src/helper/search';
import { useQuery } from '@apollo/client';
import { JobProps, JOBS_QUERY } from 'src/queries/jobs';
import { JoblistProps } from 'src/modules/joblist/Joblist';
import { SelectOptionProps } from '../select/Select';
import { useHistory } from 'react-router-dom';

export type SearchboxProps = {
  placeholder?: string,
  name?: string,
  className?: string,
  theme?: string,
  onSearchChange?(e: React.ChangeEvent<HTMLInputElement>): void,
  onSearchClick?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void,
  defaultSearch?: string,
  suggestions?: boolean
};

const Searchbox = (props: SearchboxProps): JSX.Element => {

  const { loading, data, error } = useQuery<JoblistProps>(JOBS_QUERY);
  const [suggestions, setSuggestions] = React.useState<Array<SelectOptionProps>>([]);
  const [searchterm, setSearchterm] = React.useState(props.defaultSearch || '');

  const history = useHistory();

  /**
   * Load top 3 suggestions
   */
  const performSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const results = jobSuggestionSearch(data?.jobs || [], e.currentTarget.value);

    // Top 3
    const _suggestions = new Array<SelectOptionProps>();
    results.map((item: JobProps) => {
      _suggestions.push({
        title: `${item.Bezeichnung} (${item.Bundesland})`,
        value: '/job/' + item.Referenznummer.toString()
      });
    });

    _suggestions.length = 3;

    const showAll = {
      title: `Alle ${results.length} Jobs anzeigen...`,
      value: `/candidates/${e.currentTarget.value || ''}`
    };
    if (results.length > 3) {
      _suggestions.push(showAll);
    }

    setSuggestions(_suggestions);
  };

  const navigateToJob = (e: React.MouseEvent<HTMLOptionElement, MouseEvent>): void => {
    history.push(e.currentTarget.value);
  };

  const navigateToJobsearch = () => {
    history.push('/candidates/' + searchterm);
  };

  const clickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {

    if (props.onSearchClick) {
      props.onSearchClick(e);
      return;
    }
    navigateToJobsearch();

  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {

    setSearchterm(e.currentTarget.value);

    if (props.suggestions) {
      performSearch(e);
    }

    if (props.onSearchChange) {
      props.onSearchChange(e);
    }
  };

  /**
   * Start search on enter key.
   * @param e
   */
  const searchEnterHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      navigateToJobsearch();
    }
  }

  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;


  return (
    <SearchboxWrapper className={`${props.className || ''} ${props.theme || ''} searchbox`} >
      <SearchWrapper>
        <label htmlFor={props.name || 'jobsearch'}>{props.name || 'jobsearch'}</label>
        <SearchboxEl className={`${props.theme || ''}`} placeholder={props.placeholder} name={props.name || 'jobsearch'} id={props.name || 'jobsearch'} onChange={changeHandler} value={searchterm} autoComplete={'off'} onKeyDown={searchEnterHandler} />
        <Circle className={'circle'}>
          <ReactSVG src={SearchSVG} />
        </Circle>
        <Circle className={'circle-click'} onClick={clickHandler} />
      </SearchWrapper>
      {
        props.suggestions && <Autocomplete options={suggestions} show={true} onSelectClick={navigateToJob} />
      }
    </SearchboxWrapper>
  );
};

export default Searchbox;
