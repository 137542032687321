import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const ForkWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    .subtitle, .title, .text {
      color: white;
    }

    @media (${breakpoints.l}) {
      grid-template-columns: repeat(2, 1fr);
    }

    & .textblock {
      margin: 0px !important;
      grid-template-columns: repeat(6,1fr) !important;
    }
`;
