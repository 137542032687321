/**
 * Removed all markup contents to have a clean preview text.
 * @param text original markup text
 * @param trimLength if true, trim resulting to 200 characters max
 */
export function removeMarkup(text: string | undefined, trimLength: boolean = false) {
  // Remove formatting: **, #, <ul>, images

  if (text) {

    text = text.replace(/<u(.*?)>|<\/u>/g, '');
    text = text.replace(/([*])/g, '');
    text = text.replace(/([#])/g, '');
    text = text.replace(/(?:\r\n|\r|\n)/g, '');
  } else {
    text = '';
  }

  // Trim length for previews
  if (trimLength) {
    if (text.length > 200) {
      return text.substring(0, 200) + "...";
    } else {
      return text;
    }
  }

  return text;
}
