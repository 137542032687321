import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import ListItemImage from '../../assets/icons/list-item.svg';
import { FontNormal } from 'src/styles/fonts';

export const ListWrapper = styled.div`
  color: ${colors.grey1};
  margin-bottom: 40px;

  .list-text {
    margin-bottom: 16px;
  }
`;

export const ListEl = styled.div`
  display: flex;
  font-family: ${FontNormal};
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  list-style-image: url(${ListItemImage});
  list-style-position: inside;
  display: block;
  padding: 0;
  margin: 0;

  @media (${breakpoints.s}) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (${breakpoints.l}) {
    font-size: 24px;
    line-height: 34px;
  }

  @media (${breakpoints.xl}) {

  }
`;

export const ListItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;

  & span {
    margin-left: 47px;
  }
`;
