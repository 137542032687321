import * as React from 'react';
import Footer from 'src/modules/footer/Footer';
import FormConnexys from 'src/modules/form-connexys/FormConnexys';

export type ConnexysFormPageProps = {
  formType: string
};

const ConnexysFormPage = (props: ConnexysFormPageProps): JSX.Element => {

  return (
    <div>
      <FormConnexys formType={props.formType} />
      <Footer />
    </div>
  );
};

export default ConnexysFormPage;
