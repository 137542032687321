import * as React from 'react';
import { JobWrapper, TagsWrapper, TitleWrapper } from './Job.styles';
import Button from '../../components/button/Button';
import { JobProps } from '../../queries/jobs';
import Subheading from '../../components/subheading/Subheading';
import { useHistory } from 'react-router-dom';

export type JobTagProps = {
  title: string,
};

export interface JobElementProps extends JobProps {
  jobdetailsUrl?: string;
};

const Job = (props: JobElementProps): JSX.Element => {

  const history = useHistory();

  const JobTag = (tagProps: JobTagProps) => {
    return (
      <div className={'jobTag'}>
        <div className={'bullet'} />
        <span className={'title'}>{tagProps.title}</span>
      </div>
    );
  };

  const navigateToJob = () => {
    if (props.jobdetailsUrl) {
      history.push(props.jobdetailsUrl);
    }
  };

  return (
    <JobWrapper className={'job'} onClick={navigateToJob}>
      <div className={'job-info'}>
        <TitleWrapper>
          <Subheading title={props.Bezeichnung} />
        </TitleWrapper>
        <TagsWrapper>
          {props.Bundesland && <JobTag title={props.Bundesland} />}
          {props.Wochenstunden && <JobTag title={props.Wochenstunden.toString()} />}
          {props.Taetigkeitsfeld && <JobTag title={props.Taetigkeitsfeld} />}
        </TagsWrapper>
      </div>
      <Button title={''} buttonColor={'tertiary'} fontColor={'light'} />
    </JobWrapper>
  );
};

export default Job;
