import * as React from 'react';
import { ShapeWrapper } from './Shape.styles';

import Shape1SVG from './../../assets/shapes/shape-1.svg';
import Shape2SVG from './../../assets/shapes/shape-2.svg';
import Shape3SVG from './../../assets/shapes/shape-3.svg';
import Shape4SVG from './../../assets/shapes/shape-4.svg';
import Shape5SVG from './../../assets/shapes/shape-5.svg';
import Shape6SVG from './../../assets/shapes/shape-6.svg';
import Shape7SVG from './../../assets/shapes/shape-7.svg';
import Shape8SVG from './../../assets/shapes/shape-8.svg';
import Shape9SVG from './../../assets/shapes/shape-9.svg';
import Shape10SVG from './../../assets/shapes/shape-10.svg';
import Shape11SVG from './../../assets/shapes/shape-11.svg';
import Shape12SVG from './../../assets/shapes/shape-3.svg';

export type ShapeProps = {
  type: number,
  alignment: string,
  y?: number,
  offset?: number,
  aboveAll?: boolean
};

const Shape = (props: ShapeProps): JSX.Element => {

  const images = [
    Shape1SVG, Shape2SVG, Shape3SVG, Shape4SVG, Shape5SVG, Shape6SVG, Shape7SVG, Shape8SVG, Shape9SVG, Shape10SVG, Shape11SVG, Shape12SVG
  ];

  return (
    <ShapeWrapper className={`shape shape-${props.type} ${props.alignment} ${props.aboveAll ? 'above' : ''}`} style={{ marginTop: props.y }}>
      <img src={images[props.type - 1]} className={`shape-${props.type || ''}`} alt={'Kreisform'} />
    </ShapeWrapper >
  );
};

export default Shape;
