import * as React from 'react';
import { TextBlockImageWrapper } from './TextBlockImage.styles';
import Subtitle from '../../components/Subtitle/Subtitle';
import Title from '../../components/title/Title';
import Button, { ButtonProps } from '../../components/button/Button';
import Text from '../../components/Text/Text';
import Image from '../../components/image/Image';
import Shape from 'src/components/shape/Shape';
import { getAbsoluteImageURLReduced } from 'src/helper';

export type TextBlockImageProps = {
  title: string,
  subtitle?: string,
  text: string,
  imageLeft?: {
    url: string
  },
  imageRight?: {
    url: string
  },
  button?: ButtonProps,
  className?: string
};

const TextBlockImage = (props: TextBlockImageProps): JSX.Element => {

  return (
    <TextBlockImageWrapper className={`grid textblock ${props.className || ''}`}>
      <Shape alignment={'left'} type={5} y={200} />

      {props.imageLeft &&
        <Image source={getAbsoluteImageURLReduced(props.imageLeft.url, 'medium_')} alt={'Linkes Bild'} className={'image-left'} />
      }
      {props.imageRight &&
        <Image source={getAbsoluteImageURLReduced(props.imageRight.url, 'medium_')} alt={'Rechtes Bild'} className={'image-right'} />
      }
      <div className="span-col-6 span-col-12">
        {props.subtitle &&
          <Subtitle title={props.subtitle} />
        }
        <Title title={props.title} />
        <Text title={props.text} />
        {props.button &&
          <Button {...props.button} />
        }
      </div>
    </TextBlockImageWrapper >
  );
};

export default TextBlockImage;
