import * as React from 'react';
import {
  TitleEl
} from './Title.styles';

export type TitleProps = {
  title?: string,
  className?: string,
  centered?: boolean
};

const Title = (props: TitleProps): JSX.Element => {

  if (!props.title) return <></>;

  return (
    <TitleEl className={`${props.className || ''} title ${props.centered ? 'centered' : ''}`} dangerouslySetInnerHTML={{ __html: props.title }} />
  );
};

export default Title;
