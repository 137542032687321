import { gql } from '@apollo/client';
import { FAQProps } from 'src/components/faq/FAQ';
import { StageProps } from 'src/modules/stage/Stage';
import { TextBlockProps } from 'src/modules/textBlock/TextBlock';
import { FAQ_FRAGMENT, TEXTBLOCK_FRAGMENT } from './components';
import { STAGE_FRAGMENT } from './stage';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type ClientsDataProps = {
  client: {
    stage: StageProps,
    contacts: Array<TeamMemberDataProps>,
    contactTitle: string,
    title: string,
    subtitle: string,
    textblocks: Array<TextBlockProps>,
    faqText: string,
    faqTitle: string,
  },
  faqclients: Array<FAQProps>
};

export const CLIENTS_QUERY = gql`
query {
  client {
    ${STAGE_FRAGMENT}
    title
    subtitle
    textblocks {
      ${TEXTBLOCK_FRAGMENT}
    }
    contactTitle
    contacts {
      ${TEAM_FRAGMENT}
    }
    faqText
    faqTitle
  }
  faqclients {
    ${FAQ_FRAGMENT}
  }
}
`;
