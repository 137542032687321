import * as React from 'react';
import { useQuery } from '@apollo/client';
import Stage from '../modules/stage/Stage';
import Shape from 'src/components/shape/Shape';
import Footer from 'src/modules/footer/Footer';
import { NewsElementProps } from 'src/components/newsElement/NewsElement';
import { useParams } from 'react-router-dom';
import { NewsElementDataProps, NEWS_ELEMENT_QUERY } from 'src/queries/news';
import Share from 'src/modules/share/Share';
import ShareSVG from 'src/assets/icons/share.svg';
import Button from 'src/components/button/Button';
import News from 'src/modules/news/News';
import ReactMarkdown from 'react-markdown';
import { formatDate, getAbsoluteImageURLReduced, resetPageMetadata } from 'src/helper';
import { removeMarkup } from 'src/helper/news';
import ContactOffice from 'src/modules/contact-office/ContactOffice';
import Teamlist from 'src/modules/teamlist/Teamlist';

export type NewsPageProps = {
};

type NewsPageParams = {
  id?: string
};

const NewsPage = (props: NewsPageProps): JSX.Element => {

  const { id } = useParams<NewsPageParams>();
  const { loading, data, error } = useQuery<NewsElementDataProps>(NEWS_ELEMENT_QUERY, { variables: { newsId: id } });

  const [showSharebar, setShowSharebar] = React.useState(false);

  React.useEffect(() => {
    if (data && data.press) {
      resetPageMetadata(data?.press.title, removeMarkup(data?.press.content, true), getAbsoluteImageURLReduced(data?.press.coverImage.url));
    } else {
      resetPageMetadata();
    }
  });

  const toggleSharebar = () => {
    setShowSharebar(!showSharebar);
  }

  const formatMarkup = (markupText: string): string => {

    if (markupText) {
      markupText = markupText.replaceAll('<u>', '__');
      markupText = markupText.replaceAll('</u>', '__');
    }

    return markupText;
  }

  /* Graph QL data query*/
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      <Stage title={data.press.title} subtitle={formatDate(data.press.date)} backgroundImages={[{ url: data.press.coverImage.url }]} />
      <Shape alignment={'right'} type={12} y={60} aboveAll={true} />
      <div className={'stage-overlap'}>
        <ReactMarkdown children={formatMarkup(data.press.content)} className={'markup-content'} />
        <div className={'center margin-bottom'}>
          <Button title={'News teilen'} buttonColor={'tertiary'} icon={ShareSVG} onClick={toggleSharebar} />
        </div>
        {showSharebar && <Share url={document.location.href} open={showSharebar} jobname={data.press.title} />}
        {data.presses && data.presses.length > 1 &&
          <News news={data.presses} excludeId={data.press.id} limit={2} title={'Weitere News'} />
        }
      </div>
      <div className={'overlap-margin'}>
        {data.press.contacts && data.press.contacts.length === 0 &&
          <ContactOffice />}
        {data.press.contacts && data.press.contacts.length >= 1 &&
          <Teamlist teams={data.press.contacts} />}
      </div>
      <Footer />
    </div >
  );
};

export default NewsPage;
