import * as React from 'react';
import {
  ToggleEl,
  Circle,
  ToggleWrapper,
  ToggleText
} from './Toggle.styles';

export type ToggleProps = {
  textLeft: string,
  textRight: string,
  className?: string,
  buttonColor?: string,
  onClick?: (status: boolean) => void,
  toggleStatus?: boolean
};

const Toggle = (props: ToggleProps): JSX.Element => {

  const [toggleState, setToggleState] = React.useState(props.toggleStatus);

  const onClick = () => {

    // Toggle
    let currentState = !toggleState;
    setToggleState(currentState);

    if (props.onClick) {
      props.onClick(currentState);
    }
  };

  React.useEffect(() => {
    // Update toggle status from parent
    setToggleState(props.toggleStatus);
  }, [props.toggleStatus]);

  return (
    <ToggleWrapper className={`toggle`}>
      <ToggleText>{props.textLeft}</ToggleText>
      <ToggleEl className={`${props.className || ''} ${toggleState ? 'on' : 'off'}`} onClick={onClick}>
        <Circle className={`circle background-${props.buttonColor || 'primary'}`}>
        </Circle>
      </ToggleEl>
      <ToggleText>{props.textRight}</ToggleText>
    </ToggleWrapper>
  );
};

export default Toggle;
