import * as React from 'react';
import { useQuery } from '@apollo/client';
import Stage from '../modules/stage/Stage';
import Datapromise from '../modules/datapromise/Datapromise';
import Subtitle from 'src/components/Subtitle/Subtitle';
import Process from 'src/modules/process/Process';
import FAQs from 'src/modules/faqs/FAQs';
import { CandidatesDataProps, CANDIDATES_QUERY } from 'src/queries/candidates';
import Joblist from 'src/modules/joblist/Joblist';
import Shape from 'src/components/shape/Shape';
import Title from 'src/components/title/Title';
import Footer from 'src/modules/footer/Footer';
import { resetPageMetadata } from 'src/helper';
import Teamlist from 'src/modules/teamlist/Teamlist';
import ContactOffice from 'src/modules/contact-office/ContactOffice';

type CandidatesPageProps = {

};

const CandidatesPage = (props: CandidatesPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<CandidatesDataProps>(CANDIDATES_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      {data.candidate.stage &&
        <Stage {...data.candidate.stage} />
      }
      <Shape alignment={'left'} type={2} y={40} aboveAll={true} />
      <Shape alignment={'right'} type={6} y={40} aboveAll={true} />

      <div className={'stage-overlap'}>
        <Title title={data.candidate.joblistTitle} centered={true} />
        <Joblist searchEnabled={true} />
      </div>
      <div className={'overlap-margin'}>
        <Subtitle title={data.candidate.contactUs} centered={true} />
        <Shape alignment={'left'} type={5} y={-20} />
        <Shape alignment={'right'} type={3} y={-90} />

      </div>
      <Process steps={data.candidate.process} sectionTitle={data.candidate.processTitle} />
      <Datapromise className={'margin'} />
      <div className={'overlap-margin'}>
        <FAQs faqs={data.faqcandidates} text={data.candidate.faqText} title={data.candidate.faqTitle} />
        {data.candidate.contacts && data.candidate.contacts.length === 0 &&
          <ContactOffice title={data.candidate.contactTitle} />}
        {data.candidate.contacts && data.candidate.contacts.length >= 1 &&
          <Teamlist teams={data.candidate.contacts} subtitle={data.candidate.contactTitle} />}
      </div>
      <Footer />
    </div >
  );
};

export default CandidatesPage;
