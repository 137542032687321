import { SelectOptionProps } from 'src/components/select/Select';
import { JobProps } from 'src/queries/jobs';
import { result } from 'underscore';

export function jobSuggestionSearch(data: Array<JobProps>, searchterm: string) {

  if (data === undefined) {
    return [];
  }

  // Search is all lower case
  searchterm = searchterm.toLowerCase();

  // Handle each search term as single search
  let searchTerms = searchterm.split(' ');
  let searchTermResults = new Array<Array<JobProps>>(searchTerms.length);

  // Each search term
  searchTerms.forEach((term, index) => {
    searchTermResults[index] = data.filter((item: JobProps) => {
      return item.Bezeichnung.toLowerCase().indexOf(term) !== -1 ||
        item.Referenznummer.indexOf(term) !== -1 ||
        item.Bundesland?.toLowerCase().indexOf(term) !== -1 ||
        (item.Taetigkeitsfeld?.toLowerCase().indexOf(term) !== -1 &&
        item.Taetigkeitsfeld !== null)
    });
  });


  let allSearchResults = new Array<string>();

  // Combine results of all searchterms and condense down to ref number
  if (searchTerms.length > 1) {
    for (let i = 0; i < searchTerms.length; i++) {
      for (let j = 0; j < searchTermResults[i].length; j++) {
        allSearchResults.push(searchTermResults[i][j].Referenznummer);
      }
    }

    // Find duplicated ref numbers
    const set = new Set(allSearchResults)
    const duplicates = allSearchResults.filter((item, index) => index !== allSearchResults.indexOf(item));

    // Return full result list
    return data.filter(item => duplicates.indexOf(item.Referenznummer) !== -1);
  } else {
    return searchTermResults[0];
  }
}

/**
 * Executes search logic on all jobs
 * @param searchterm
 */
export function jobSearch(data: Array<JobProps>, searchterm: string, filterRegion?: string, filterArea?: string): Array<JobProps> {

  if (data === undefined) {
    return [];
  }

  // Search is all lower case
  searchterm = searchterm.toLowerCase();

  let results = [];

  // Filter by name and ref number
  results = jobSuggestionSearch(data, searchterm);

  // Filter by region
  if (filterRegion) {
    results = results.filter((item: JobProps) => {
      // Default: ignore filter
      if (filterRegion === 'default') {
        return true;
      } else {
        return item.Bundesland?.toLowerCase().indexOf(filterRegion) !== -1 && item.Bundesland !== null;
      }
    });
  }

  // Filter by area
  if (filterArea) {
    results = results.filter((item: JobProps) => {
      // Default: ignore filter
      if (filterArea === 'default') {
        return true;
      } else {
        return item.Taetigkeitsfeld?.toLowerCase().indexOf(filterArea) !== -1 && item.Taetigkeitsfeld !== null;
      }
    });
  }

  return results;
}

/**
 *
 * @param jobs
 */
export function addFilters(data: Array<string | undefined>): Array<SelectOptionProps> {
  let filterOptionsArray = new Array<SelectOptionProps>(0);

  if (data) {
    data.sort();
    data.map((item, index) => {
      // Add filters
      filterOptionsArray[index] = {
        title: item || '',
        value: (item || '').toLowerCase()
      };
    });
  }

  // Remove "Life Science" or empty fields -- area not released yet
  filterOptionsArray = filterOptionsArray.filter((item) => { return item.value !== 'life science' && item.value !== ''; });

  return filterOptionsArray;
}
