import { breakpoints } from 'src/styles/breakpoints';
import styled from 'styled-components';

export const FAQsWrapper = styled.div`

  @media(${breakpoints.l}) {
    max-width: 66.66666667%;
    min-width: unset;
    margin: auto;
  }

  padding-bottom: 136px;

  .text {
    margin-bottom: 50px;
  }
`;

