import { useQuery } from '@apollo/client';
import * as React from 'react';
import { resetPageMetadata } from 'src/helper';
import Footer from 'src/modules/footer/Footer';
import Stage from 'src/modules/stage/Stage';
import { ImpressumDataProps, IMPRESSUM_QUERY } from 'src/queries/impressum';

export type ImpressumPageProps = {

};

const ImpressumPage = (props: ImpressumPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<ImpressumDataProps>(IMPRESSUM_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      <Stage {...data.impressum.stage} />
      <div className={'stage-overlap legal-page'}>
        <div dangerouslySetInnerHTML={{ __html: data.impressum.content }}></div>
      </div>
      <Footer />
    </div>
  );
};

export default ImpressumPage;
