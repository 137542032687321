/**
 * Removes all HTML element tags and unsused characters.
 * @param text original text
 */
export function formatString(text: string): string {

  if (!text) {
    return '';
  }

  text = text.replaceAll('</li>', '');
  text = text.replaceAll(/<span(.*?)>|<\/span>/g, '');
  text = text.replaceAll(/<p(.*?)>|<\/p>/g, '');
  text = text.replaceAll(/<ul(.*?)>|<\/ul>/g, '');
  text = text.replaceAll('<br>', '');
  text = text.replaceAll(';', '');
  text = text.replaceAll('"', '');

  return text;
};

/**
 * Creates a list from a <li> separated rich text.
 * @param text
 */
export function makeListFromString(text: string, delimiter: string = '<li>'): Array<string> {

  text = formatString(text);
  const formattedList = new Array<string>();
  text.split(delimiter).map(item => {
    // Sort out everything that is empty or has s: structure
    if (item.startsWith('s:') || item.length === 0) {
      return;
    } else {
      formattedList.push(item);
    }
    return;
  });

  return formattedList;
};
