import { breakpoints } from 'src/styles/breakpoints';
import { FontHeavy, FontNormal } from 'src/styles/fonts';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const ForwardingPrivacyText = styled.div`

`;

export const FormConnexysWrapper = styled.div`
  .text {
    margin-top: 60px;
    tex-align: center;
  }
`;

export const FormApplicationWrapper = styled.div`

  font-family: ${FontNormal};
  letter-spacing: 1px;
  line-height: 26px;
  font-size: 16px;
  color: ${colors.grey1};
  max-width: 900px;
  overflow: hidden;
  min-height: 400px;
  margin: auto;

  & #cxsFormHolder {

    &.form-PrivacyStatement {
      margin: 100px auto;

      @media(${breakpoints.s}) {
        margin: 140px auto;
      }

      .cxsPageHeader {
        display: none;
      }
    }

    &.form-Forwarding {
      margin: 40px auto;

      .cxsPageHeader, #cxsSection_0 {
        display: none;
      }
    }
  }

  & .cxsFieldContainer {
    display: flex;
  }

  & .cxsSection {
    max-width: 700px;
    margin: auto;
    text-align: center;
  }

  & .cxsErrorHeader, .cxsFieldError {
    color: ${colors.primary};
    text-align: left;
  }

  & .cxsErrorHeader {
    text-align: center;
  }

  & #cxsLanguages, & .cxsFieldName_ent_last_update_type__c, & .cxsFieldName_ent_last_update_date__c {
    display: none;
  }

  & h3, & h2, & h1 {
    font-family: ${FontHeavy};
    text-transform: uppercase;
    line-height: 50px;
    text-align: center;
  }

  & h1 {
    margin-bottom: 48px;
    font-size: 32px;
    line-height: 42px;

    @media(${breakpoints.s}) {
      font-size: 40px;
    }
  }

  & .cxsSectionIntro {
    margin-bottom: 16px;
  }

  & .cxsApplyButton {
    margin-top: 24px;
    background-color: ${colors.primary};
    color: white;
    border: none;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 0px;
  }

  & input, & select {
    border: ${colors.grey1} solid 2px;
    padding: 15px;
    background-color: transparent;
    outline: none;
    font-family: ${FontHeavy};
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 32px;
    width: 100%;

    &::placeholder {
      color: ${colors.placeholder};
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: ${colors.placeholder};
    }

    &::-ms-input-placeholder {
      color: ${colors.placeholder};
    }
  }

  & label {
    opacity: 0;
    width: 0px;
    height: 0px;
    display: block;
  }

  & .cxscheckboxField {
    width: unset;
  }

  & .cxsFieldType_checkbox {

    margin-top: 24px;
    margin-bottom: 48px;

    .cxsFieldAfterLabel {
      opacity: 1;
      color: ${colors.grey1};
      width: 100%;
      margin-left: 8px;
      font-family: ${FontHeavy};
    }
  }

  & .cxsFileUploadFrame {
    background-color: ${colors.grey4};
    border: ${colors.placeholder} solid 2px;
    height: 60px;
    width: 100%;
  }

  & .cxsFieldType_file {

    flex-flow: column;
    margin-bottom: 32px;

    & .cxsFieldLabel {
      opacity: 1;
      width: 100%;
      height: 24px;
      display: flex;
      margin-bottom: 8px;
    }
  }

  & .cxsFileName {
    font-family: ${FontHeavy};
    text-align: left;
  }

  & .cxsFileRemove {
    font-family: ${FontNormal};
    color: ${colors.primary};
    cursor: pointer;
    text-align: left;
    display: none;

    &::after {
      content: " Dokument entfernen";
    }
  }

  & .cxsFileButtonLabel {
    display: none;
  }

  #cxsThanks, .cxsPageError {
    text-align: center;
  }

  .cxsFieldName_ent_ip_address__c, .cxsFieldName_ent_mobile_vs_desktop__c {
    display: none;
  }
`;
