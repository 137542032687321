import { colors } from 'src/styles/colors';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const SliderWrapper = styled.div`

  background-color: ${colors.grey1};
  margin-bottom: 48px;
  position: relative;
  display: block;
  height: 400px;

  h1, p, h2 {
    color: white !important;
  }
`;

export const SliderItem = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.5s;

  &.slide-hidden {
    opacity: 0;
    z-index: -1;
  }

  &.slide-visible {
    opacity: 1;
  }

  .grid {
    margin: 0px;
    padding: 40px;
    padding-right: 48px;
    grid-template-columns: repeat(6,1fr);

    .image {
      max-width: 440px;
      width: 100%;
      margin: auto;
    }

    @media(${breakpoints.s}) {
      padding: 120px;
      padding-right: 180px;
      grid-template-columns: repeat(12,1fr);
    }
  }

  .navigation-elements {
    @media(${breakpoints.s}) {
      display: none;
    }
  }

`;

export const SliderButton = styled.div`
  border-radius: 100%;
  background-color: ${colors.primary};
  min-width: 80px;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translate(0%,-50%);
  display: none;

  @media(${breakpoints.s}) {
    display: flex;
  }

  svg {
    transition: 0.4s width, 0.4s height;
    width: 16px;
    height: 16px;
  }

  &:hover {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.left {
    left: 24px;

    @media(${breakpoints.s}) {
      left: 72px;
    }
  }

  &.right {
    right: 24px;

    @media(${breakpoints.s}) {
      right: 72px;
    }
  }
`;
