import * as React from 'react';
import Button from 'src/components/button/Button';
import NewsElement, { NewsElementProps } from 'src/components/newsElement/NewsElement';
import { NewsWrapper } from './News.styles';
import MoreSVG from '../../assets/icons/more.svg';
import Subtitle from 'src/components/Subtitle/Subtitle';

export type NewsProps = {
  news: Array<NewsElementProps> | null,
  className?: string,
  limit?: number,
  excludeId?: string,
  maxWidth?: number,
  title?: string
};

const News = (props: NewsProps): JSX.Element => {

  const TILES = 4;
  const [tilesShown, setTilesShown] = React.useState<number>(props.limit || TILES);
  const [tilesLimit] = React.useState<number | undefined>(props.limit || props.news?.length);

  const [sortedNews, setSortedNews] = React.useState<Array<NewsElementProps>>();

  /**
   * Load more news.
   */
  const loadMore = () => {
    const currentScrollY = window.scrollY;
    const wrapperEl = document.querySelector('.newslist');

    if (wrapperEl) {
      wrapperEl.classList.add('hidden');
      setTilesShown(tilesShown + (props.limit || TILES));
      setTimeout(() => {
        window.scrollTo({ top: currentScrollY });
        wrapperEl.classList.remove('hidden');
        return;
      }, 300);
    }
  };

  React.useEffect(() => {

    if (sortedNews === undefined) {

      let _news = props.news || [];

      // Exclude self (e.g. for related news)
      if (props.excludeId && props.news) {
        _news = props.news.filter((a) => { return a.id !== props.excludeId });
      }

      setSortedNews(_news);
    }

  }, [sortedNews]);

  return (
    <NewsWrapper className={`news ${props.className || ''}`} style={{ maxWidth: props.maxWidth || 'unset' }}>
      {props.title && <Subtitle title={props.title} />}
      <div className={'grid newslist'}>
        {
          sortedNews && sortedNews.map((newsElement: NewsElementProps, index: number) => {
            if (index < tilesShown) {
              return <NewsElement key={index} {...newsElement} coverImage={newsElement.coverImage} index={index} />;
            }
          })
        }
      </div>
      {
        sortedNews && sortedNews.length > tilesShown && (tilesLimit && tilesShown < tilesLimit) &&
        <Button title={'Mehr laden'} onClick={loadMore} className={'load-more'} icon={MoreSVG} buttonColor={'tertiary'} />
      }
    </NewsWrapper >
  );
};

export default News;

