import { gql } from '@apollo/client';
import { StageProps } from 'src/modules/stage/Stage';
import { STAGE_FRAGMENT } from './stage';

export type ConnexysFormDataProps = {
  connexysForm: {
    stage: StageProps,
    titlePrivacyStatement: string,
    titleForwarding: string,
    textPrivacyStatement: string,
    textForwarding: string,
    forwardingPrivacyText: string,
    titleApplication: string
  }
};

export const CONNEXYSFORM_QUERY = gql`
query {
  connexysForm {
    ${STAGE_FRAGMENT}
    titlePrivacyStatement
    titleForwarding
    textPrivacyStatement
    textForwarding
    forwardingPrivacyText
    titleApplication
  }
}
`;
