import * as React from 'react';
import { useQuery } from '@apollo/client';
import { JoblistWrapper } from './JoblistCareer.styles';
import Job from '../job/Job';
import Button from '../../components/button/Button';
import MoreSVG from '../../assets/icons/more.svg';
import { JobKaarismaProps, JOBS_KAARISMA_QUERY } from 'src/queries/jobs_kaarisma';
import Toggle from 'src/components/toggle/Toggle';

export type JoblistCareerProps = {
  jobKaarismas?: Array<JobKaarismaProps>,
  experienceToggleStatus?: boolean,
  onToggleClick?: (status: boolean) => void,
};

const JoblistCareer = (props: JoblistCareerProps): JSX.Element => {

  const TILES = 8;
  const [tilesShown, setTilesShown] = React.useState<number>(TILES);
  const [toggleExperienceStatus, setToggleExperienceStatus] = React.useState<string>('Berlin');

  /* GraphQL*/
  const { loading, data, error } = useQuery<JoblistCareerProps>(JOBS_KAARISMA_QUERY);

  /** Toggle the filter between entry and professional level. */
  const toggleFilter = (status: boolean) => {
    setToggleExperienceStatus((status ? 'Berlin' : 'Mannheim') || 'Berlin');

    if (props.onToggleClick) {
      props.onToggleClick(status);
    }
  }

  React.useEffect(() => {

    // Update toggle state of job experience filter
    toggleFilter(props.experienceToggleStatus || false);

  }, [props.experienceToggleStatus]);

  if (loading) return <p>Lade...</p>;
  if (error) return <p>{error.message}</p>;
  if (!data) return <p></p>;

  /**
   * Load more search results.
   */
  const loadMore = () => {
    const currentScrollY = window.scrollY;
    const wrapperEl = document.querySelector('.joblist');

    if (wrapperEl) {
      wrapperEl.classList.add('hidden');
      setTilesShown(tilesShown + TILES);
      setTimeout(() => {
        window.scrollTo({ top: currentScrollY });
        wrapperEl.classList.remove('hidden');
        return;
      }, 300);
    }
  };

  return (
    <JoblistWrapper>
      <div className={'grid joblist-career'}>
        <Toggle textLeft={'Berlin'} textRight={'Mannheim'} onClick={toggleFilter} toggleStatus={props.experienceToggleStatus} />
        {/* Filter jobs by experience and limit tiles for UX*/}
        {data && data.jobKaarismas?.filter((item: JobKaarismaProps) => {
          return item.Office.indexOf(toggleExperienceStatus) === -1
        }).map((item: JobKaarismaProps, index: number) => {
          if (index < tilesShown) {
            return <a href={'/career/' + item.Referenznummer} key={index} target={'_blank'} rel='noopener noreferrer'><Job {...item} Bundesland={item.Office} Wochenstunden={item.Wochenstunden} /></a>;
          } else return '';
        })
        }
      </div>
      {
        data && data.jobKaarismas && data.jobKaarismas.length > TILES &&
        <Button title={'Mehr laden'} onClick={loadMore} className={'load-more'} icon={MoreSVG} buttonColor={'tertiary'} />
      }
    </JoblistWrapper>
  );
};

export default JoblistCareer;
