import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';
import { animations } from '../../styles/animations';
import { FontHeavy } from 'src/styles/fonts';

export const ButtonEl = styled.div`

  display: flex;
  align-items: center;
  height: 86px;

  @media (${breakpoints.s}) {
    height: 96px;
  }

  &:hover {
    svg {
      width: 16px;
      height: 16px;

      @media (${breakpoints.s}) {
        width: 20px;
        height: 20px;
      }

    }
  }

  &.disabled {

    pointer-events: none;

    & .circle {
      background-color: ${colors.grey3} !important;
    }
  }

  &.centered {
    justify-content: center;
  }

  .link {
    font-family: ${FontHeavy};
    font-size: 16px;
    text-transform: uppercase;
    z-index: 2;

    &:hover {
      color: ${colors.grey1} !important;
    }

  }
`;

export const ButtonTitle = styled.input`
  font-family: ${FontHeavy};
  font-size: 16px;
  text-decoration: underline;
  text-transform: uppercase;
  z-index: 2;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  max-width: calc(100vw - 116px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (${breakpoints.s}) {
    font-size: 18px;
    max-width: unset;
  }
`;

export const Circle = styled.div`
  border-radius: 100px;
  min-width: 48px;
  min-height: 48px;
  margin-left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  svg {
    transition: width ${animations.buttons}, height ${animations.buttons};
    width: 12px;
    height: 12px;
  }

  @media (${breakpoints.s}) {
    min-width: 80px;
    min-height: 80px;

    svg {
      width: 16px;
      height: 16px;
    }
  }


`;
