import * as React from 'react';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, WhatsappShareButton, EmailIcon, FacebookIcon, LinkedinIcon, WhatsappIcon } from 'react-share';
import { ShareWrapper } from './Share.styles';

export type ShareProps = {
  url: string,
  className?: string,
  open?: boolean,
  jobname: string
};

const Share = (props: ShareProps): JSX.Element => {

  return (
    <ShareWrapper className={`share ${props.className || ''} ${props.open ? 'open' : ''}`}>
      <EmailShareButton url={props.url} subject={props.jobname} body={'Ich möchte diesen Link mit Ihnen teilen: ' + props.url}>
        <EmailIcon />
      </EmailShareButton>
      <LinkedinShareButton url={props.url}>
        <LinkedinIcon />
      </LinkedinShareButton>
      <FacebookShareButton url={props.url}>
        <FacebookIcon />
      </FacebookShareButton>
      <WhatsappShareButton url={props.url}>
        <WhatsappIcon />
      </WhatsappShareButton>
    </ShareWrapper>
  );
};

export default Share;

