import * as React from 'react';
import { useHistory } from 'react-router-dom';
import CookieBanner from '../cookie-banner/CookieBanner';

type GoogleAnalyticsProps = {

};

const GoogleAnalytics = (props: GoogleAnalyticsProps): JSX.Element => {

  const history = useHistory();
  const [cookieStatus, setCookieStatus] = React.useState<boolean | undefined>(false);

  /**
   * Trigger Klaro consent change function and toggle Google analytics integration
   * @param consent status of consent
   * @param service
   */
  const changeCookieConsent = (consent: any, service: any): void => {

    if (service && service.name === 'google-analytics') {
      setCookieStatus(consent);
    }
  }

  /**
   * Remove all Google Analytics cookies, in case Klaro didn't manage automatically
   */
  const removeCookies = () => {
    var res = document.cookie;
    var multiple = res.split(";");
    for (var i = 0; i < multiple.length; i++) {
      var key = multiple[i].split("=");
      if (key.indexOf('_g') !== -1) {
        // Remove cookie by setting expire date into past
        document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=" + window.location.hostname;
      }
    }
  }

  React.useEffect(() => {

    if (cookieStatus) {

      const ReactGA = require('react-ga');
      // Initialize with ID
      ReactGA.initialize('UA-173999420-1');
      ReactGA.pageview(document.location.pathname);

      // Listen to history for Google Analytics tracking
      history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });
    } else {
      // Klaro handles removal of cookies
      // As fallback: manually delete GA cookies
      removeCookies();
    }
  }, [cookieStatus, history]);

  return (
    <CookieBanner onCookieConsentChange={changeCookieConsent} />
  );
};

export default GoogleAnalytics;
