import { gql } from '@apollo/client';
import { FAQProps } from 'src/components/faq/FAQ';
import { ProcessStepProps } from 'src/modules/process/Process';
import { StageProps } from 'src/modules/stage/Stage';
import { FAQ_FRAGMENT } from './components';
import { STAGE_FRAGMENT } from './stage';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type CandidatesDataProps = {
  candidate: {
    stage: StageProps,
    contacts: Array<TeamMemberDataProps>,
    contactTitle: string,
    factsTitle: string,
    factsText: string,
    contactUs: string,
    process: Array<ProcessStepProps>,
    processTitle: string,
    faqText: string,
    faqTitle: string,
    joblistTitle: string
  },
  faqcandidates: Array<FAQProps>
};

export const CANDIDATES_QUERY = gql`
query {
  candidate {
    ${STAGE_FRAGMENT}
    contactUs
    processTitle
    process {
      text
      image {
        url
      }
    }
    contactTitle
    contacts {
      ${TEAM_FRAGMENT}
    }
    faqText
    faqTitle
    joblistTitle
  }
  faqcandidates {
    ${FAQ_FRAGMENT}
  }
}
`;
