import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const TeamListOuterWrapper = styled.div`
  margin-bottom: 136px;

  .load-more {
    justify-content: center;
  }

  .contact-subtitle {
    padding-bottom: 24px;

    @media (${breakpoints.l}) {
      padding-bottom: 48px;
    }
  }
`;

export const TeamlistWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  @media (${breakpoints.s}) {
    grid-template-columns: repeat(2, 1fr);

    &.has-1-contact {
      grid-template-columns: repeat(1, 1fr);
      max-width: 750px;
      margin: auto;

      .team-member {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.has-2-contact {
      grid-template-columns: repeat(2, 1fr);
      max-width: 1000px;
      margin: auto;
    }
  }

  @media (${breakpoints.l}) {
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (${breakpoints.xl}) {
    grid-template-columns: repeat(3, 1fr);
  }

  .team-member {
    margin-bottom: 24px;
  }

  .shape {
    display: none;
  }
`;
