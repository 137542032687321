import * as React from 'react';
import { FactTitle, FactWrapper, FactText } from './Fact.styles';

export type FactProps = {
  text: string,
  title: string,
  counterIndex: number,
};

const Fact = (props: FactProps): JSX.Element => {

  const [isinitiallyCounted, setIsInitiallyCounted] = React.useState(false);

  let timerSpeed = parseInt(props.text) * 1000;

  // Special case if value is very low. In that case calculated timer speed is slower than others.
  if (parseInt(props.text) < 50) {
    timerSpeed = 4000
  }

  React.useEffect(() => {

    // Animate counting the fact value from 0 to their respective value
    if (!isinitiallyCounted && window !== null) {

      startCounter();

      window.addEventListener('scroll', function () {
        // Start animation when scrolling to facts
        if (window.scrollY > 10) {
          timerSpeed = 3000;
          startTimestamp = null;
          start = currentValue;
        }
      });
    }
  }, [timerSpeed]);

  let currentValue: number = 1;
  let startTimestamp: number | null = null;
  let start = 1;

  const animateValue = (end: number) => {

    var obj = document.getElementById('counter-' + props.counterIndex);

    // Has already started?
    if (obj && obj.innerText.indexOf('1') > -1) {

      const step = (timestamp: number) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }
        const progress = Math.min((timestamp - startTimestamp) / timerSpeed, 1);

        if (obj) {
          currentValue = Math.floor(progress * (end - start) + start);
          obj.innerHTML = currentValue.toString();
        }
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }
  }

  /**
   * Count the current fact until its final value.
   */
  const startCounter = () => {

    let valueTo = parseInt(props.text);
    setIsInitiallyCounted(true);
    animateValue(valueTo);
  }

  return (
    <FactWrapper className={'fact'}>
      <FactTitle id={`counter-${props.counterIndex.toString()}`}>1</FactTitle>
      <FactText>{props.title}</FactText>
    </FactWrapper>
  );
};

export default Fact;
