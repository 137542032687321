import { MatchValueProps } from 'src/components/match-value/MatchValue';
import { MATCH_VALUE_FRAGMENT } from './components';

export type MatchmakingDataProps = {
  title: string,
  text: string,
  titleFullMatch: string,
  titleAlmostMatch: string,
  textFullMatch: string,
  textAlmostMatch: string,
  matchValues: Array<MatchValueProps>,
  buttonApply: string,
  buttonContact: string
};

export const MATCHMAKING_FRAGMENT = `
    title
    text
    titleFullMatch
    titleAlmostMatch
    textFullMatch
    textAlmostMatch
    matchValues {
      ${MATCH_VALUE_FRAGMENT}
    }
    buttonApply
    buttonContact
`;
