import { useQuery } from '@apollo/client';
import * as React from 'react';
import Title from 'src/components/title/Title';
import Areas from '../areas/Areas';
import { OfficesWrapper } from './Offices.styles';
import { OfficesDataProps, OFFICES_QUERY } from '../../queries/offices';

export type OfficesProps = {
  className?: string
};

const Offices = (props: OfficesProps): JSX.Element => {

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<OfficesDataProps>(OFFICES_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <OfficesWrapper className={`areas overlap-margin ${props.className || ''}`}>
      <Title title={'Unsere Offices'} centered={true} />
      <Areas areas={data.offices} className={'margin-bottom'} />
    </OfficesWrapper>
  );
};

export default Offices;

