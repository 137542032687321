import * as React from 'react';
import { FactsWrapper, GridWrapper } from './Facts.styles';
import Title from '../../components/title/Title';
import Text from '../../components/Text/Text';
import Fact, { FactProps } from '../../components/fact/Fact';
import { JoblistProps } from '../joblist/Joblist';
import { useQuery } from '@apollo/client';
import { JOBS_QUERY } from 'src/queries/jobs';

export type FactsProps = {
  facts: Array<FactProps>;
  factsTitle: string;
  factsText: string;
  className?: string;
};

const Facts = (props: FactsProps): JSX.Element => {

  /* GraphQL to load jobs count*/
  const { loading, data, error } = useQuery<JoblistProps>(JOBS_QUERY);

  if (loading) return <p>Lade Fakten...</p>;
  if (error) return <p>{error.message}</p>;
  if (!data) return <p></p>;

  // Prepare facts and load job counter dynamically
  // Also FIX: count all facts simulataneously, this is why this needs to be done before rendering
  let jobsCount = data.jobs?.length.toString() || '1';
  let allFacts: Array<FactProps> | null = null;
  if (props.facts) {
    allFacts = Array.from(props.facts);
    // Replace project fact
    allFacts[4] = { text: jobsCount, title: allFacts[4].title, counterIndex: 4 };
  }

  return (
    <FactsWrapper className={`facts ${props.className}`}>
      <Title title={props.factsTitle} centered={true} />
      <Text title={props.factsText} centered={true} />
      <GridWrapper>
        {
          props.facts && allFacts && allFacts.map((fact: FactProps, index: number) => {
            // Count projects dynamically

            return <Fact key={index} {...fact} counterIndex={index} />;
          })
        }
      </GridWrapper>
    </FactsWrapper>
  );
};

export default Facts;
