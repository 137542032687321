import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const JobdetailsWrapper = styled.div`

  .company-text {
    margin-bottom: 48px;
  }

  .company-text-title {
    margin-bottom: 16px;
  }

  margin-bottom: 70px;
`;

export const DetailsGridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;

    @media (${breakpoints.l}) {
      grid-gap: 24px;
      grid-template-columns: repeat(2, 1fr);
    }

    .subheading {
      max-width: unset;
    }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 136px;
  flex-wrap: wrap;

  .button {
    margin-right: 23px;
  }
`;
