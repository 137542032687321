export const colors = {
  primary: '#C02EB7',
  secondary: '#4440EE',
  tertiary: '#59B5F7',
  grey1: '#2B2A30',
  grey2: '#4C4C56',
  grey3: '#ACB2BF',
  grey4: '#D8D8D8',
  placeholder: '#ACB2BF'
};
