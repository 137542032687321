import { FontHeavy } from 'src/styles/fonts';
import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { colors } from '../../styles/colors';

export const SubheadingEl = styled.h2`
  display: flex;
  font-family: ${FontHeavy};
  letter-spacing: 3px;
  line-height: 42px;
  color: ${colors.grey1};
  text-transform: uppercase;
  max-width: 90%;
  word-break: break-all;
  font-size: 24px;
  margin: 8px 0px;

  @media (${breakpoints.s}) {
    line-height: 50px;
    max-width: 70%;
    font-size: 24px;
  }

  @media (${breakpoints.l}) {
    letter-spacing: 4px;
    line-height: 66px;
    margin: 16px 0px;
  }

  @media (${breakpoints.xl}) {
    letter-spacing: 5px;
    line-height: 74px;
    max-width: 50%;
  }
`;
