import { gql } from '@apollo/client';
import { AreaProps } from 'src/components/area/Area';
import { OFFICES_FRAGMENT } from './components';

export type OfficesDataProps = {
  offices: Array<AreaProps>
};

export const OFFICES_QUERY = gql`
  query {
    offices {
      ${OFFICES_FRAGMENT}
    }
  }
`;
