import * as React from 'react';
import Text from '../Text/Text';
import Title from '../title/Title';
import { AreaImage, AreaWrapper, AreaContent, PartnerWrapper, TextWrapper, Avatar, PositionWrapper, ContactDetailsWrapper, ContactContentWrapper, LocationDetailsWrapper } from './Area.styles';
import { getAbsoluteImageURLReduced } from '../../helper';
import { TeamMemberDataProps } from 'src/queries/team';
import Obfuscate from 'react-obfuscate';
import Link from '../link/Link';
import Button, { ButtonProps } from '../button/Button';

export type AreaProps = {
  title: string,
  text?: string,
  partner: TeamMemberDataProps,
  areaImage: {
    url: string;
  },
  location?: string,
  city?: string,
  street?: string,
  link?: string,
  index?: number;
  mapsButton?: ButtonProps
};

export const Area = (props: AreaProps): JSX.Element => {

  const isFirefox = () => {
    return navigator.userAgent.search("Firefox") !== -1
  }

  return (
    <AreaWrapper className={`area area-${props.index}`} id={'areas'}>
      {props.areaImage && <div className={'area-image-wrapper'}>
        <AreaImage src={getAbsoluteImageURLReduced(props.areaImage.url, "medium_")} />
      </div>}
      < AreaContent >
        <TextWrapper>
          <Title title={props.title} />
          {props.text && <Text title={props.text} className='area-text' />}
          <PartnerWrapper>
            {props.partner.avatar && <Avatar src={getAbsoluteImageURLReduced(props.partner.avatar.url)} />}
            <ContactContentWrapper>
              <Text className={'name'} title={props.partner.name} />
              {props.partner && <PositionWrapper>
                {props.partner.position && <Text title={props.partner.position} />}
                {(props.partner.areaFunction || props.partner.areaIndustry) && <Text title={`
            ${props.partner.areaFunction ? props.partner.areaFunction : ''}
            ${(props.partner.areaFunction && props.partner.areaIndustry) ? ' - ' : ''}
            ${props.partner.areaIndustry ? props.partner.areaIndustry : ''}
          `} />}
                {props.partner.leadership && <Text title={props.partner.leadership} />}
              </PositionWrapper>}
              <ContactDetailsWrapper>
                {props.partner.phone && <div className={'link-wrapper phone-wrapper'}>
                  <Text title={'Tel.:'} />
                  {isFirefox() === false &&
                    <Obfuscate tel={props.partner.phone} className={'phone'}></Obfuscate>
                  }
                  {isFirefox() === true &&
                    <Link title={props.partner.phone} url={'tel:' + props.partner.phone} className={'phone'} />
                  }
                </div>}
                {props.partner.email && <div className={'link-wrapper'}>
                  <Text title={'E-Mail:'} />
                  {isFirefox() === false &&
                    <Obfuscate email={props.partner.email} className={'email'}></Obfuscate>
                  }
                  {isFirefox() === true &&
                    <Link title={props.partner.email} url={'mailto:' + props.partner.email} className={'email'} />
                  }
                </div>}
              </ContactDetailsWrapper>
              <LocationDetailsWrapper>
                {props.location && <Text title={props.location || ''} />}
                {props.street && <Text title={props.street || ''} />}
                {props.city && <Text title={props.city || ''} />}
                {props.mapsButton && <Button {...props.mapsButton} />}
              </LocationDetailsWrapper>
            </ContactContentWrapper>
          </PartnerWrapper>
        </TextWrapper>
      </AreaContent >
    </AreaWrapper >
  );
};

export default Area;
