import { gql } from '@apollo/client';

export const HEADER_QUERY = gql`
    query {
        header {
            navigationItems {
                title,
                route,
                group
            }
        }
    }
`;
