import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';

export const AreasWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 52px;

    @media(${breakpoints.s}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;


