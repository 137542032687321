import * as React from 'react';
import Stage from '../modules/stage/Stage';
import { useQuery } from '@apollo/client';
import { LandingDataProps, LANDING_QUERY } from '../queries/landing';
import Fork from '../modules/fork/Fork';
import Excellence from '../modules/excellence/Excellence';
import Datapromise from 'src/modules/datapromise/Datapromise';
import TextBlock from 'src/modules/textBlock/TextBlock';
import Shape from 'src/components/shape/Shape';
import Footer from 'src/modules/footer/Footer';
import News from 'src/modules/news/News';
import { resetPageMetadata } from 'src/helper';
import ContactOffice from 'src/modules/contact-office/ContactOffice';
import Teamlist from 'src/modules/teamlist/Teamlist';

type LandingPageProps = {

};

const LandingPage = (props: LandingPageProps): JSX.Element => {

  React.useEffect(() => {
    resetPageMetadata();
  });

  /* Graph QL data query*/
  const { loading, data, error } = useQuery<LandingDataProps>(LANDING_QUERY);
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      <Stage {...data.startseite.stage} />
      <Fork left={data.startseite.forkLeft} right={data.startseite.forkRight} className={'margin-bottom'} />
      <Shape alignment={'right'} type={12} y={-135} />
      <Datapromise className={'margin'} />
      <Excellence {...data.startseite.excellence} />
      {data.presses && data.presses.length > 0 && <>
        <Shape alignment={'right'} type={12} y={10} />
        <News news={data.presses} limit={2} className={'margin'} title={data.startseite.newsTitle} maxWidth={1066} />
      </>}
      <Shape alignment={'right'} type={12} y={600} />
      <div className={'margin'}>
        <TextBlock {...data.startseite.career} />
        {data.startseite.contacts && data.startseite.contacts.length === 0 &&
          <ContactOffice title={data.startseite.contactTitle} />}
        {data.startseite.contacts && data.startseite.contacts.length >= 1 &&
          <Teamlist teams={data.startseite.contacts} subtitle={data.startseite.contactTitle} />}
      </div>
      <Footer />
    </div >
  );
};

export default LandingPage;
