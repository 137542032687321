import * as React from 'react';
import { useQuery } from '@apollo/client';
import Stage from '../modules/stage/Stage';
import Shape from 'src/components/shape/Shape';
import { JobKaarismaDataProps, JobKaarismaProps, JOBS_KAARISMA_QUERY } from 'src/queries/jobs_kaarisma';
import { useParams } from 'react-router-dom';
import Jobdetails from 'src/modules/jobdetails/Jobdetails';
import { makeListFromString } from 'src/helper/jobs';
import List from 'src/components/list/List';
import Teamlist from 'src/modules/teamlist/Teamlist';
import Footer from 'src/modules/footer/Footer';

export type CareerJobdetailPageProps = {
  hasNavigation?: boolean
};

type CareerJobdetailPageParams = {
  id?: string
};

const CareerJobdetailPage = (props: CareerJobdetailPageProps): JSX.Element => {

  const { id } = useParams<CareerJobdetailPageParams>();
  const [currentJob, setCurrentJob] = React.useState<JobKaarismaProps>();
  const { loading, data, error } = useQuery<JobKaarismaDataProps>(JOBS_KAARISMA_QUERY, { variables: { jobID: id } });

  React.useEffect(() => {

    // Get job with current ref number from all available jobs
    if (data && id) {
      const job = data.jobKaarismas.find((item: JobKaarismaProps) => { return item.Referenznummer === id; });
      setCurrentJob(job);
    }
  }, [data, id]);

  /* Graph QL data query*/
  if (error) return <></>;
  if (loading) return <></>;
  if (!data) return <></>;

  return (
    <div>
      {data.jobKaarismas && currentJob && <>
        <Stage title={currentJob.Bezeichnung} text={`REF. NR. ${currentJob.Referenznummer}`} backgroundImages={[data.jobdetail.backgroundImage || { url: '' }]} />
        <Shape alignment={'right'} type={12} y={300} aboveAll={true} />
        <div className={'stage-overlap'}>
          <Jobdetails {...data.jobdetail}
            profileList={makeListFromString(currentJob.Vorraussetzungen, '\n')}
            taskList={makeListFromString(currentJob.Beschreibung, '\n')}
            taskTitle={data.jobdetail.taskTitleInternal}
            profileTitle={data.jobdetail.profileTitleInternal}
            hasNavigation={props.hasNavigation || true}
            applicationID={currentJob.BewerbungsId}
            applyButton={data.jobdetail.internalApplyButton}
            applyMailtoSubject={`${currentJob.Bezeichnung} ${currentJob.Referenznummer} ${currentJob.Office}`}
            jobname={currentJob.Bezeichnung} />
          {data.jobdetail.aboutCareerList && <List title={data.jobdetail.aboutTitle} items={makeListFromString(data.jobdetail.aboutCareerList, '\n')} text={data.jobdetail.aboutCareerText} />}
          {data.jobdetail.contactsInternal && <Teamlist teams={data.jobdetail.contactsInternal} subtitle={data.jobdetail.contactTitle} />}
        </div >
      </>}
      <Footer />
    </div>
  );
};

export default CareerJobdetailPage;
