import { gql } from '@apollo/client';
import { NewsElementProps } from 'src/components/newsElement/NewsElement';
import { StageProps } from 'src/modules/stage/Stage';
import { STAGE_FRAGMENT } from './stage';
import { TeamMemberDataProps, TEAM_FRAGMENT } from './team';

export type NewsroomDataProps = {
  newsroom: {
    stage: StageProps,
    contacts: Array<TeamMemberDataProps>,
    contactTitle: string,
    noNewsMessage: string
  },
  presses: Array<NewsElementProps>
};


export type NewsDataProps = {
  presses: Array<NewsElementProps>
};

export type NewsElementDataProps = {
  press: NewsElementProps,
  presses: Array<NewsElementProps>
};

export const NEWS_ELEMENT_FRAGMENT = `
  id
  title
  content
  date
  coverImage {
    url
  }
  contacts {
    ${TEAM_FRAGMENT}
  }
`;

export const NEWSROOM_QUERY = gql`
  query {
    newsroom {
      ${STAGE_FRAGMENT}
      contacts {
        ${TEAM_FRAGMENT}
      }
      contactTitle
      noNewsMessage
    }
    presses (sort: "date:desc") {
      ${NEWS_ELEMENT_FRAGMENT}
    }
  }
`;

export const NEWS_QUERY = gql`
  query {
    presses (sort: "date:desc") {
      ${NEWS_ELEMENT_FRAGMENT}
    }
  }
`;

export const NEWS_ELEMENT_QUERY = gql`
  query ($newsId:ID!){
    press(id: $newsId) {
      ${NEWS_ELEMENT_FRAGMENT}
    }
    presses (sort: "date:desc") {
      ${NEWS_ELEMENT_FRAGMENT}
    }
  }
`;
