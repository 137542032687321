import * as React from 'react';
import { SliderButtonMobile, SliderButtonsMobileWrapper } from './SliderButtonsMobile.styles';
import { ReactSVG } from 'react-svg';
import PreviousSVG from './../../assets/icons/previous.svg';
import NextSVG from './../../assets/icons/next.svg';

export type SliderButtonsMobileProps = {
  changeSlideHandler: (currentSlide: number) => void,
  itemsCount: number,
  currentSlide: number
};

const SliderButtonsMobile = (props: SliderButtonsMobileProps): JSX.Element => {

  const nextSlide = () => {
    let _currentSlide = props.currentSlide + 1;
    if (props.changeSlideHandler) {
      props.changeSlideHandler(_currentSlide);
    }
  };

  const previousSlide = () => {
    let _currentSlide = props.currentSlide - 1;
    if (props.changeSlideHandler) {
      props.changeSlideHandler(_currentSlide);
    }
  };


  return (
    <SliderButtonsMobileWrapper className={'navigation-elements'}>
      <SliderButtonMobile onClick={previousSlide} className={`${(props.currentSlide > 0) ? '' : 'disabled'}`}>
        <ReactSVG src={PreviousSVG} />
      </SliderButtonMobile>
      <SliderButtonMobile onClick={nextSlide} className={`${(props.currentSlide < (props.itemsCount - 1)) ? '' : 'disabled'}`}>
        <ReactSVG src={NextSVG} />
      </SliderButtonMobile>
    </SliderButtonsMobileWrapper>
  );
};

export default SliderButtonsMobile;
